import React from 'react'
import styled, {keyframes} from 'styled-components'

const increase = keyframes`
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
`
const decrease = keyframes`
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
`

const ProgressBarIndeterminateWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 5px;
  overflow-x: hidden;
  z-index: 9999999999999999;

  .line {
    position: absolute;
    opacity: 0.4;
    background: #1A428A;
    width: 150%;
    height: 5px;
  }

  .subline {
    position: absolute;
    background: #1A428A;
    height: 5px;
  }

  .inc {
    animation: ${increase} 2s infinite;
  }

  .dec {
    animation: ${decrease} 2s 0.5s infinite;
  }
`


const ProgressBarIndeterminate = () =>
    <ProgressBarIndeterminateWrapper>
        <div className="line"/>
        <div className="subline inc"/>
        <div className="subline dec"/>
    </ProgressBarIndeterminateWrapper>


export default ProgressBarIndeterminate