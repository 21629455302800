import React, {useReducer, useState} from 'react'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import Form from './Form'
import '../../../assets/scss/Help/index.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import NoData from "../Styled/NoData"
import _ from "lodash"
import QuickSearchWithLink from "../QuickSearchWithLink"
import Tabs from "../Styled/Tabs"
import VersionNumber from "../Styled/VersionNumber"
import LegalInfoFooter from "../LegalInfoFooter"
import {logEvent} from "firebase/analytics"


const Help = ({setItem, deleteItem, docs, fetching, options, allOptions, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Help"
    })

    const [query, setQuery] = useState("")
    const [checked, toggle] = useReducer(
        (checked, docID) => {
            const isChecked = _.get(checked, docID, false)

            return {
                ...checked,
                [docID]: !isChecked
            }
        },
        {}
    )

    const filtered = query.length > 0 ? docs.filter(doc => JSON.stringify(doc).toLowerCase().includes(query.toLowerCase())) : docs

    return (
        <main className="app help">

            {fetching &&
                <ProgressBarIndeterminate/>
            }

            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Help"/>

                <QuickSearchWithLink
                    query={query}
                    setQuery={setQuery}
                    entries={filtered.length}
                    linkTo="/help/add"
                    linkTitle="ADD HELP"
                />

                <Tabs style={{marginTop: "16px"}}>
                    {filtered.map(doc =>
                        <li key={doc.id}>
                            <input
                                type="checkbox"
                                id={doc.id}
                                checked={_.get(checked, `${doc.id}`, false)}
                                onChange={() => toggle(doc.id)}
                            />
                            <label className="tab-label" htmlFor={doc.id}>{doc.title}</label>
                            <div className="tab-content">
                                {_.get(checked, `${doc.id}`, false) &&
                                    <Form
                                        {...doc}
                                        options={options}
                                        allOptions={allOptions}
                                        handleUpdate={setItem}
                                        handleDelete={deleteItem}
                                    />
                                }
                            </div>
                        </li>
                    )}

                </Tabs>

                {filtered.length === 0 &&
                    <NoData>No data</NoData>
                }

            </article>

            <VersionNumber/>
            <LegalInfoFooter/>
        </main>
    )

}

export default Help