import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {
    collection,
    deleteDoc,
    doc,
    endBefore,
    getDocs,
    increment,
    limit,
    limitToLast,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter,
    Timestamp,
    where
} from "firebase/firestore"
import {addError} from "../error"
import _ from 'lodash'
import {batch} from "react-redux"
import {workerThread} from "../../util/workerThread"


const noOfEntriesPerPage = 25

const isFetching = fetching =>
    ({
        type: C.PATIENTS_FETCHING_DOCS,
        payload: fetching
    })

export const addQuery = query =>
    ({
        type: C.PATIENTS_QUERY,
        payload: query
    })

export const addPatientServiceProviderFilter = patient_service_provider_path =>
    ({
        type: C.PATIENTS_SERVICE_PROVIDER_FILTER,
        payload: patient_service_provider_path
    })

const nextAvailable = isAvailable =>
    ({
        type: C.PATIENTS_PAGINATION_NEXT_AVAILABLE,
        payload: isAvailable
    })

const prevAvailable = isAvailable =>
    ({
        type: C.PATIENTS_PAGINATION_PREVIOUS_AVAILABLE,
        payload: isAvailable
    })

const removeItem = item =>
    ({
        type: C.PATIENTS_DELETE_DOC,
        payload: item
    })

const updateItem = item =>
    ({
        type: C.PATIENTS_UPDATE_DOC,
        payload: item
    })

const addItem = item =>
    ({
        type: C.PATIENTS_NEW_DOC,
        payload: item
    })


export const deleteItem = itemID => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        await deleteDoc(doc(db, COLLECTION.PATIENTS, itemID))

        const patientCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.PATIENTS)

        await setDoc(patientCounterRef, {total: increment(-1)}, {merge: true})
    } catch (error) {
        batch(() => {
            dispatch(
                addError(error.message)
            )

            dispatch(
                isFetching(false)
            )
        })
    }
}

export const setItem = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {

        const temp = {
            password: item.password,
            keywords: item.keywords
        }

        temp.program_refs = item.program_path.map(i => doc(db, i.value))
        temp.patient_service_provider_ref = doc(db, temp.program_refs[0].parent.parent.path)

        const patientRef = doc(db, COLLECTION.PATIENTS, item.id)

        await setDoc(patientRef, temp, {merge: true})
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }
}

const checkForNextItem = async (items, queryString, patient_service_provider_path, loadPrev, loadNext) => {

    const oldestItem = !_.isUndefined(_.last(items)) && _.has(_.last(items), "registered") ? Timestamp.fromMillis(_.last(items).registered) : null

    const queryConstraints = []

    if (queryString.length > 0) {
        queryConstraints.push(where("keywords", "array-contains", queryString))
    }

    if (patient_service_provider_path !== 'all') {
        const patient_service_provider_ref = doc(db, patient_service_provider_path)
        queryConstraints.push(where("patient_service_provider_ref", "==", patient_service_provider_ref))
    }

    if (loadNext) {
        queryConstraints.push(orderBy("registered", "desc"))
        queryConstraints.push(startAfter(oldestItem))
        queryConstraints.push(limit(1))
    } else if (loadPrev) {
        queryConstraints.push(orderBy("registered", "desc"))
        queryConstraints.push(startAfter(oldestItem))
        queryConstraints.push(limit(1))
    } else {
        queryConstraints.push(orderBy("registered", "desc"))
        queryConstraints.push(startAfter(oldestItem))
        queryConstraints.push(limit(1))
    }

    const patientsCollRef = collection(db, COLLECTION.PATIENTS)

    const q = query(patientsCollRef, ...queryConstraints)
    const snap = await getDocs(q)

    return !snap.empty
}

const checkForPrevItem = async (items, queryString, patient_service_provider_path, loadPrev, loadNext) => {
    const latestDoc = !_.isUndefined(_.first(items)) && _.has(_.first(items), "registered") ? Timestamp.fromMillis(_.first(items).registered) : null

    const queryConstraints = []

    if (queryString.length > 0) {
        queryConstraints.push(where("keywords", "array-contains", queryString))
    }

    if (patient_service_provider_path !== 'all') {
        const patient_service_provider_ref = doc(db, patient_service_provider_path)
        queryConstraints.push(where("patient_service_provider_ref", "==", patient_service_provider_ref))
    }

    queryConstraints.push(orderBy("registered", "asc"))
    queryConstraints.push(startAfter(latestDoc))
    queryConstraints.push(limitToLast(1))

    const patientsCollRef = collection(db, COLLECTION.PATIENTS)

    const q = query(patientsCollRef, ...queryConstraints)
    const snap = await getDocs(q)

    return !snap.empty
}


export const getPatients = (
    loadNext = false,
    loadPrev = false
) => async (dispatch, getState) => {

    const {patients: {docs = [], queryString = "", patient_service_provider_path = "all"}} = getState()

    const latestDoc = !_.isUndefined(_.first(docs)) && _.has(_.first(docs), "registered") ? Timestamp.fromMillis(_.first(docs).registered) : null
    const oldestDoc = !_.isUndefined(_.last(docs)) && _.has(_.last(docs), "registered") ? Timestamp.fromMillis(_.last(docs).registered) : null

    const queryConstraints = []

    if (queryString.length > 0) {
        queryConstraints.push(where("keywords", "array-contains", queryString.toLowerCase()))
    }

    if (patient_service_provider_path !== 'all') {
        const patient_service_provider_ref = doc(db, patient_service_provider_path)

        queryConstraints.push(where("patient_service_provider_ref", "==", patient_service_provider_ref))
    }

    if (loadNext) {
        queryConstraints.push(orderBy("registered", "desc"))
        queryConstraints.push(startAfter(oldestDoc))
        queryConstraints.push(limit(noOfEntriesPerPage))
    } else if (loadPrev) {
        queryConstraints.push(orderBy("registered", "desc"))
        queryConstraints.push(endBefore(latestDoc))
        queryConstraints.push(limitToLast(noOfEntriesPerPage))
    } else {
        queryConstraints.push(orderBy("registered", "desc"))
        queryConstraints.push(limit(noOfEntriesPerPage))
    }

    if (window.getPatientsListener) {
        window.getPatientsListener()
        window.getPatientsListener = null
    }

    if (window.getPatientsListener) {
        window.getPatientsListener()
        window.getPatientsListener = null
    }

    dispatch(isFetching(true))

    const patientsCollRef = collection(db, COLLECTION.PATIENTS)

    const q = query(patientsCollRef, ...queryConstraints)

    window.getPatientsListener = onSnapshot(q, async querySnapshot => {

        const queryDocs = _.chain(querySnapshot.docs)
            .map(i => ({id: i.id, ...i.data()}))
            .map(i => {
                if (_.has(i, "registered")) {
                    i.registered = i.registered.toMillis()
                }

                if (_.has(i, "online.last_updated")) {
                    i.online.last_updated = i.online.last_updated.toMillis()
                }

                if (_.has(i, "program_refs")) {
                    i.program_paths = i.program_refs.map(i => i.path)
                    delete i.program_refs
                }

                if (_.has(i, "patient_service_provider_ref")) {
                    i.patient_service_provider_path = i.patient_service_provider_ref.path
                    delete i.patient_service_provider_ref
                }

                return i
            })
            .orderBy(['registered'], ['desc'])
            .value()

        const isNextDoc = await checkForNextItem(queryDocs, queryString, patient_service_provider_path, loadPrev, loadNext)
        const isPrevDoc = await checkForPrevItem(queryDocs, queryString, patient_service_provider_path, loadPrev, loadNext)

        batch(() => {
            dispatch(isFetching(false))
            dispatch(nextAvailable(isNextDoc))
            dispatch(prevAvailable(isPrevDoc))

            docs.filter(i => !queryDocs.find(j => i.patient_id === j.patient_id))
                .forEach(i => dispatch(removeItem(i)))
        })

        workerThread(() => {
            querySnapshot.docChanges().forEach(change => {
                const item = {
                    id: change.doc.id,
                    path: change.doc.ref.path,
                    ...change.doc.data()
                }

                if (_.has(item, "program_refs")) {
                    item.program_paths = item.program_refs.map(i => i.path)
                    delete item.program_refs
                }

                if (_.has(item, "patient_service_provider_ref")) {
                    item.patient_service_provider_path = item.patient_service_provider_ref.path
                    delete item.patient_service_provider_ref
                }

                if (_.has(item, "registered")) {
                    item.registered = item.registered.toMillis()
                }

                if (_.has(item, "online.last_updated")) {
                    item.online.last_updated = item.online.last_updated.toMillis()
                }

                switch (change.type) {
                    case 'added':
                        dispatch(
                            addItem(item)
                        )
                        break
                    case 'modified':
                        dispatch(
                            updateItem(item)
                        )
                        break
                    case 'removed':
                        dispatch(
                            removeItem(item)
                        )
                        break
                    default:
                        break
                }
            })
        })
    }, error => {
        batch(() => {
            dispatch(
                isFetching(false)
            )
            dispatch(
                addError(error.message)
            )
        })
    })
}
