import styled from "styled-components"
import theme from "./theme"


const Tabs = styled.ul`
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;

  > li {
    width: 100%;
    color: white;
    overflow: hidden;
    margin: 0 0 0.8rem 0;
    flex: 0 0 100%;
    border: 1px solid ${props => props.theme.brandPrimary};
    border-radius: 0.5rem;

    > input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      z-index: -1;

      &:checked {
        + .tab-label {
          &::after {
            transform-origin: center;
            transform: rotate(90deg);
          }
        }

        ~ .tab-content {
          max-height: 1000vh;
          opacity: 1;
          padding: 1.6rem;
        }
      }
    }

    > label {
      display: flex;
      justify-content: space-between;
      padding: 0.7rem 1.6rem 0.7rem 1.6rem;
      color: ${props => props.theme.brandPrimary};
      font-family: ${props => props.theme.fontFamily};
      font-size: 2.2rem;
      font-weight: bold;
      cursor: pointer;

      &::after {
        content: "\\276F";
        display: block;
        width: 2.2rem;
        height: 2.2rem;
        text-align: center;
        transition: all .15s ease-in-out;
      }
    }

    > div {
      max-height: 0;
      padding: 0 1.6rem;
      overflow: hidden;
      opacity: 0;
      transition: all .2s ease-in-out;

      &:after {
        content: "";
        clear: both;
        display: table;
      }
    }
  }
`

Tabs.defaultProps = {
    theme: theme
}

export default Tabs