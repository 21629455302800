import React from 'react'
import Button from './Button'
import DateRangePickerWrapper from './DateRangePickerWrapper'
import FeedbackTable from './FeedbackTable'
import Header from '../../container/Header'
import EntryHeader from '../EntryHeader'
import ShowErrors from '../../container/ShowErrors'
import '../../../assets/scss/Analytics/index.scss'
import FeedbackBarGraph from './FeedbackBarGraph'
import Select from "react-select"
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import NoData from "../Styled/NoData"
import colourStyles from "../../../constants/colorStyles"
import VersionNumber from "../Styled/VersionNumber"
import LegalInfoFooter from "../LegalInfoFooter"
import {logEvent} from "firebase/analytics"


const Analytics = ({
                       docs,
                       fetching,
                       start_date,
                       end_date,
                       fetchFeedback,
                       setStartDate,
                       setEndDate,
                       patient_service_provider_path,
                       setPatientServiceProviderPath,
                       allPrograms,
                       options,
                       data,
                       dates,
                       patients,
                       analytics
                   }) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Analytics"
    })

    return (
        <main className="app analytics">

            {fetching &&
                <ProgressBarIndeterminate/>
            }

            <ShowErrors/>
            <Header/>
            <article className="inner-content">

                <EntryHeader
                    title="Analytics"
                />

                <div className="analytics-controls">

                    <Select
                        id="filter-by-patient-service-provider"
                        className="react-select-field"
                        placeholder="Please select patient service provider..."
                        name="filter"
                        value={options.find(i => i.value === patient_service_provider_path)}
                        onChange={selected => {
                            setPatientServiceProviderPath(selected.value)
                            fetchFeedback()
                        }}
                        defaultValue={allPrograms.find(i => i.value === patient_service_provider_path)}
                        options={allPrograms}
                        styles={colourStyles}
                    />

                    <DateRangePickerWrapper
                        disabled={fetching}
                        startDate={start_date}
                        endDate={end_date}
                        onDatesChange={(startDate, endDate) => {
                            if (!!startDate) {
                                setStartDate(startDate)
                            }
                            if (!!endDate) {
                                setEndDate(endDate)
                            }
                        }}
                        onClose={() => {
                            fetchFeedback()
                        }}
                    />

                    <Button
                        data={data}
                    />

                </div>

                {docs.length > 0 &&
                    <>
                        <aside className="analytics-graphs">
                            <FeedbackBarGraph
                                dates={dates}
                                patients={patients}
                            />
                        </aside>
                        <h3>FEEDBACK</h3>
                        <FeedbackTable
                            data={docs}
                        />
                    </>
                }

                {docs.length === 0 &&
                    <NoData>No data</NoData>
                }
            </article>

            <VersionNumber/>
            <LegalInfoFooter/>
        </main>
    )

}

export default Analytics
