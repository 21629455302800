import React, {useReducer} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/PatientServiceProviders/Add.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import ResetButton from "../Styled/ResetButton"
import {useFieldArray, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import OutlineSuccessButton from "../Styled/OutlineSuccessButton"
import Notifications from "./Notifications"
import FormControl from "../Styled/FormControl"
import OutlineDangerButton from "../Styled/OutlineDangerButton"
import OutlineLargeDangerButton from "../Styled/OutlineLargeDangerButton"
import {isEmpty} from "../../../util/isEmpty"
import getKeywords from "../../../util/getKeywords"
import getDocumentID from "../../../util/getDocumentID"
import COLLECTION from "../../../constants/collections"
import _ from "lodash"
import Tabs from "../Styled/Tabs"
import PrimaryButton from "../Styled/PrimaryButton"
import VersionNumber from "../Styled/VersionNumber"
import LegalInfoFooter from "../LegalInfoFooter"
import {Timestamp} from "firebase/firestore"
import {logEvent} from "firebase/analytics"


const Add = ({setItems, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Add Patient Service Provider"
    })

    const navigate = useNavigate()

    const {register, handleSubmit, setValue, watch, formState: {errors}, control, reset} = useForm({mode: 'onSubmit'})

    const {fields, append, remove} = useFieldArray({
        control,
        name: "programs"
    })

    const [checked, toggle] = useReducer(
        (checked, docID) => {
            const isChecked = _.get(checked, docID, false)

            return {
                ...checked,
                [docID]: !isChecked
            }
        },
        {}
    )

    const onSubmit = data => {
        data.id = getDocumentID(COLLECTION.PATIENT_SERVICE_PROVIDERS)
        data.registered = Timestamp.now()
        data.keywords = getKeywords(data.title)

        if (data.programs) {
            for (const i in data.programs) {
                const program = data.programs[i]
                data.programs[i].patient_service_provider_id = data.id
                data.programs[i].registered = Timestamp.now()
                data.programs[i].keywords = getKeywords(program.title, data.title)

                if (data.programs[i].notifications) {
                    for (const j in data.programs[i].notifications) {
                        const notification = data.programs[i].notifications[j]

                        notification.id = getDocumentID(COLLECTION.NOTIFICATIONS)
                        notification.patient_service_provider_id = data.id
                        notification.program_id = data.programs[i].id
                        notification.registered = Timestamp.now()

                        data.programs[i].notifications[j] = notification
                    }
                }
            }
        }

        setItems(data)

        navigate('/patient-service-providers')
    }

    const title = watch("title", "")
    const programs = watch("programs", [])

    return (
        <main className="app add-patient-service-provider">
            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Add Patient Service Provider"/>

                <section className="content">

                    <h3>New Patient Service Provider</h3>

                    <Link
                        id="add-service-provider-close-btn"
                        to="/patient-service-providers"
                        className="close"
                        data-tooltip-id="add-patient-service-provider-tooltip"
                        data-tooltip-content={`Click to close`}>
                        <img src={close} alt="close icon"/>
                    </Link>

                    <form
                        className="add-patient-service-provider-form"
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                        }}
                    >

                        <div className="title" style={{marginBottom: "16px"}}>
                            <Label
                                id="add-service-provider-name"
                                data-tooltip-id="add-patient-service-provider-tooltip"
                                data-tooltip-content={`The patient service provider's name`}
                            >Patient Service Provider
                                Name</Label>
                            <FormControl>
                                <InputField
                                    isValid={!!errors.title}
                                    tabIndex="20"
                                    type="text"
                                    placeholder="Please enter an patient service provider name..."
                                    {...register(
                                        'title',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {_.isString(title) && title.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('title', '')}
                                        type="reset"
                                    />
                                }
                            </FormControl>
                            {errors.title && <InvalidFeedback>The `Patient Service Provider Name` field is
                                required</InvalidFeedback>}
                        </div>

                        <Tabs>
                            {fields.map((item, index) => {

                                let panelTitle = `Program: #${index + 1}`

                                if (!isEmpty(programs[index]?.title)) {
                                    panelTitle = `Program: ${programs[index]?.title}`
                                } else if (!isEmpty(item?.title)) {
                                    panelTitle = `Program: ${item.title}`
                                }

                                return (
                                    <li key={item.id}>

                                        <input
                                            type="checkbox"
                                            id={item.id}
                                            checked={_.get(checked, `${item.id}`, false)}
                                            onChange={() => toggle(item.id)}
                                        />

                                        <label className="tab-label" htmlFor={item.id}>{panelTitle}</label>
                                        <div className="tab-content">

                                            <input
                                                type="hidden"
                                                defaultValue={`${item.id}`}
                                                {...register(`programs[${index}].id`)}
                                            />

                                            <div className={`programs`}>
                                                <Label id={`add-service-provider-program-title-${index}`}
                                                       data-tooltip-id="add-patient-service-provider-tooltip"
                                                       data-tooltip-content={`The program's name`}>Program Title</Label>
                                                <FormControl>
                                                    <InputField
                                                        isValid={!!errors.programs &&
                                                            !!errors.programs[index] &&
                                                            !!errors.programs[index].title}
                                                        defaultValue={`${item.title}`}
                                                        type="text"
                                                        placeholder="Please enter the name of the program..."
                                                        {...register(
                                                            `programs[${index}].title`,
                                                            {
                                                                required: true
                                                            })
                                                        }
                                                    />
                                                </FormControl>
                                                {errors.programs && errors.programs[index]
                                                    && errors.programs[index].title
                                                    && errors.programs[index].title
                                                    && <InvalidFeedback>The `Program Title` field is
                                                        required</InvalidFeedback>}
                                            </div>

                                            <Notifications
                                                register={register}
                                                control={control}
                                                field={item}
                                                setValue={setValue}
                                                watch={watch}
                                                errors={errors}
                                                checked={checked}
                                                toggle={toggle}
                                                namePrefix={`programs[${index}].notifications`}
                                            />

                                            <OutlineDangerButton
                                                type="button"
                                                onClick={() => remove(index)}>
                                                Delete Program {programs[index]?.title}
                                            </OutlineDangerButton>
                                        </div>
                                    </li>
                                )
                            })}
                        </Tabs>

                        <OutlineSuccessButton
                            type="button"
                            className="add-program-button"
                            onClick={() => {
                                append({
                                    id: getDocumentID(COLLECTION.PROGRAMS),
                                    title: "",
                                    notifications: []
                                })
                            }}

                        >{_.isString(title) && title.length > 0 ? `Add Program to ${title}` : `Add Program`}</OutlineSuccessButton>

                        <aside className="controls">
                            <OutlineLargeDangerButton
                                type="button"
                                onClick={() => reset()}>
                                Reset Form
                            </OutlineLargeDangerButton>

                            <PrimaryButton
                                data-tooltip-id="add-patient-service-provider-tooltip"
                                data-tooltip-content={`Click to add record`}
                                tabIndex="40"
                                className="add btn"
                                type="submit">
                                ADD PATIENT SERVICE PROVIDER
                            </PrimaryButton>

                        </aside>

                        <Tooltip
                            id="add-patient-service-provider-tooltip"
                            float={true}
                        />
                    </form>

                </section>
            </article>

            <VersionNumber/>
            <LegalInfoFooter/>
        </main>
    )
}


export default Add