import React from 'react'
import {Link} from 'react-router-dom'
import '../../../assets/scss/Patients/QuickSearchWithFilter.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Select from 'react-select'
import ResetButton from "../Styled/ResetButton"
import colourStyles from "../../../constants/colorStyles"
import {DebounceInput} from 'react-debounce-input'


const QuickSearchWithFilter = ({
                                   linkTo = "",
                                   linkTitle = "",
                                   patientServiceProviderOptions = [],
                                   query = "",
                                   patient_service_provider_path = "all",
                                   addQuery,
                                   addPatientServiceProviderFilter,
                                   getPatients,
                                   total_number_of_patients = 0
                               }) => {
    return (
        <section className="quick-search">
            <form onSubmit={e => e.preventDefault()}>
                <div className="quick-search-container">
                    <DebounceInput
                        type="text"
                        placeholder="Quick search..."
                        value={query}
                        onChange={e => {
                            addQuery(e.target.value)
                            getPatients()
                        }}
                    />
                    {query.length > 0 &&
                        <ResetButton onClick={() => {
                            addQuery("")
                            getPatients()
                        }} type="reset"/>
                    }
                </div>

                <Select
                    id="filter-by-patient-service-provider-id"
                    className="react-select-field"
                    name="filter"
                    placeholder="Please select patient service provider..."
                    value={patientServiceProviderOptions.find(i => i.value === patient_service_provider_path)}
                    onChange={selected => {
                        addPatientServiceProviderFilter(selected.value)
                        getPatients()
                    }}
                    options={patientServiceProviderOptions}
                    styles={colourStyles}
                />

                <p>{total_number_of_patients} patients in total</p>

            </form>

            <Link
                id="patient-add-record"
                data-cy="link-add"
                to={linkTo}
                className="btn"
                data-tooltip-id="quick-search-with-filter-tooltip"
                data-tooltip-content="Click to add record">{linkTitle}</Link>
            <Tooltip
                id="quick-search-with-filter-tooltip"
                float={true}
            />
        </section>
    )
}

export default QuickSearchWithFilter