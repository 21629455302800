import React from 'react'
import NavItem from './NavItem'
import Logout from './Logout'
import {NavLink} from 'react-router-dom'
import logo from '../../assets/svg/logo.svg'
import '../../assets/scss/Header.scss'


const Header = props =>
    <header id="masthead" className="site-header" role="banner">
        <div className="site-branding">
            <NavLink to="/data"><img src={logo} alt="TCP Homecare Logo"/></NavLink>
        </div>
        <nav id="site-navigation" className="main-navigation" role="navigation">
            <ul className="menu nav-menu">
                <NavItem to="/data">Data</NavItem>
                <NavItem to="/admins">Admins</NavItem>
                <NavItem to="/patient-service-providers">Service Providers</NavItem>
                <NavItem to="/nurses">Nurses</NavItem>
                <NavItem to="/patients">Patients</NavItem>
                <NavItem to="/appointments">Appointments</NavItem>
                <NavItem to="/program-information">Program Information</NavItem>
                <NavItem to="/analytics">Analytics</NavItem>
                <NavItem to="/messages">Messages</NavItem>
                <NavItem to="/help">Help</NavItem>
            </ul>
        </nav>
        <Logout {...props} />
    </header>


export default Header