import React, {useReducer} from 'react'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import Form from './Form'
import Pagination from '../Pagination/Pagination'
import _ from 'lodash'
import '../../../assets/scss/Patients/index.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import QuickSearchWithFilter from "./QuickSearchWithFilter"
import NoData from "../Styled/NoData"
import Tabs from "../Styled/Tabs"
import VersionNumber from "../Styled/VersionNumber"
import LegalInfoFooter from "../LegalInfoFooter"
import {logEvent} from "firebase/analytics"


const Patients = ({
                      queryString,
                      addQuery,
                      patient_service_provider_path,
                      addPatientServiceProviderFilter,
                      patientServiceProviderOptions,
                      docs,
                      options,
                      allOptions,
                      fetching,
                      getPatients,
                      setItem,
                      deleteItem,
                      nextAvailable,
                      prevAvailable,
                      addError,
                      total_number_of_patients,
                      analytics
                  }) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Patients"
    })

    const [checked, toggle] = useReducer(
        (checked, docID) => {
            const isChecked = _.get(checked, docID, false)

            return {
                ...checked,
                [docID]: !isChecked
            }
        },
        {}
    )

    return (
        <main className="app patients">

            {fetching &&
                <ProgressBarIndeterminate/>
            }
            <ShowErrors/>
            <Header/>
            <article className="inner-content">

                <EntryHeader title="Patients"/>
                <QuickSearchWithFilter
                    query={queryString}
                    total_number_of_patients={total_number_of_patients}
                    patient_service_provider_path={patient_service_provider_path}
                    addQuery={addQuery}
                    getPatients={getPatients}
                    addPatientServiceProviderFilter={addPatientServiceProviderFilter}
                    patientServiceProviderOptions={patientServiceProviderOptions}
                    linkTo="/patients/add"
                    linkTitle="ADD PATIENT"
                />

                <section className="content">
                    <Tabs style={{marginTop: "16px"}}>
                        {docs.map(doc =>
                            <li key={doc.id}>
                                <input
                                    type="checkbox"
                                    id={doc.id}
                                    checked={_.get(checked, `${doc.id}`, false)}
                                    onChange={() => toggle(doc.id)}
                                />
                                <label className="tab-label" htmlFor={doc.id}>{doc.patient_id}</label>
                                <div className="tab-content">
                                    {_.get(checked, `${doc.id}`, false) &&
                                        <Form
                                            key={doc.id}
                                            {...doc}
                                            options={options}
                                            allOptions={allOptions}
                                            handleUpdate={setItem}
                                            handleDelete={deleteItem}
                                            addError={addError}
                                        />
                                    }
                                </div>
                            </li>
                        )}

                    </Tabs>


                    {(!fetching && docs.length === 0) &&
                        <NoData>No data</NoData>
                    }

                    {(prevAvailable || nextAvailable) &&
                        <Pagination
                            isPreviousAvailable={prevAvailable}
                            isNextAvailable={nextAvailable}
                            handlePreviousClick={() => getPatients(false, true)}
                            handleNextClick={() => getPatients(true, false)}
                        />
                    }

                </section>
            </article>

            <VersionNumber/>
            <LegalInfoFooter/>
        </main>
    )
}


export default Patients