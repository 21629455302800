import React, {useState} from 'react'
import {DateRangePicker} from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import '../../../assets/scss/Analytics/DateRangePickerWrapper.scss'
import moment from "moment"


const DateRangePickerWrapper = ({disabled, startDate, endDate, onDatesChange, onClose}) => {

    const [focusedInput, setFocusedInput] = useState(null)

    return (
        <DateRangePicker
            disabled={disabled}
            startDate={startDate}
            startDateId="analytics_start_date_id"
            endDate={endDate}
            endDateId="analytics_end_date_id"
            onDatesChange={({startDate, endDate}) => {
                onDatesChange(startDate, endDate)
            }}
            displayFormat={"DD/MM/YYYY"}
            focusedInput={focusedInput}
            onFocusChange={i => setFocusedInput(i)}
            showDefaultInputIcon
            inputIconPosition="after"
            onClose={() => onClose()}
            small
            isOutsideRange={day => (moment.tz().diff(day) < 0)}
        />
    )

}

export default DateRangePickerWrapper
