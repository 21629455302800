import React, {useEffect} from 'react'
import logo from '../../assets/svg/logo-with-phoenix.svg'
import '../../assets/scss/PrivacyPolicy.scss'
import GoBack from "./GoBack"
import {logEvent} from "firebase/analytics"
import {useLocation} from "react-router-dom"


const PrivacyPolicy = ({analytics}) => {

    const {pathname} = useLocation()

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Privacy Policy"
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <main id="privacy-policy">
            <article className="content">

                <GoBack/>

                <img src={logo} className="logo" alt="TCP Homecare"/>

                <h1>Privacy Policy</h1>

                <h2>Data protection</h2>

                <p>We would like you to feel comfortable visiting our website. It is very important to us to protect
                    your privacy when your personal data is processed. In our privacy statement we describe how we
                    collect, use and disclose your personal data when you visit our website <a
                        href="https://www.tcp.ie/" target="_blank">www.tcp.ie</a> (hereafter: “Website”).</p>

                <h2>1. Controller</h2>

                <p>The Controller pursuant to data protection law is:</p>

                <address>
                    Temperature Controlled Pharmaceutical<br/>
                    a PHOENIX company<br/>
                    2-3 Westland House, Westland Business Park,<br/>
                    Willow Road, Clondalkin,<br/>
                    D12 EA03, Dublin, Ireland.<br/>
                    <a href="tel:+35312158800">Telephone: +353 1 215 8800</a><br/>
                    <a href="mailto:Data.Protection@tcp.ie">Data.Protection@tcp.ie</a>
                </address>

                <h2>2. Data Protection Officer</h2>

                <p>Our Data Protection Officer will be happy to assist you with any concerns regarding data
                    protection.</p>

                <address>
                    Data Protection Officer<br/>
                    United Drug House, Magna Drive,<br/>
                    Magna Business Park, Citywest Road,<br/>
                    D24 XKE5, Dublin, Ireland.<br/>
                    <a href="tel:+35312158800">Telephone: +353 1 215 8800</a><br/>
                    <a href="mailto:Data.Protection@tcp.ie">Data.Protection@tcp.ie</a>
                </address>

                <h2>3. Personal data</h2>

                <p>Personal data means any information relating to an identified or identifiable natural person (“data
                    subject”).
                    An identifiable natural person is a data subject who can be identified, directly or indirectly, in
                    particular by
                    reference to an identifier. Identifiers can include, for example, a name, an identification number,
                    location
                    data, an online identifier, an IP address or one or more factors specific to the physical,
                    physiological,
                    genetic, psychological, economic, cultural or social identity of that natural person (hereafter
                    referred to
                    jointly as “data”).</p>

                <h2>4. Purposes and legal bases of data processing</h2>

                <h3>4.1 General</h3>

                <p>We process your data in accordance with the provisions of the General Data Protection Regulation, the
                    Irish
                    Data Protection Acts and other applicable data protection provisions. Data are processed only
                    insofar as is
                    necessary and permitted under data protection law, for example to enable a visit to the website, to
                    fulfil
                    contractual purposes or to the extent that you consent to data processing.</p>


                <h4>4.1.1 Enquiries</h4>

                <h5>4.1.1.1 Scope of processing and purpose</h5>

                <p>To enable us to process and respond to your enquiries to us, e.g. via the contact form or to our
                    email address,
                    we will process the data you provide to us. The data include your name, your age and your email
                    address and any
                    additional information you send to us in your communication.</p>

                <h5>4.1.1.2 Legal basis</h5>

                <p>We process your data in order to respond to your enquiries on the following legal basis:</p>

                <p>If you contact us in connection with a contract to which you are a party, or in order to take steps
                    prior to
                    entering into a contract, the legal basis is Art. 6, para. 1(b) GDPR.</p>

                <p>For the purposes of pursuing our legitimate interests pursuant to Art. 6, para. 1(f) GDPR; we have a
                    legitimate
                    interest in responding appropriately to customer enquiries.</p>

                <h2>5. Recipients of the data</h2>

                <p>Your data will only be shared with the internal departments or organisational units and other
                    companies
                    affiliated with us under company law which have need of it, in order to fulfil our contractual and
                    legal
                    obligations or to the extent that we require the data in order to process and implement our
                    legitimate
                    interests.</p>

                <p>Your data will be passed on to external recipients in order to process contracts if we are obliged by
                    legal
                    requirements to:</p>

                <p>provide information,</p>

                <p>report or share data or you have given us your consent to your data being shared with third parties,
                    or</p>

                <p>to external service providers who are acting as processors on our behalf or who perform functions for
                    us (e.g.
                    IT service providers, data centres, companies that destroy data or courier services).</p>

                <h2>6. Transfers to third countries</h2>

                <p>We do not otherwise transfer your personal data to countries outside the EU or the EEA or to
                    international
                    organisations.</p>

                <h2>7. Your rights as a data subject</h2>

                <p>You may assert your rights listed below against the entity named in Section 1 at any time.</p>

                <h3>7.1 Right of access</h3>

                <p>You are entitled, pursuant to Art. 15 GDPR, to obtain following information from us at any time and
                    free of
                    charge: your data we process, the purposes of the processing, the categories of recipients, the
                    envisaged period
                    for which the data will be stored or, in the case of transfer to a third country, the appropriate
                    safeguards.
                    You are also entitled to obtain a copy of your data.</p>

                <h3>7.2 Right to rectification, erasure, restriction of processing</h3>

                <p>Should the data we process of you be inaccurate or incomplete or if their processing is unlawful, you
                    can
                    request that we rectify, supplement or restrict the processing of your data or erase the data to the
                    extent
                    permitted by law, Art. 16, 17 and 18 GDPR.</p>

                <p>There is no right to erasure if the processing of the personal data is necessary (i) for the exercise
                    of the
                    right of freedom of expression and information, (ii) for compliance with a legal obligation to which
                    we are
                    subject (e.g. statutory retention periods) or (iii) for the establishment, exercise or defence of
                    legal
                    claims.</p>

                <h3>7.3 Right to data portability</h3>

                <p>If you have provided us with your data on the basis of your consent or as part of an existing
                    contractual
                    relationship with us, we will, at your request, make the data available to you in a structured,
                    commonly used
                    and machine-readable format or, where technically feasible, will transmit them to a third party
                    named by
                    you.</p>

                <h3>7.4 Right to object</h3>

                <p>If we process your data in order to pursue a legitimate interest, you may object to this processing
                    on grounds
                    relating to your particular situation, Art. 21 GDPR. The right to object may be exercised only
                    within the
                    confines of Art. 21 GDPR. Moreover, our interests may override the cessation of processing, meaning
                    that,
                    despite your objection, we are entitled to process your personal data.</p>

                <h3>7.5 Right to lodge a complaint</h3>

                <p>You are welcome to contact our Data Protection Officer named in Section 2 with questions, suggestions
                    and
                    criticisms.</p>

                <p>You are also entitled, subject to Art. 77 GDPR, to lodge a complaint with a supervisory authority, in
                    particular in the Member State of your habitual residence, place of work or place of the alleged
                    infringement if
                    you consider that the processing of personal data relating to you infringes the GDPR. The right to
                    lodge a
                    complaint applies regardless of any other administrative or judicial remedies.</p>

                <p>The supervisory authority responsible for us is:</p>

                <address>
                    Office of the Data Protection Commissioner.<br/>
                    Canal House, Station Road, Portarlington, Co. Laois, R32 AP23, Ireland<br/>
                    <a href="tel:+3530761104800">Phone +353 (0761) 104 800</a><br/>
                    <a href="tel:1890252231">LoCall 1890 25 22 31</a><br/>
                    <a href="tel:+353578684757">Fax +353 57 868 4757</a><br/>
                    <a href="mailto:info@dataprotection.ie">Email: info@dataprotection.ie</a>
                </address>

                <h2>8. Consent/rights of withdrawal</h2>

                <p>In the event that you give or have given us your consent to the collection, processing or use of your
                    data, you
                    can withdraw this consent at any time by notifying the entity named in Section 1. An email is
                    sufficient, but
                    you can reach us via any a preferred method. You also have the right, on grounds relating to your
                    particular
                    situation, to object to the processing of data concerning you on the basis of Art. 6, para. 1(e)
                    (performance of
                    a task carried out in the public interest) or Art. 6, para. 1(f) GDPR (legitimate interests pursued
                    by the
                    controller); the same applies to profiling pursuant to those provisions. In this event, we will no
                    longer
                    process the data concerning you, unless we can demonstrate compelling and legitimate grounds for the
                    processing
                    that override your interests, rights and freedoms, or the processing serves the assertion, exercise
                    or defence
                    of legal claims. If the data concerning you are processed in order to engage in direct marketing,
                    you have the
                    right to object at any time to the processing of this data for the purposes of such marketing. If
                    you object to
                    the processing for direct marketing purposes, the data will no longer be processed for those
                    purposes. Please
                    send notification of withdrawal to the address indicated in Section 1.</p>

                <h2>9. Amendments</h2>

                <p>We reserve the right to amend this privacy statement at any time. Any changes will be announced by
                    the
                    publication of the revised privacy statement on our website. Unless specified to the contrary, such
                    amendments
                    take effect immediately. Please therefore regularly check this privacy statement to make sure you
                    are aware of
                    the latest version. Last updated in May 2018</p>


                <h1 className="text-center display-4">Cookie Policy</h1>

                <h2>What are cookies?</h2>

                <p>Also known as browser cookies or tracking cookies, cookies are small text files of letters and
                    numbers that we
                    put on your computer if you agree. These cookies allow us to distinguish you from other users of our
                    website,
                    which helps us to provide you with a good experience when you browse our website and also allows us
                    to improve
                    our site.</p>

                <p>Cookies are created when a user’s browser loads a particular website. The website sends information
                    to the
                    browser which then creates a text file. Every time the user goes back to the same website, the
                    browser retrieves
                    and sends this file to the website’s server. Computer cookies are created not just by the website
                    the user is
                    browsing but also by other websites that run advertisements, widgets, or other elements on the page
                    being
                    loaded. These cookies regulate how the advertisements appear or how the widgets and other elements
                    function on
                    the page.</p>

                <h2>Who drops cookies when you use our website?</h2>

                <p>Many of the cookies dropped when you use our services are “first party cookies” (meaning cookies
                    placed and
                    read by us directly).</p>

                <p>A number of our partners also drop cookies when you use our services. These specialists “third party
                    cookies”
                    are not set by us but are rather set by companies such as Google for website analytics purposes with
                    our
                    permission. Please read our partners’ privacy policies to ensure that you are comfortable with the
                    manner in
                    which they use cookies:</p>

                <p><a href="https://www.google.com/analytics/learn/privacy.html" target="_blank"
                      rel="noreferrer">www.google.com/analytics/learn/privacy.html</a></p>

                <h2>How do you opt-out?</h2>

                <p>If you do not consent to our use of cookies, you can set your browser settings to reject cookies.
                    These
                    settings are usually found in the “options” or “preferences” menu of your web browser.
                    Alternatively, you can
                    use the “Help” option in your web browser to get more details.</p>

                <p>A number of features of our services may not work or will be curtailed from working effectively
                    without
                    cookies. As a result, changing your browser settings to reject cookies may have a negative impact on
                    your
                    ability to use our website.</p>

                <p>If you use our website with your web browser set to accept cookies, you consent to our use of cookies
                    as
                    described in this cookies policy. If you do not accept our use of cookies, you should either (a)
                    immediately
                    stop using our services or (b) use the settings described above to reject cookies. You can find out
                    more about
                    deleting and blocking cookies here and using your web browser’s “Help” option.</p>

                <h2>Contacting us</h2>

                <p>If you have any questions about this Policy, our practices relating to the website or your dealings
                    with the
                    website you can contact: <a href="mailto:info@tcp.ie">info@tcp.ie</a></p>

            </article>
        </main>
    )

}

export default PrivacyPolicy