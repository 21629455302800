import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {deleteDoc, doc, increment, setDoc} from "firebase/firestore"
import {addError} from "../error"


const isFetching = fetching =>
    ({
        type: C.NURSES_FETCHING_DOCS,
        payload: fetching
    })


export const deleteItem = itemID => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        await deleteDoc(doc(db, COLLECTION.NURSES, itemID))

        const nurseCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.NURSES)

        await setDoc(nurseCounterRef, {total: increment(-1)}, {merge: true})
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}

export const setItem = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {

        const temp = {
            first_name: item.first_name,
            last_name_initial: item.last_name_initial,
            password: item.password,
            keywords: item.keywords
        }

        await setDoc(doc(db, item.path), temp, {merge: true})
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}