import styled from 'styled-components'
import theme from "./theme"


const OutlineSuccessButton = styled.button`
  background-color: #fff;
  color: ${props => props.theme.brandSuccess};
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
  padding: 1rem 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${props => props.theme.brandSuccess};
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s ease-in;
  line-height: 1.5;
  margin: 1.6rem 0 0 0;

  &:hover {
    background-color: ${props => props.theme.brandSuccess};
    color: #fff;
  }
`

OutlineSuccessButton.defaultProps = {
    theme: theme
}

export default OutlineSuccessButton