import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {deleteDoc, deleteField, doc, increment, setDoc, updateDoc} from "firebase/firestore"
import {addError} from "../error"


const isFetching = fetching =>
    ({
        type: ACTION.HELP_FETCHING_DOCS,
        payload: fetching
    })


export const deleteItem = itemID => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        await deleteDoc(doc(db, COLLECTION.HELP, itemID))

        const helpCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.HELP)

        await setDoc(helpCounterRef, {total: increment(-1)}, {merge: true})

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}

export const setItem = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )


    const docRef = doc(db, item.path)

    delete item.path

    try {
        if (item.program_path.value !== "all") {
            item.program_ref = doc(db, item.program_path.value)
        } else {
            await updateDoc(docRef, {
                program_ref: deleteField()
            })
        }

        delete item.program_path

        await setDoc(docRef, item, {merge: true})
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}