import Patients from '../../ui/Patients'
import {connect} from 'react-redux'
import {addPatientServiceProviderFilter, addQuery, deleteItem, getPatients, setItem} from '../../../actions/patients'
import {addError} from '../../../actions/error'
import {allOptions, getOptions, patientServiceProviderOptions} from "./selectors"
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        docs: state.patients.docs,
        total_number_of_patients: state.patients.total_number_of_patients,
        options: getOptions(state),
        patientServiceProviderOptions: patientServiceProviderOptions(state),
        allOptions: allOptions(state),
        fetching: state.patients.fetching,
        patient_service_provider_path: state.patients.patient_service_provider_path,
        queryString: state.patients.queryString,
        nextAvailable: state.patients.nextAvailable,
        prevAvailable: state.patients.prevAvailable,
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        deleteItem(doc) {
            dispatch(
                deleteItem(doc)
            )
        },
        setItem(doc) {
            dispatch(
                setItem(doc)
            )
        },
        addPatientServiceProviderFilter(patient_service_provider_path) {
            dispatch(
                addPatientServiceProviderFilter(patient_service_provider_path)
            )
        },
        addQuery(queryString) {
            dispatch(
                addQuery(queryString)
            )
        },
        addError(error) {
            dispatch(
                addError(error)
            )
        },
        getPatients(loadNext, loadPrev) {
            dispatch(
                getPatients(loadNext, loadPrev)
            )
        }
    })

export default connect(mapStateToProps, mapDispatchToProps)(Patients)  
