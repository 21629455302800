import React from 'react'
import '../../../assets/scss/Help/Form.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import getKeywords from "../../../util/getKeywords"
import roleType from '../../../constants/roleType'
import isUrlValid from '../../../util/isUrlValid'
import Select from 'react-select'
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Textarea from "../Styled/Textarea"
import Label from "../Styled/Label"
import OutlineLargeDangerButton from "../Styled/OutlineLargeDangerButton"
import PrimaryButton from "../Styled/PrimaryButton"
import _ from "lodash"
import colourStyles from "../../../constants/colorStyles"
import InputNumber from "rc-input-number"
import 'rc-input-number/assets/index.css'
import isPhoneValid from "../../../util/isPhoneValid"


const Form = props => {

    const {register, handleSubmit, setValue, watch, formState: {errors}, control} = useForm({mode: 'onSubmit'})

    const onSubmit = data => {
        data.path = props.path

        data.group = _.has(data.group, "value") ? data.group.value : data.group

        if (!data.with_link) {
            delete data.link_title
            delete data.link_url
        }

        data.index = _.toNumber(data.index)

        data.keywords = data.with_link ?
            getKeywords(data.title, data.body, data.link_title, data.link_url) :
            getKeywords(data.title, data.body)

        props.handleUpdate(data)
    }

    const title = watch("title", props.title)
    const with_link = watch("with_link", props.with_link)
    const link_title = watch("link_title", props.link_title)
    const link_url = watch("link_url", props.link_url)


    return (
        <form
            className="edit-help-form"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault()
            }}
        >

            <div className="program_path">
                <Label
                    id={`help-program-${props.id}`}
                    data-tooltip-id={`update-help-tooltip-${props.id}`}
                    data-tooltip-content={`Program for the item`}>
                    Program
                </Label>
                <Controller
                    render={({field}) =>
                        <Select
                            {...field}
                            options={props.options}
                            className="react-select-field"
                            placeholder="Please select a program..."
                            styles={colourStyles}
                        />
                    }
                    defaultValue={props.allOptions.find(program => program.value === props.program_path)}
                    control={control}
                    rules={{required: true}}
                    name="program_path"
                />
                {errors.program_path && <InvalidFeedback>The `Program` field is required</InvalidFeedback>}
            </div>

            <div className="group">
                <Label
                    id={`help-group-${props.id}`}
                    data-tooltip-id={`update-help-tooltip-${props.id}`}
                    data-tooltip-content={`Group for the item`}>
                    Group
                </Label>
                <Controller
                    render={({field}) =>
                        <Select
                            {...field}
                            options={roleType}
                            className="react-select-field"
                            placeholder="Please select a group..."
                            styles={colourStyles}
                        />
                    }
                    defaultValue={roleType.find(role => role.value === props.group)}
                    control={control}
                    rules={{required: true}}
                    name="group"
                />
            </div>

            <div className="title">
                <Label
                    id={`help-title-${props.id}`}
                    data-tooltip-id={`update-help-tooltip-${props.id}`}
                    data-tooltip-content={`Title for the item`}>
                    Title
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.title}
                        type="text"
                        placeholder="Please enter a title..."
                        defaultValue={props.title}
                        {...register(
                            'title',
                            {
                                required: true
                            })
                        }
                    />
                    {title.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('title', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.title && <InvalidFeedback>The `Title` field is required</InvalidFeedback>}
            </div>

            <div className="body">
                <Label
                    id={`help-description-${props.id}`}
                    data-tooltip-id={`update-help-tooltip-${props.id}`}
                    data-tooltip-content={`Description for the item`}
                >Description (500 word limit)</Label>
                <Textarea
                    placeholder="Please enter the description..."
                    isValid={!!errors.body}
                    defaultValue={props.body}
                    {...register(
                        'body',
                        {
                            required: true,
                            maxLength: 1000
                        })
                    }
                />
                {errors.body && errors.body.type === "maxLength" &&
                    <InvalidFeedback>The description needs to be less than 500 words</InvalidFeedback>
                }
                {errors.body && errors.body.type === "required" &&
                    <InvalidFeedback>The `Description` field is required</InvalidFeedback>
                }
            </div>

            <label
                id={`help-link-${props.id}`}
                className="checkbox"
                data-tooltip-id={`update-help-tooltip-${props.id}`}
                data-tooltip-content={`Does this item include a link`}>
                <input
                    type="checkbox"
                    defaultChecked={props.with_link}
                    {...register('with_link')}
                />
                <span>Link</span>
            </label>
            {with_link && (
                <>
                    <div className="link_title">
                        <Label
                            id={`help-link-title-${props.id}`}
                            data-tooltip-id={`update-help-tooltip-${props.id}`}
                            data-tooltip-content={`Link title`}>
                            Link title
                        </Label>
                        <div style={{position: "relative"}}>
                            <InputField
                                isValid={!!errors.title}
                                type="text"
                                placeholder="Please enter the link title..."
                                defaultValue={props.link_title}
                                {...register(
                                    'link_title',
                                    {
                                        required: true
                                    })
                                }
                            />
                            {!!link_title && link_title.length > 0 &&
                                <ResetButton
                                    onClick={() => setValue('link_title', '')}
                                    type="reset"
                                />
                            }
                        </div>
                        {errors.link_title && <InvalidFeedback>The `Link Title` field is required</InvalidFeedback>}
                    </div>

                    <div className="link_url">
                        <Label
                            id={`help-link-url-${props.id}`}
                            data-tooltip-id={`update-help-tooltip-${props.id}`}
                            data-tooltip-content={`Link url`}>
                            Link Url
                        </Label>
                        <div style={{position: "relative"}}>
                            <InputField
                                isValid={!!errors.link_url}
                                type="text"
                                placeholder="Please enter the link url..."
                                defaultValue={props.link_url}
                                {...register(
                                    'link_url',
                                    {
                                        required: true,
                                        validate: {
                                            isValidUrlOrPhone: value => isUrlValid(value) || isPhoneValid(value)
                                        }
                                    })
                                }
                            />
                            {!!link_url && link_url.length > 0 &&
                                <ResetButton
                                    onClick={() => setValue('link_url', '')}
                                    type="reset"
                                />
                            }
                        </div>
                        {errors.link_url && errors.link_url.type === "required" &&
                            <InvalidFeedback>The `Link URL` field is required</InvalidFeedback>
                        }
                        {errors.link_url && errors.link_url.type === 'isValidUrl' &&
                            <InvalidFeedback>Please enter a valid url</InvalidFeedback>
                        }

                    </div>
                </>
            )}

            <div className="index">
                <Label
                    id={`help-order-${props.id}`}
                    data-tooltip-id={`update-help-tooltip-${props.id}`}
                    data-tooltip-content={`Rank order for the item`}>
                    Order
                </Label>

                <Controller
                    render={({field}) =>
                        <InputNumber
                            {...field}
                            min={1}
                        />
                    }
                    rules={{required: true}}
                    defaultValue={parseInt(props.index)}
                    control={control}
                    name="index"
                />
            </div>

            <OutlineLargeDangerButton
                disabled={props.fetching}
                className="trash"
                type="button"
                onClick={e => {
                    e.preventDefault()
                    props.handleDelete(props.id)
                }}>
                Delete Help
            </OutlineLargeDangerButton>

            <PrimaryButton
                id={`help-update-btn-${props.id}`}
                disabled={props.fetching}
                data-tooltip-id={`update-help-tooltip-${props.id}`}
                data-tooltip-content={`Click to update record`}
                className="update btn"
                type="submit">
                UPDATE
            </PrimaryButton>

            <Tooltip
                id={`update-help-tooltip-${props.id}`}
                float={true}
            />

        </form>
    )
}


export default Form