import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {addDoc, collection, doc, increment, setDoc} from "firebase/firestore"
import {addError} from "../error"


const isFetching = fetching =>
    ({
        type: ACTION.PROGRAM_INFORMATION_FETCHING_DOCS,
        payload: fetching
    })

export const addItem = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        if (item.program_path.value !== "all") {
            item.program_ref = doc(db, item.program_path.value)
        }

        delete item.program_path

        await addDoc(collection(db, COLLECTION.PROGRAM_INFORMATION), item)

        const programInfoCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.PROGRAM_INFORMATION)

        await setDoc(programInfoCounterRef, {total: increment(1)}, {merge: true})

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}