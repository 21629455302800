import React from 'react'
import XlsxPopulate from 'xlsx-populate'
import PrimaryButton from "../Styled/PrimaryButton"


const Button = ({data}) => {

    const download = (data, filename, type) => {
        const file = new Blob([data], {type: type})
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file, filename)
        } else {
            const a = document.createElement("a")
            const url = URL.createObjectURL(file)
            a.href = url
            a.download = filename
            document.body.appendChild(a)
            a.click()
            setTimeout(() => {
                document.body.removeChild(a)
                window.URL.revokeObjectURL(url)
            }, 0)
        }
    }

    const date = () => (new Date()).toISOString().slice(0, 19).replace(/-/g, "-").replace("T", "-").replace(":", "-")

    const handleClick = async () => {
        if (data.length > 1) {
            const workbook = await XlsxPopulate.fromBlankAsync()

            workbook.sheet(0).cell("A1").value(data)

            const buffer = await workbook.outputAsync({password: "password"})

            download(buffer, `analytics-data-${date()}.xlsx`, `application/vnd.ms-excel`)
        }
    }

    return (
        <PrimaryButton
            style={{marginLeft: "auto"}}
            disabled={data.length < 2}
            data-tooltip-content={`Click to download feedback data in excel format`}
            onClick={() => handleClick()}
        >DOWNLOAD EXCEL</PrimaryButton>
    )

}

export default Button
