import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {collectionGroup, onSnapshot} from "firebase/firestore"
import _ from "lodash"
import moment from "moment-timezone"
import Timezone from "../../constants/timezone"
import {workerThread} from "../../util/workerThread"
import {addError} from "../error"


const addItem = item =>
    ({
        type: ACTION.MESSAGES_NEW_DOC,
        payload: item
    })

const updateItem = item =>
    ({
        type: ACTION.MESSAGES_UPDATE_DOC,
        payload: item
    })

const deleteItem = item =>
    ({
        type: ACTION.MESSAGES_DELETE_DOC,
        payload: item
    })


export const messagesDocListener = () => async (dispatch, getState) => {

    if (_.isFunction(window.messagesDocListener)) {
        window.messagesDocListener()
        delete window.messagesDocListener
    }

    const chatCollRef = collectionGroup(db, COLLECTION.CHAT_THREAD)

    window.messagesDocListener = onSnapshot(chatCollRef, querySnapshot => {

        workerThread(() => {
            querySnapshot.docChanges().forEach(change => {

                const item = {
                    id: change.doc.id,
                    path: change.doc.ref.path,
                    ...change.doc.data()
                }

                item.isPatient = item.sender.ref.path.toLowerCase().endsWith("patient")
                item.millis = item.created.toMillis()
                item.date_formatted_channel = moment.tz(item.millis, Timezone).format("DD.MM.YYYY")

                const date = moment.tz(item.millis, Timezone).format("h:mma D MMMM YYYY")
                item.meta = `${date} ${item.sender.name}`
                item.date_sent = moment.tz(item.millis, Timezone).format("DD MM YYYY hh:mm:ss")

                item.sender.path = item.sender.ref.path
                delete item.sender.ref

                item.patient_service_provider.path = item.patient_service_provider.ref.path
                delete item.patient_service_provider.ref

                item.program.path = item.program.ref.path
                delete item.program.ref

                item.channel_path = change.doc.ref.parent.parent.path

                switch (change.type) {
                    case 'added':
                        dispatch(
                            addItem(item)
                        )
                        break
                    case 'modified':
                        dispatch(
                            updateItem(item)
                        )
                        break
                    case 'removed':
                        dispatch(
                            deleteItem(item)
                        )
                        break
                    default:
                        break
                }
            })

        })
    }, error => {
        dispatch(
            addError(error.message)
        )
    })

}