import React, {useReducer, useState} from 'react'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import QuickSearchWithLink from '../QuickSearchWithLink'
import EntryHeader from '../EntryHeader'
import Form from './Form'
import '../../../assets/scss/Admins/index.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import NoData from "../Styled/NoData"
import _ from "lodash"
import Tabs from "../Styled/Tabs"
import VersionNumber from "../Styled/VersionNumber"
import LegalInfoFooter from "../LegalInfoFooter"
import {logEvent} from "firebase/analytics"


const Admins = ({docs, fetching, setItem, deleteItem, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Admins"
    })

    const [query, setQuery] = useState("")

    const [checked, toggle] = useReducer(
        (checked, docID) => {
            const isChecked = _.get(checked, docID, false)

            return {
                ...checked,
                [docID]: !isChecked
            }
        },
        {}
    )

    const filtered = query.length > 0 ? docs.filter(doc => JSON.stringify(doc).toLowerCase().includes(query.toLowerCase())) : docs

    return (
        <main className="app admins">

            {fetching &&
                <ProgressBarIndeterminate/>
            }

            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Admins"/>
                <QuickSearchWithLink
                    query={query}
                    setQuery={setQuery}
                    entries={filtered.length}
                    linkTo="/admins/add"
                    linkTitle="ADD ADMIN"
                />

                <section className="content">

                    <Tabs style={{marginTop: "16px"}}>
                        {filtered.map(doc =>
                            <li key={doc.id}>
                                <input
                                    type="checkbox"
                                    id={doc.id}
                                    checked={_.get(checked, `${doc.id}`, false)}
                                    onChange={() => toggle(doc.id)}
                                />
                                <label className="tab-label" htmlFor={doc.id}>{doc.admin_id}</label>
                                <div className="tab-content">
                                    {_.get(checked, `${doc.id}`, false) &&
                                        <Form
                                            key={doc.id}
                                            {...doc}
                                            handleDelete={deleteItem}
                                            handleUpdate={setItem}
                                        />
                                    }
                                </div>
                            </li>
                        )}

                    </Tabs>

                    {filtered.length === 0 &&
                        <NoData>No data</NoData>
                    }

                </section>

            </article>

            <VersionNumber/>
            <LegalInfoFooter/>

        </main>
    )

}


export default Admins