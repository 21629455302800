const constants = {

    AUTH_LOGIN: "AUTH/LOGIN",
    AUTH_LOGOUT: "AUTH/LOGOUT",
    AUTH_FETCHING: "AUTH/FETCHING",

    ADMINS_NEW_DOC: "ADMINS/NEW_DOC",
    ADMINS_UPDATE_DOC: "ADMINS/UPDATE_DOC",
    ADMINS_DELETE_DOC: "ADMINS/DELETE_DOC",
    ADMINS_FETCHING_DOCS: "ADMINS/FETCHING",

    PATIENT_SERVICE_PROVIDERS_NEW_DOC: "PATIENT_SERVICE_PROVIDERS/NEW_DOC",
    PATIENT_SERVICE_PROVIDERS_UPDATE_DOC: "PATIENT_SERVICE_PROVIDERS/UPDATE_DOC",
    PATIENT_SERVICE_PROVIDERS_DELETE_DOC: "PATIENT_SERVICE_PROVIDERS/DELETE_DOC",
    PATIENT_SERVICE_PROVIDERS_FETCHING_DOCS: "PATIENT_SERVICE_PROVIDERS/FETCHING",

    PROGRAMS_NEW_DOC: "PROGRAMS/NEW_DOC",
    PROGRAMS_UPDATE_DOC: "PROGRAMS/UPDATE_DOC",
    PROGRAMS_DELETE_DOC: "PROGRAMS/DELETE_DOC",
    PROGRAMS_FETCHING_DOCS: "PROGRAMS/FETCHING",

    NOTIFICATIONS_NEW_DOC: "NOTIFICATIONS/NEW_DOC",
    NOTIFICATIONS_UPDATE_DOC: "NOTIFICATIONS/UPDATE_DOC",
    NOTIFICATIONS_DELETE_DOC: "NOTIFICATIONS/DELETE_DOC",
    NOTIFICATIONS_FETCHING_DOCS: "NOTIFICATIONS/FETCHING",

    MESSAGES_NEW_DOC: "MESSAGES/NEW_DOC",
    MESSAGES_UPDATE_DOC: "MESSAGES/UPDATE_DOC",
    MESSAGES_DELETE_DOC: "MESSAGES/DELETE_DOC",
    MESSAGES_FETCHING_DOCS: "MESSAGES/FETCHING",

    APPOINTMENT_NEW_DOC: "APPOINTMENT/NEW_DOC",
    APPOINTMENT_UPDATE_DOC: "APPOINTMENT/UPDATE_DOC",
    APPOINTMENT_DELETE_DOC: "APPOINTMENT/DELETE_DOC",
    APPOINTMENT_FETCHING_DOCS: "APPOINTMENT/FETCHING",
    APPOINTMENT_QUERY_TERM: "APPOINTMENT/QUERY/TERM",
    APPOINTMENT_QUERY_DATE_RANGE: "APPOINTMENT/QUERY/DATE_RANGE",
    APPOINTMENT_QUERY_PROGRAM: "APPOINTMENT/QUERY/PROGRAM",

    PATIENTS_NEW_DOC: "PATIENTS/NEW_DOC",
    PATIENTS_NEW_DOCS: "PATIENTS/NEW_DOCS",
    PATIENTS_UPDATE_DOC: "PATIENTS/UPDATE_DOC",
    PATIENTS_DELETE_DOC: "PATIENTS/DELETE_DOC",
    PATIENTS_DELETE_DOCS: "PATIENTS/DELETE_DOCS",
    PATIENTS_FETCHING_DOCS: "PATIENTS/FETCHING",
    PATIENTS_CLEAR_DOCS: "PATIENTS/CLEAR_DOCS",
    PATIENTS_QUERY: "PATIENTS/QUERY",
    PATIENTS_SERVICE_PROVIDER_FILTER: "PATIENTS/FILTER/SERVICE_PROVIDERS",
    PATIENTS_PAGINATION_NEXT_AVAILABLE: "PATIENTS/PAGINATION/NEXT",
    PATIENTS_PAGINATION_PREVIOUS_AVAILABLE: "PATIENTS/PAGINATION/PREVIOUS",

    COUNTER_PATIENT: "COUNTER/PATIENT",

    NURSES_NEW_DOC: "NURSES/NEW_DOC",
    NURSES_UPDATE_DOC: "NURSES/UPDATE_DOC",
    NURSES_DELETE_DOC: "NURSES/DELETE_DOC",
    NURSES_CLEAR_DOCS: "NURSES/CLEAR",
    NURSES_LAST_VISIBLE_DOC: "NURSES/LAST_VISIBLE_DOC",
    NURSES_FETCHING_DOCS: "NURSES/FETCHING",

    FEEDBACK_NEW_DOC: "FEEDBACK/NEW_DOC",
    FEEDBACK_UPDATE_DOC: "FEEDBACK/UPDATE_DOC",
    FEEDBACK_DELETE_DOC: "FEEDBACK/DELETE_DOC",
    FEEDBACK_FETCHING_DOCS: "FEEDBACK/FETCHING",
    FEEDBACK_CLEAR_DOCS: "FEEDBACK/CLEAR",
    FEEDBACK_START_DATE: "FEEDBACK/START_DATE",
    FEEDBACK_END_DATE: "FEEDBACK/END_DATE",
    FEEDBACK_PATIENT_SERVICE_PROVIDER_PATH: "FEEDBACK/PATIENT_SERVICE_PROVIDER_PATH",

    TWILIO_ROOMS_NEW_DOC: "TWILIO_ROOMS/NEW/DOC",
    TWILIO_ROOMS_UPDATE_DOC: "TWILIO_ROOMS/UPDATE/DOC",
    TWILIO_ROOMS_DELETE_DOC: "TWILIO_ROOMS/DELETE/DOC",
    TWILIO_ROOMS_CLEAR_DOCS: "TWILIO_ROOMS/CLEAR",
    TWILIO_ROOMS_FETCHING_DOCS: "TWILIO_ROOMS/FETCHING",

    PROGRAM_INFORMATION_NEW_DOC: "PROGRAM_INFORMATION/NEW_DOC",
    PROGRAM_INFORMATION_UPDATE_DOC: "PROGRAM_INFORMATION/UPDATE_DOC",
    PROGRAM_INFORMATION_DELETE_DOC: "PROGRAM_INFORMATION/DELETE_DOC",
    PROGRAM_INFORMATION_FETCHING_DOCS: "PROGRAM_INFORMATION/FETCHING",

    HELP_NEW_DOC: "HELP/NEW_DOC",
    HELP_UPDATE_DOC: "HELP/UPDATE_DOC",
    HELP_DELETE_DOC: "HELP/DELETE_DOC",
    HELP_FETCHING_DOCS: "HELP/FETCHING",

    DATA_FETCHING_DOCS: "DATA/FETCHING",
    DATA_PROCESSING_STAGE: "DATA/DATA_PROCESSING_STAGE",

    ERROR_NEW_DOC: "ERROR/NEW_DOC",
    ERROR_DELETE_DOC: "ERROR/DELETE/DOC"
}


export default constants