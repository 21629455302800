import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {deleteDoc, doc, getDocs, writeBatch} from "firebase/firestore"
import {addError} from "../error"
import _ from "lodash"


const isFetching = fetching =>
    ({
        type: ACTION.PATIENT_SERVICE_PROVIDERS_FETCHING_DOCS,
        payload: fetching
    })


export const setItems = data => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {

        isFetching(true)

        const batch = writeBatch(db)

        const patientServiceProvidersRef = doc(db, COLLECTION.PATIENT_SERVICE_PROVIDERS, data.id)

        batch.set(patientServiceProvidersRef, {
            title: data.title,
            registered: data.registered,
            keywords: data.keywords
        })

        if (data.programs) {
            for (const program of data.programs) {
                const programRef = doc(db, COLLECTION.PATIENT_SERVICE_PROVIDERS, data.id, COLLECTION.PROGRAMS, program.id)

                batch.set(programRef, {
                    title: program.title,
                    registered: program.registered,
                    keywords: program.keywords
                })

                if (program.notifications) {

                    for (const notification of program.notifications) {

                        const notificationRef = doc(db, COLLECTION.PATIENT_SERVICE_PROVIDERS, data.id, COLLECTION.PROGRAMS, program.id, COLLECTION.NOTIFICATIONS, notification.id)

                        batch.set(notificationRef, {
                            title: notification.title,
                            body: notification.body,
                            lead_time: _.toNumber(notification.lead_time),
                            registered: notification.registered
                        })
                    }
                }
            }
        }

        await batch.commit()

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}


export const deletePatientServiceProvider = docID => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        isFetching(true)

        const batch = writeBatch(db)

        const pDocRef = doc(db, COLLECTION.PATIENT_SERVICE_PROVIDERS, docID, COLLECTION.PROGRAMS)

        const pSnapshot = await getDocs(pDocRef)


        if (!pSnapshot.empty) {
            for (const pDoc of pSnapshot.docs) {

                const nDocRef = doc(db, COLLECTION.PATIENT_SERVICE_PROVIDERS, docID, COLLECTION.PROGRAMS, pDoc.id, COLLECTION.NOTIFICATIONS)
                const nSnapshot = await getDocs(nDocRef)

                if (!nSnapshot.empty) {
                    for (const nDoc of nSnapshot.docs) {
                        batch.delete(nDoc.ref)
                    }
                }

                batch.delete(pDoc.ref)
            }
        }

        const patientServiceProvidersRef = doc(db, COLLECTION.PATIENT_SERVICE_PROVIDERS, docID)

        batch.delete(patientServiceProvidersRef)

        await batch.commit()
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}


export const deleteProgram = (serviceProviderDocID = "", programDocID = "") => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {

        if (serviceProviderDocID.length > 0 && programDocID.length > 0) {
            isFetching(true)

            const batch = writeBatch(db)

            const pDocRef = doc(db, COLLECTION.PATIENT_SERVICE_PROVIDERS, serviceProviderDocID, COLLECTION.PROGRAMS, programDocID)
            const pSnapshot = await getDocs(pDocRef)

            if (pSnapshot.exists) {

                const nDocRef = doc(db, COLLECTION.PATIENT_SERVICE_PROVIDERS, serviceProviderDocID, COLLECTION.PROGRAMS, pSnapshot.id, COLLECTION.NOTIFICATIONS)

                const nSnapshot = await getDocs(nDocRef)

                if (!nSnapshot.empty) {
                    for (const nDoc of nSnapshot.docs) {
                        batch.delete(nDoc.ref)
                    }
                }

                batch.delete(pSnapshot.ref)
            }

            await batch.commit()
        }

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}

export const deleteNotification = path => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        isFetching(true)

        const notifDocRef = doc(db, path)

        await deleteDoc(notifDocRef)
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}