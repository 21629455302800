import styled from 'styled-components'
import theme from "./theme"

const FormField = styled.div`
  margin-bottom: 1.7rem;
  position: relative;
`

FormField.defaultProps = {
    theme: theme
}

export default FormField