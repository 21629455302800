import styled from 'styled-components'
import theme from "./theme"


const MessageWrapper = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 80%;
  align-items: ${props => props.isPatient ? "flex-end" : "flex-start"};

  > p.meta {
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    color: #14171A;
    margin: 0 0 0.4rem 0;
    padding: 0;
  }

  > p.content {
    font-size: 1.4rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    margin: 0 0 1.6rem 0;
    background-color: ${props => props.isPatient ? "#1A428A" : "#96DAEA"};
    color: ${props => props.isPatient ? "#fff" : "#000"};
    border-radius: 0.25rem;
    padding: 0.8rem;
  }
`

MessageWrapper.defaultProps = {
    theme: theme
}

export default MessageWrapper