import ACTION from '../../constants/actionType'
import {analytics, auth, functions} from "../index"
import {logEvent, setUserId, setUserProperties} from "firebase/analytics"
import {signInWithCustomToken} from 'firebase/auth'
import {httpsCallable} from "firebase/functions"
import {addError} from "../error"


const isFetching = (fetching = false) =>
    ({
        type: ACTION.AUTH_FETCHING,
        payload: fetching
    })

const setUser = user =>
    ({
        type: ACTION.AUTH_LOGIN,
        payload: user
    })

export const signInWithAdminIDAndPassword = (
    admin_id = "",
    password = ""
) => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        const accessToken = httpsCallable(functions, 'getAdminLoginAccessToken')

        const {
            data: {
                token
            }
        } = await accessToken({
            admin_id: admin_id,
            password: password
        })

        const userCredential = await signInWithCustomToken(auth, token)

        const idTokenResult = await userCredential.user.getIdTokenResult()

        if (!!idTokenResult.claims.admin) {

            dispatch(
                setUser(userCredential.user)
            )

            setUserId(analytics, userCredential.user.uid)
            setUserProperties(analytics, {userRole: "cms-admin"})

            logEvent(analytics, "login", {"admin_id": admin_id})

        } else {
            dispatch(
                addError("No admin custom claims")
            )
        }

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}

