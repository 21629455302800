import styled from "styled-components"
import React from "react"
import {NavLink} from "react-router-dom"


const FooterWrapper = styled.footer`
  max-width: 90%;
  margin: 64px auto 64px auto;
  transition: all 0.1s ease-in;

  > h3 {
    font-size: 16px;
    color: #d3d3d3;
    margin: 0 0 16px 0;
    padding: 0;
    line-height: 1.2;
    transition: all 0.1s ease-in;
  }

  > li {
    list-style: none;
    font-size: 14px;
    color: #d3d3d3;
    margin: 0 0 8px 8px;
    padding: 0;
    line-height: 1.2;
    transition: all 0.1s ease-in;

    > a {
      font-size: 14px;
      color: #d3d3d3;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &:hover * {
    color: #676767;
  }
`

const LegalInfoFooter = () => {
    return (
        <FooterWrapper>
            <h3>LEGAL</h3>
            <li><NavLink to="/privacy-policy">Privacy Statement</NavLink></li>
            <li><NavLink to="/terms-and-conditions">Terms & Conditions</NavLink></li>
            <li><NavLink to="/cookie-policy">Cookie Policy</NavLink></li>
        </FooterWrapper>
    )
}


export default LegalInfoFooter