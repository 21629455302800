import {createSelector} from 'reselect'
import _ from "lodash"

export const getPatientServiceProviders = state => state.patient_service_providers.docs
export const getPrograms = state => state.programs.docs
export const getMessages = state => state.messages.docs


export const getGroupedMessages = createSelector(
    getMessages,
    messages => {
        return _.chain(messages)
            .sortBy(m => m.millis)
            .groupBy(m => m.channel_path)
            .reduce((result, value, key) => {
                const obj = {}
                obj.path = key
                obj.messages = value
                obj.nurse = value.find(i => i.sender.path.endsWith("nurse"))?.sender?.name ?? "n/a"
                obj.patient = value.find(i => i.sender.path.endsWith("patient"))?.sender?.name ?? "n/a"
                obj.date_formatted_channel = value[0].date_formatted_channel
                obj.download_data = [
                    ["content", "sender", "program", "patient_service_provider", "date"],
                    ...value.map(i => ([
                            i.content.trim(),
                            i.sender.name.trim(),
                            i.program.title.trim(),
                            i.patient_service_provider.title.trim(),
                            i.date_sent
                        ])
                    )
                ]

                result.push(obj)

                return result
            }, [])
            .value()
    }
)

export const getOptions = createSelector(
    getPatientServiceProviders,
    getPrograms,
    (patientServiceProviders, programs) => {
        const options = patientServiceProviders.map(i => {
            const programOptions = programs
                .filter(j => j.patient_service_provider_id === i.id)
                .map(j => ({label: j.title, value: j.path})) ?? []

            return {
                label: i.title,
                options: programOptions
            }
        })

        return [{label: 'All programs', value: 'all'}, ...options]
    }
)

export const allOptions = createSelector(
    getPrograms,
    programs => {
        const options = programs
            .map(j => ({label: j.title, value: j.path})) ?? []

        return [{label: 'All programs', value: 'all'}, ...options]
    }
)

export const patientServiceProviderOptions = createSelector(
    getPatientServiceProviders,
    patientServiceProviders => {
        const options = patientServiceProviders
            .map(j => ({label: j.title, value: j.path})) ?? []

        return [{label: 'All patient service providers', value: 'all'}, ...options]
    }
)