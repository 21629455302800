import Add from '../../ui/Admins/Add'
import {connect} from 'react-redux'
import {addItem} from '../../../actions/admins/addDoc'
import {addError} from '../../../actions/error'
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        fetching: state.admins.fetching,
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        addItem(doc) {
            dispatch(
                addItem(doc)
            )
        },
        addError(error) {
            dispatch(
                addError(error)
            )
        }
    })


export default connect(mapStateToProps, mapDispatchToProps)(Add)