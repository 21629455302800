import styled from 'styled-components'
import theme from "./theme"

const CheckContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto;
  grid-gap: 16px;
  align-items: center;
  margin-bottom: 16px;

  > input[type=checkbox] {
    cursor: pointer;
  }

  > label a {
    line-height: 1.5;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    color: ${props => props.theme.brandPrimary};
  }
`

CheckContainer.defaultProps = {
    theme: theme
}

export default CheckContainer
