import C from '../../constants/actionType'
import {db} from '../index'
import {collection, doc, getDocs, increment, query, setDoc, Timestamp, where, writeBatch} from "firebase/firestore"
import _ from 'lodash'
import {addError} from "../error"
import COLLECTION from '../../constants/collections'
import getKeywords from '../../util/getKeywords'
import getPassword from '../../util/getPassword'
import Role from "../../constants/Role"
import XlsxPopulate from 'xlsx-populate'
import {batch} from "react-redux"


export const setDataProcessingStage = stage =>
    ({
        type: C.DATA_PROCESSING_STAGE,
        payload: stage
    })

const isFetching = fetching =>
    ({
        type: C.DATA_FETCHING_DOCS,
        payload: fetching
    })

const date = () => (new Date()).toISOString().slice(0, 19).replace(/-/g, "-").replace("T", "-").replace(":", "-")

const download = (data, filename, type) => {
    const file = new Blob([data], {type: type})
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, filename)
    } else {
        const a = document.createElement("a")
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        setTimeout(() => {
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
        }, 0)
    }
}

const postData = async (json, addRecords, dispatch) => {

    batch(() => {
        dispatch(
            isFetching(true)
        )
        dispatch(
            setDataProcessingStage("ACTIVE")
        )
    })

    try {

        const erroneousData = []

        let patientIncrement = 0
        let nurseIncrement = 0

        const nurseCollRef = collection(db, COLLECTION.NURSES)
        const patientsCollRef = collection(db, COLLECTION.PATIENTS)
        const serviceProvidersCollRef = collection(db, COLLECTION.PATIENT_SERVICE_PROVIDERS)
        const nurseCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.NURSES)
        const patientCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.PATIENTS)


        if (addRecords) {
            const chunks = _.chunk(json, 300)

            for (const chunk of chunks) {

                const batch = writeBatch(db)

                loopItem:
                    for (const item of chunk) {

                        if (item.hasOwnProperty('nurse_id')) {
                            const nurse_id = item.nurse_id.toLowerCase().replace(/\s/g, '')

                            const q = query(nurseCollRef, where("nurse_id", "==", nurse_id))

                            const querySnapshot = await getDocs(q)

                            if (!querySnapshot.empty) {
                                dispatch(
                                    addError(`NURSE ID ${item.nurse_id} already exists`)
                                )

                                erroneousData.push(item)

                                continue
                            }

                            const uid = doc(nurseCollRef).id + Role.NURSE
                            const nurseRef = doc(db, COLLECTION.NURSES, uid)

                            batch.set(nurseRef, {
                                nurse_id: nurse_id,
                                password: item.password.toLowerCase().replace(/\s/g, '') || getPassword(),
                                first_name: item.first_name ?? "n/a",
                                last_name_initial: item.last_name_initial.charAt(0) ?? "?",
                                online: {
                                    status: false,
                                    is_logged_in: false,
                                    last_updated: Timestamp.now()
                                },
                                is_auth: false,
                                registered: Timestamp.now(),
                                keywords: getKeywords(nurse_id, item.first_name, item.last_name, item.last_name_initial)
                            })

                            nurseIncrement++

                        } else if (item.hasOwnProperty('patient_id')) {

                            const qServiceProviders = query(serviceProvidersCollRef, where("keywords", "array-contains", item.patient_service_provider.toLowerCase().trim()))

                            // Get Patient Service Provider
                            const sQuerySnapshot = await getDocs(qServiceProviders)

                            if (sQuerySnapshot.empty) {
                                dispatch(
                                    addError(`Patient Service Provider ${item.patient_service_provider} not found`)
                                )

                                erroneousData.push(item)

                                continue
                            }

                            const patientServiceProvider = {
                                ref: sQuerySnapshot.docs[0].ref,
                                title: sQuerySnapshot.docs[0].get("title")
                            }

                            // Get Programs
                            const programs = []
                            const programsStrArr = item.programs.split(",").map(i => i.trim().toLowerCase())

                            if (programsStrArr.length === 0) {
                                dispatch(
                                    addError(`Programs not found`)
                                )

                                erroneousData.push(item)

                                continue
                            }

                            for (const programStr of programsStrArr) {
                                const programCollRef = collection(db, COLLECTION.PATIENT_SERVICE_PROVIDERS, patientServiceProvider.ref.id, COLLECTION.PROGRAMS)
                                const pQuery = query(programCollRef, where("keywords", "array-contains", programStr))

                                const pQuerySnapshot = await getDocs(pQuery)

                                if (pQuerySnapshot.empty) {
                                    dispatch(
                                        addError(`Program not found: ${programStr}`)
                                    )

                                    erroneousData.push(item)

                                    continue loopItem
                                }

                                const program = {
                                    ref: pQuerySnapshot.docs[0].ref,
                                    title: pQuerySnapshot.docs[0].get("title")
                                }

                                programs.push(program)
                            }


                            // Get Patient ID
                            const patient_id = item.patient_id.toLowerCase().replace(/\s/g, '')
                            const q = query(patientsCollRef, where("patient_id", "==", patient_id))

                            const pQuerySnapshot = await getDocs(q)

                            if (!pQuerySnapshot.empty) {
                                dispatch(
                                    addError(`Patient ID already exists: ${item.patient_id}.`)
                                )

                                erroneousData.push(item)

                                continue
                            }

                            const uid = doc(patientsCollRef).id + Role.PATIENT

                            const patientRef = doc(db, COLLECTION.PATIENTS, uid)

                            batch.set(patientRef, {
                                patient_service_provider_ref: patientServiceProvider.ref,
                                program_refs: programs.map(i => i.ref),
                                patient_id: patient_id,
                                password: item.password?.toLowerCase()?.replace(/\s/g, '') ?? getPassword(),
                                online: {
                                    status: false,
                                    is_logged_in: false,
                                    last_updated: Timestamp.now()
                                },
                                is_auth: false,
                                registered: Timestamp.now(),
                                keywords: getKeywords(patient_id, patientServiceProvider.title, ...programs.map(i => i.title))
                            })


                        } else {
                            dispatch(
                                addError(`File improper formatting for ${JSON.stringify(item, null, 2)}`)
                            )
                        }

                    }

                await batch.commit()

            }

            if (erroneousData.length > 0) {
                const message = `${Object.keys(erroneousData[0]).join(",")}\n${erroneousData.map(i => Object.values(i).join(",")).join("\n")}`
                download(message, `error-${date()}`, "text/csv")
            }

        } else {

            const chunks = _.chunk(json, 400)

            for (const chunk of chunks) {

                const batch = writeBatch(db)

                for (const item of chunk) {

                    if (item.hasOwnProperty('nurse_id')) {
                        const nurse_id = item.nurse_id.toLowerCase().replace(/\s/g, '')

                        const q = query(nurseCollRef, where("nurse_id", "==", nurse_id))

                        const querySnapshot = await getDocs(q)

                        if (!querySnapshot.empty) {
                            nurseIncrement -= querySnapshot.docs.length
                            querySnapshot.forEach(snap => {
                                const ref = doc(db, COLLECTION.NURSES, snap.id)
                                batch.delete(ref)
                            })
                        } else {
                            erroneousData.push(item)
                        }

                    } else if (item.hasOwnProperty('patient_id')) {
                        const patient_id = item.patient_id.toLowerCase().replace(/\s/g, '')

                        const q = query(patientsCollRef, where("patient_id", "==", patient_id))

                        const querySnapshot = await getDocs(q)

                        if (!querySnapshot.empty) {
                            patientIncrement -= querySnapshot.docs.length
                            querySnapshot.forEach(snap => {
                                const ref = doc(db, COLLECTION.PATIENTS, snap.id)
                                batch.delete(ref)
                            })
                        } else {
                            erroneousData.push(item)
                        }


                    } else {
                        dispatch(
                            addError(`csv file improper formatting for ${JSON.stringify(item, null, 2)}`)
                        )
                    }
                }

                await batch.commit()
            }

            if (erroneousData.length > 0) {
                const message = `${Object.keys(erroneousData[0]).join(",")}\n${erroneousData.map(i => Object.values(i).join(",")).join("\n")}`
                download(message, `error-${date()}`, "text/csv")
            }

        }

        if (nurseIncrement !== 0) {
            await setDoc(nurseCounterRef, {total: increment(nurseIncrement)}, {merge: true})
        }

        if (patientIncrement !== 0) {
            await setDoc(patientCounterRef, {total: increment(patientIncrement)}, {merge: true})
        }
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        batch(() => {
            dispatch(
                isFetching(false)
            )
            dispatch(
                setDataProcessingStage("COMPLETED")
            )
        })
    }

}


export const uploadDataDocs = (inputFile, addRecords) => async (dispatch, getState) => {
    dispatch(
        isFetching(true)
    )

    try {
        const workbook = await XlsxPopulate.fromDataAsync(inputFile)
        const raw = workbook.activeSheet().usedRange().value().filter(i => !i.includes(undefined))

        const header = raw.shift()

        if (raw.length > 0 &&
            header.includes("nurse_id") &&
            header.includes("password") &&
            header.includes("first_name") &&
            header.includes("last_name_initial")) {

            const data = _.chain(raw).map(i => (_.zipObject(header, i))).uniqBy("nurse_id").value()

            postData(data, addRecords, dispatch)

        } else if (raw.length > 0 &&
            header.includes("patient_id") &&
            header.includes("password") &&
            header.includes("patient_service_provider") &&
            header.includes("programs")) {

            const data = _.chain(raw).map(i => (_.zipObject(header, i))).uniqBy("patient_id").value()

            postData(data, addRecords, dispatch)

        } else {
            dispatch(
                addError(`excel file improper formatting`)
            )
        }


    } catch (error) {
        batch(() => {
            dispatch(
                addError(error.message)
            )

            dispatch(
                isFetching(false)
            )
        })

    }
}
