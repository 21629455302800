import _ from "lodash"
import {helpDocsListener} from './help/listener'
import {programInformationDocListener} from './program_information/listener'
import {adminsDocListener} from './admins/listener'
import {patientServiceProvidersDocListener} from './patient_service_providers/listener'
import {getPatients} from './patients/index'
import {patientCountListener} from './patients/patientCountListener'
import {nursesDocListener} from "./nurses/listener"
import {auth} from "./index"
import {onAuthStateChanged} from 'firebase/auth'
import ACTION from "../constants/actionType"
import {feedbackDocListener} from "./analytics/feedback"
import {appointmentListener} from "./appointments"
import {programsDocListener} from "./programs/listener"
import {notificationsDocListener} from "./notifications/listener"
import {messagesDocListener} from "./messages/listener"


const removeUser = () =>
    ({
        type: ACTION.AUTH_LOGOUT,
        payload: null
    })


const setUpListeners = async dispatch => {

    dispatch(
        getPatients()
    )

    dispatch(
        helpDocsListener()
    )

    dispatch(
        programInformationDocListener()
    )

    dispatch(
        adminsDocListener()
    )

    dispatch(
        patientServiceProvidersDocListener()
    )

    dispatch(
        programsDocListener()
    )

    dispatch(
        notificationsDocListener()
    )

    dispatch(
        nursesDocListener()
    )

    dispatch(
        feedbackDocListener()
    )

    dispatch(
        appointmentListener()
    )

    dispatch(
        messagesDocListener()
    )

    dispatch(
        patientCountListener()
    )

}

const removeListeners = async () => {

    if (_.isFunction(window.feedbackDocListener)) {
        window.feedbackDocListener()
        delete window.feedbackDocListener
    }

    if (_.isFunction(window.patientServiceProvidersDocListener)) {
        window.patientServiceProvidersDocListener()
        delete window.patientServiceProvidersDocListener
    }

    if (_.isFunction(window.programInformationDocListener)) {
        window.programInformationDocListener()
        delete window.programInformationDocListener
    }

    if (_.isFunction(window.helpDocsListener)) {
        window.helpDocsListener()
        delete window.helpDocsListener
    }

    if (_.isFunction(window.nursesDocListener)) {
        window.nursesDocListener()
        delete window.nursesDocListener
    }

    if (_.isFunction(window.getPatientsListener)) {
        window.getPatientsListener()
        delete window.getPatientsListener
    }

    if (_.isFunction(window.adminsDocListener)) {
        window.adminsDocListener()
        delete window.adminsDocListener
    }

    if (_.isFunction(window.appointmentListener)) {
        window.appointmentListener()
        delete window.appointmentListener
    }

    if (_.isFunction(window.programsDocListener)) {
        window.programsDocListener()
        delete window.programsDocListener
    }

    if (_.isFunction(window.notificationsDocListener)) {
        window.notificationsDocListener()
        delete window.notificationsDocListener
    }

    if (_.isFunction(window.messagesDocListener)) {
        window.messagesDocListener()
        delete window.messagesDocListener
    }

    if (_.isFunction(window.patientCountListener)) {
        window.patientCountListener()
        delete window.patientCountListener
    }

}

export const dataListeners = () => async (dispatch, getState) => {

    if (_.isFunction(window.authListener)) {
        window.authListener()
        delete window.authListener
    }

    window.authListener = onAuthStateChanged(auth, currentUser => {
        if (currentUser) {
            setUpListeners(dispatch)
        } else {
            removeListeners()
            dispatch(
                removeUser()
            )
        }
    })
}