import React from 'react'
import '../../../assets/scss/Nurses/Form.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import getKeywords from "../../../util/getKeywords"
import ResetButton from "../Styled/ResetButton"
import {useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import FormControl from "../Styled/FormControl"
import OutlineLargeDangerButton from "../Styled/OutlineLargeDangerButton"
import PrimaryButton from "../Styled/PrimaryButton"


const Form = props => {

    const {register, handleSubmit, setValue, watch, formState: {errors}} = useForm({mode: 'onSubmit'})

    const onSubmit = data => {
        data.id = props.id
        data.path = props.path


        data.keywords = getKeywords(nurse_id, first_name, last_name_initial)

        props.handleUpdate(data)
    }

    const nurse_id = watch("nurse_id", props.nurse_id)
    const password = watch("password", props.password)
    const first_name = watch("first_name", props.first_name)
    const last_name_initial = watch("last_name_initial", props.last_name_initial)

    return (
        <form
            className="edit-nurse"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault()
            }}
        >
            <div className="nurse_id">
                <Label
                    id={`nurse-id-${props.id}`}
                    data-tooltip-id={`update-nurse-tooltip-${props.id}`}
                    data-tooltip-content={`Nurse's ID`}>
                    Nurse ID
                </Label>
                <FormControl>
                    <InputField
                        type="text"
                        placeholder="Please enter a nurse ID..."
                        defaultValue={props.nurse_id}
                        {...register(
                            'nurse_id',
                            {
                                required: true
                            })
                        }
                        readOnly
                    />
                </FormControl>
            </div>

            <div className="password">
                <Label
                    id={`nurse-password-${props.id}`}
                    data-tooltip-id={`update-nurse-tooltip-${props.id}`}
                    data-tooltip-content={`Nurse's password`}>
                    Password
                </Label>
                <FormControl>
                    <InputField
                        type="password"
                        autoComplete="off"
                        isValid={!!errors.password}
                        placeholder="Please enter a password..."
                        defaultValue={props.password}
                        {...register(
                            'password',
                            {
                                required: true,
                                validate: {
                                    lowerCase: value => value.toLowerCase() === value,
                                    noSpaces: value => value.replace(" ", "") === value,
                                    longerThanThreeLetters: value => value.length > 2
                                }
                            })
                        }
                    />
                    {password && password.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('password', '')}
                            type="reset"
                        />
                    }
                </FormControl>
                {errors.password && errors.password.type === 'required' &&
                    <InvalidFeedback>The `Password` field is required</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'lowerCase' &&
                    <InvalidFeedback>The password needs to be in lowercase</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'noSpaces' &&
                    <InvalidFeedback>The password cannot have spaces</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'longerThanThreeLetters' &&
                    <InvalidFeedback>The password must be at least 3 characters</InvalidFeedback>
                }
            </div>

            <div className="first_name">
                <Label
                    id={`nurse-first-name-${props.id}`}
                    data-tooltip-id={`update-nurse-tooltip-${props.id}`}
                    data-tooltip-content={`Nurse's first name`}>
                    First Name
                </Label>
                <FormControl>
                    <InputField
                        isValid={!!errors.first_name}
                        type="text"
                        placeholder="Please enter a first name..."
                        defaultValue={props.first_name}
                        {...register(
                            'first_name',
                            {required: true}
                        )
                        }
                    />
                    {first_name.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('first_name', '')}
                            type="reset"
                        />
                    }
                </FormControl>
                {errors.first_name && <InvalidFeedback>The `First Name` field is required</InvalidFeedback>}
            </div>

            <div className="last_name_initial">
                <Label
                    id={`nurse-last-name-initial-${props.id}`}
                    data-tooltip-id={`update-nurse-tooltip-${props.id}`}
                    data-tooltip-content={`Nurse's last name initial`}
                >Last Name Initial</Label>
                <FormControl>
                    <InputField
                        isValid={!!errors.last_name_initial}
                        type="text"
                        placeholder="Please enter a last name initial..."
                        defaultValue={props.last_name_initial}
                        {...register(
                            'last_name_initial',
                            {
                                required: true,
                                validate: {
                                    noSpaces: value => value.replace(" ", "") === value,
                                    onlyOneLetter: value => value.length === 1
                                }
                            })
                        }
                    />
                    {last_name_initial.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('last_name_initial', '')}
                            type="reset"
                        />
                    }
                </FormControl>
                {errors.last_name_initial && errors.last_name_initial.type === 'required' &&
                    <InvalidFeedback>The `Last Name Initial` field is required</InvalidFeedback>
                }
                {errors.last_name_initial && errors.last_name_initial.type === 'noSpaces' &&
                    <InvalidFeedback>The `Last Name Initial` field cannot have spaces</InvalidFeedback>
                }
                {errors.last_name_initial && errors.last_name_initial.type === 'onlyOneLetter' &&
                    <InvalidFeedback>The `Last Name Initial` field must be 1 character in length</InvalidFeedback>
                }
            </div>

            <OutlineLargeDangerButton
                className="trash"
                type="button"
                onClick={e => {
                    e.preventDefault()
                    props.handleDelete(props.id)
                }}
                disabled={true}>
                Delete Nurse
            </OutlineLargeDangerButton>

            <PrimaryButton
                id={`nurse-update-btn-${props.id}`}
                disabled={props.fetching}
                className="update btn"
                type="submit"
                data-tooltip-id={`update-nurse-tooltip-${props.id}`}
                data-tooltip-content={`Click to update record`}>
                UPDATE
            </PrimaryButton>

            <Tooltip
                id={`update-nurse-tooltip-${props.id}`}
                float={true}
            />

        </form>
    )
}


export default Form