import C from '../../constants/actionType'
import {db} from '../index'
import {collection, doc, getDocs, increment, query, setDoc, Timestamp, where} from "firebase/firestore"
import {addError} from "../error"
import COLLECTION from '../../constants/collections'
import Role from "../../constants/Role"
import {batch} from "react-redux"


const isFetching = fetching =>
    ({
        type: C.PATIENTS_FETCHING_DOCS,
        payload: fetching
    })

export const addItem = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        item.is_auth = false
        item.registered = Timestamp.now()
        item.online = {
            status: false,
            is_logged_in: false,
            last_updated: Timestamp.now()
        }

        item.program_refs = item.program_path.map(i => doc(db, i.value))
        item.patient_service_provider_ref = doc(db, item.program_refs[0].parent.parent.path)

        delete item.program_path

        const patientsCollRef = collection(db, COLLECTION.PATIENTS)

        const q = query(patientsCollRef, where("patient_id", "==", item.patient_id))

        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
            throw new Error(`Patient with patient id: ${item.patient_id} already exists. Please use a unique id`)
        }

        const id = doc(patientsCollRef).id + Role.PATIENT

        await setDoc(doc(db, COLLECTION.PATIENTS, id), item)

        const patientsCountersRef = doc(db, COLLECTION.COUNTERS, COLLECTION.PATIENTS)

        await setDoc(patientsCountersRef, {total: increment(1)}, {merge: true})
    } catch (error) {
        batch(() => {
            dispatch(
                addError(error.message)
            )

            dispatch(
                isFetching(false)
            )
        })
    }

}
