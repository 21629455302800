import Analytics from '../../ui/Analytics'
import {connect} from 'react-redux'
import {
    feedbackDocListener,
    setEndDate,
    setPatientServiceProviderPath,
    setStartDate
} from '../../../actions/analytics/feedback'
import {getAllOptions, getAllPrograms, getData, getDates, getEndDate, getPatients, getStartDate} from "./selectors"
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        docs: state.feedback.docs,
        fetching: state.feedback.fetching,
        start_date: getStartDate(state),
        end_date: getEndDate(state),
        account_id: state.feedback.account_id,
        allPrograms: getAllOptions(state),
        options: getAllPrograms(state),
        programs: getAllPrograms(state),
        patient_service_provider_path: state.feedback.patient_service_provider_path,
        dates: getDates(state),
        patients: getPatients(state),
        data: getData(state),
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        fetchFeedback() {
            dispatch(
                feedbackDocListener()
            )
        },
        setStartDate(start_date) {
            dispatch(
                setStartDate(start_date)
            )
        },
        setEndDate(end_date) {
            dispatch(
                setEndDate(end_date)
            )
        },
        setPatientServiceProviderPath(path) {
            dispatch(
                setPatientServiceProviderPath(path)
            )
        }
    })


export default connect(mapStateToProps, mapDispatchToProps)(Analytics)
