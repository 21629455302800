import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/Admins/Add.scss'
import getKeywords from '../../../util/getKeywords'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import ResetButton from "../Styled/ResetButton"
import Select from "react-select"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import AdminGroupType from "../../../constants/AdminGroupType"
import OutlineLargeDangerButton from "../Styled/OutlineLargeDangerButton"
import colourStyles from "../../../constants/colorStyles"
import VersionNumber from "../Styled/VersionNumber"
import LegalInfoFooter from "../LegalInfoFooter"
import {logEvent} from "firebase/analytics"


const Add = ({addItem, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Add Admin"
    })

    const navigate = useNavigate()

    const {register, handleSubmit, setValue, watch, formState: {errors}, reset, control} = useForm({
        mode: 'onSubmit',
        defaultValues: {
            admin_id: "",
            password: "",
            admin_group: null,
            first_name: "",
            last_name: ""
        }
    })

    const onSubmit = data => {
        data.admin_group = data.admin_group.value
        data.is_auth = false
        data.keywords = getKeywords(data.admin_id, data.first_name, data.last_name, data.admin_group)

        addItem(data)
        navigate('/admins')
    }

    const [admin_id, password, first_name, last_name] = watch(["admin_id", "password", "first_name", "last_name"])

    return (
        <main className="app add-admin">
            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Add Admin"/>

                <section className="content">

                    <h3>New Admin</h3>
                    <Link id="admin-add-click-to-close"
                          to="/admins"
                          className="close"
                          data-tooltip-id="add-admin-tooltip"
                          data-tooltip-content={`Click to close`}>
                        <img src={close} alt="close icon"/>
                    </Link>

                    <form
                        className="add-admin-form"
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                        }}
                    >

                        <div className="admin_id">
                            <Label
                                id="admin-add-admin-id"
                                data-tooltip-content={`Admin's ID`}
                                data-tooltip-id="add-admin-tooltip">Admin ID</Label>

                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.admin_id}
                                    type="text"
                                    placeholder="Please enter an admin ID..."
                                    {...register(
                                        'admin_id',
                                        {
                                            required: true,
                                            validate: {
                                                lowerCase: value => value.toLowerCase() === value,
                                                noSpaces: value => value.replace(" ", "") === value,
                                                longerThanThreeLetters: value => value.length > 2
                                            }
                                        })
                                    }
                                />
                                {admin_id.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('admin_id', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.admin_id && errors.admin_id.type === 'required' &&
                                <InvalidFeedback>The `Admin ID` field is required</InvalidFeedback>
                            }
                            {errors.admin_id && errors.admin_id.type === 'lowerCase' &&
                                <InvalidFeedback>The `Admin ID` needs to be in lowercase</InvalidFeedback>
                            }
                            {errors.admin_id && errors.admin_id.type === 'noSpaces' &&
                                <InvalidFeedback>The `Admin ID` cannot have spaces</InvalidFeedback>
                            }
                            {errors.admin_id && errors.admin_id.type === 'longerThanThreeLetters' &&
                                <InvalidFeedback>The `Admin ID` must be at least 3 characters</InvalidFeedback>
                            }
                        </div>

                        <div className="password">
                            <Label
                                id="admin-add-password"
                                data-tooltip-id="add-admin-tooltip"
                                data-tooltip-content={`Admin's password`}>Password</Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    autoComplete="off"
                                    isValid={!!errors.password}
                                    type="text"
                                    placeholder="Please enter a password..."
                                    {...register(
                                        'password',
                                        {
                                            required: true,
                                            validate: {
                                                lowerCase: value => value.toLowerCase() === value,
                                                noSpaces: value => value.replace(" ", "") === value,
                                                longerThanThreeLetters: value => value.length > 2
                                            }
                                        })
                                    }
                                />
                                {password.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('password', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.password && errors.password.type === 'required' &&
                                <InvalidFeedback>The `Password` field is required</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'lowerCase' &&
                                <InvalidFeedback>The password needs to be in lowercase</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'noSpaces' &&
                                <InvalidFeedback>The password cannot have spaces</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'longerThanThreeLetters' &&
                                <InvalidFeedback>The password must be at least 3 characters</InvalidFeedback>
                            }
                        </div>

                        <div className="first_name">
                            <Label
                                id="admin-add-first-name"
                                data-tooltip-id="add-admin-tooltip"
                                data-tooltip-content={`Admin's first name`}>
                                First name
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.first_name}
                                    type="text"
                                    placeholder="Please enter a first name..."
                                    {...register(
                                        'first_name',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {first_name.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('first_name', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.first_name && <InvalidFeedback>The `First Name` field is required</InvalidFeedback>}

                        </div>

                        <div className="last_name">
                            <Label
                                id="admin-add-last-name"
                                data-tooltip-id="add-admin-tooltip"
                                data-tooltip-content={`Admin's last name`}>
                                Last Name
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.last_name}
                                    type="text"
                                    placeholder="Please enter a last name..."
                                    {...register(
                                        'last_name',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {last_name.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('last_name', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.last_name && <InvalidFeedback>The `Last Name` field is required</InvalidFeedback>}
                        </div>

                        <div className="admin_group">
                            <Label
                                id="admin-add-group"
                                data-tooltip-id="add-admin-tooltip"
                                data-tooltip-content={`Admin's group`}>
                                Admin Group
                            </Label>
                            <Controller
                                render={({field}) => <Select
                                    {...field}
                                    options={AdminGroupType}
                                    className="react-select-field"
                                    placeholder="Please select an admin group..."
                                    styles={colourStyles}
                                />
                                }
                                control={control}
                                rules={{required: true}}
                                name="admin_group"
                            />
                            {errors.account_id &&
                                <InvalidFeedback>The `Admin Group` field is required</InvalidFeedback>
                            }
                        </div>

                        <OutlineLargeDangerButton
                            className="reset"
                            type="button"
                            onClick={() => reset()}>
                            Reset Form
                        </OutlineLargeDangerButton>

                        <input
                            id="admin-add-record"
                            data-tooltip-id="add-admin-tooltip"
                            data-tooltip-content={`Click to add record`}
                            className="add btn"
                            type="submit"
                            value="ADD ADMIN"
                        />

                        <Tooltip
                            id="add-admin-tooltip"
                            float={true}
                        />

                    </form>

                </section>

            </article>

            <VersionNumber/>
            <LegalInfoFooter/>
        </main>
    )
}


export default Add