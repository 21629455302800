import React from 'react'


const Sort = ({isSorted = false, isSortedDesc = false}) => (
    <svg viewBox="0 0 44 61">
        {!isSorted &&
            <>
                <path
                    fill="#1A428A"
                    stroke="none"
                    d="M39.6 34.5H4.41c-3.91 0-5.89 4.76-3.12 7.54l17.6 17.67a4.394 4.394 0 006.22 0l17.6-17.67c2.76-2.77.8-7.54-3.11-7.54zM22 56.58L4.4 38.92h35.2L22 56.58zm0 0"
                />
                <path
                    fill="#1A428A"
                    stroke="none"
                    d="M4.4 26.5h35.19c3.91 0 5.89-4.76 3.12-7.54L25.11 1.29a4.394 4.394 0 00-6.22 0L1.29 18.96c-2.76 2.77-.8 7.54 3.11 7.54zM22 4.42l17.6 17.66H4.4L22 4.42zm0 0"
                />
            </>
        }

        {(isSorted && isSortedDesc) &&
            <path
                fill="#1A428A"
                stroke="none"
                d="M39.6 34.5H4.41c-3.91 0-5.89 4.76-3.12 7.54l17.6 17.67a4.394 4.394 0 006.22 0l17.6-17.67c2.76-2.77.8-7.54-3.11-7.54zM22 56.58L4.4 38.92h35.2L22 56.58zm0 0"
            />
        }

        {(isSorted && !isSortedDesc) &&
            <path
                fill="#1A428A"
                stroke="none"
                d="M4.4 26.5h35.19c3.91 0 5.89-4.76 3.12-7.54L25.11 1.29a4.394 4.394 0 00-6.22 0L1.29 18.96c-2.76 2.77-.8 7.54 3.11 7.54zM22 4.42l17.6 17.66H4.4L22 4.42zm0 0"
            />
        }
    </svg>
)


export default Sort