import PrivacyPolicy from '../../ui/PrivacyPolicy'
import {connect} from 'react-redux'
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({})


export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy)