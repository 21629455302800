import styled from 'styled-components'
import theme from "./theme"


const FormControl = styled.div`
  position: relative;
`

FormControl.defaultProps = {
    theme: theme
}

export default FormControl