import Add from '../../ui/PatientServiceProviders/Add'
import {connect} from 'react-redux'
import {setItems} from '../../../actions/batch/patient_service_providers'
import {addError} from '../../../actions/error'
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        fetching: state.patient_service_providers.fetching,
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        setItems(doc) {
            dispatch(
                setItems(doc)
            )
        },
        addError(error) {
            dispatch(
                addError(error)
            )
        }
    })


export default connect(mapStateToProps, mapDispatchToProps)(Add)