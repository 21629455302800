import React, {useEffect} from 'react'
import logo from '../../assets/svg/logo-with-phoenix.svg'
import '../../assets/scss/TermsConditions.scss'
import GoBack from "./GoBack"
import {logEvent} from "firebase/analytics"
import {useLocation} from "react-router-dom"


const TermsConditions = ({analytics}) => {

    const {pathname} = useLocation()

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Terms & Conditions"
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <main id="terms-and-conditions">
            <article className="content">

                <GoBack/>

                <img src={logo} className="logo" alt="TCP Homecare"/>

                <h1 className="text-center display-4">Terms and conditions/Legal notice</h1>

                <p>To users of this site, the following provisions apply:</p>

                <p>This Site is owned by TCP Homecare or its affiliates are the owners or licensee of all intellectual
                    property
                    rights attaching to the website and in the material published on it. All texts and illustrations are
                    covered
                    by copyright. All such rights are reserved. No rights or licenses of any kind with respect to
                    trademarks,
                    copyrights, patents, etc. shall be granted to the user.</p>

                <p>This site is operated by Temperature Controlled Pharmaceuticals (referred to as “TCP”, “TCP
                    Homecare”, “TCP
                    Logistics”, “TCP Group”, “we”, “us” or “our”), which expression shall also include our subsidiaries
                    or
                    associated companies including, where the context requires, our third party suppliers and any other
                    person
                    involved with the provision or maintenance of this Site.</p>

                <p>This Site is controlled and operated by TCP from our office in Dublin, Ireland.</p>

                <p>The content appearing on the pages of this Site, which is variously known as information or
                    materials, is
                    provided in accordance with and subject to the laws of Ireland. Although accessible by others, the
                    information
                    appearing on the pages of this Site is primarily intended for use by Irish residents. TCP makes no
                    representations that materials on this Site are appropriate or available for use in other locations.
                    Users who
                    access this Site from other locations do so on their own initiative and risk and are responsible for
                    compliance with local laws.</p>

                <p>No information on this Site should be construed as a promotion or solicitation for any product or for
                    the use
                    of any product which is not authorised by the applicable laws and regulations of your place of
                    citizenship,
                    domicile or residence. The information contained on the pages of the Site is not intended to
                    constitute an
                    offer, promotion, or solicitation to Users to buy or sell any products or services of TCP. The
                    information
                    provided on the pages of this Site, including these TERMS AND CONDITIONS OF USE, is provided for
                    your
                    information, general interest and entertainment purposes only, may not be up to date and is subject
                    to change
                    and update by TCP from time to time without notification. You should periodically revisit this page
                    to review
                    the then-current TERMS AND CONDITIONS OF USE.</p>

                <p>Though TCP has taken all reasonable steps to ensure the accuracy, currency, availability, correctness
                    and
                    completeness of the information contained on the pages of this Site, the information is provided “AS
                    IS”
                    without warranty of any kind, whether express or implied, including, but not limited to, implied
                    warranties of
                    quality, fitness for a particular purpose or non-infringement. The use of this Site is at the User’s
                    sole
                    risk. TCP shall not be liable for any loss or damage howsoever arising as a result of use of or
                    reliance on
                    this information, whether authorised or not, to the maximum extent permitted by the applicable
                    law.</p>

                <p>Users should consult their health care professionals in respect of any healthcare or pharmaceutical
                    related
                    information provided on this Site.</p>

                <p>In accessing this Site the User accepts that electronic mail passing over the Internet may not be
                    free from
                    interference by third parties. In consequence TCP cannot guarantee the privacy or confidentiality of
                    any
                    information relating to the User passing over the Internet. The User shall be responsible for the
                    content and
                    information contained in all your communications to this Site, including its lawfulness,
                    truthfulness and
                    accuracy. In particular, but without limitation, you should not post any unlawful, threatening,
                    defamatory or
                    obscene material or any material that could give rise to a criminal offence and/or civil liability
                    in any
                    relevant jurisdiction.</p>

                <p>It is entirely at your discretion to send any information to TCP. While TCP is bound by applicable
                    data
                    protection legislation, information which is not personal will not be deemed confidential and TCP
                    shall be
                    free to disclose or use it, without obligation to you, as it may deem appropriate. The information
                    contained
                    on and the contents of the pages of this Site, including but not limited to the layout and
                    expression, are TCP
                    and/or its third party suppliers and may not be copied, transmitted, converted, transcribed or
                    reproduced
                    without the prior consent of TCP and except in accordance with the following permission: You may
                    print or
                    download on any single computer one copy of the contents of these pages (other than those excepted
                    from this
                    licence under clause 11) for your personal non-commercial home use. All copyright notices,
                    proprietary notices
                    and disclaimers should also be copied. All rights not expressly granted by us in these TERMS AND
                    CONDITIONS OF
                    USE are hereby reserved.</p>

                <p>The brand names and product names referred to in this Site are trade marks or trade names of TCP
                    Homecare or
                    its affiliates. Where applicable, the author(s) of the literary and artistic works set out on the
                    pages of
                    this Site has/have asserted his/her/their moral rights to be identified as the author of those
                    works. Their
                    identities are set out on the pages containing their works. All reproduction of these pages is
                    prohibited,
                    including printing and downloading. In addition, in respect of any software downloaded from this
                    Site, title,
                    including all intellectual property rights, shall vest and remain vested in TCP and/or its third
                    party
                    suppliers. The limited licence to copy materials on this Site set out above does not permit
                    incorporation of
                    the material or any part of it in any other work or publication, whether in hard copy, electronic or
                    any other
                    form.</p>

                <p>In particular, but without limitation no part of the Site pages may be distributed or copied for any
                    commercial purposes. No part of these Site pages may be reproduced on or transmitted to or stored in
                    any other
                    web site or other form of electronic retrieval system. Use of this Site shall be made subject to the
                    laws of
                    Ireland which shall exclusively govern the interpretation, application and effect of all the above
                    permissions, exclusions, licences and conditions of use. The courts of Ireland shall have exclusive
                    jurisdiction over all claims or disputes arising in relation to, out of or in connection with this
                    Site and
                    its use. If any provision of these TERMS AND CONDITIONS OF USE shall be held unlawful, void or
                    unenforceable
                    for any reason then such provision shall be deemed severed and shall not affect the validity and
                    enforceability of the remaining provisions. These TERMS AND CONDITIONS OF USE contain the entire
                    agreement
                    between the parties relating to the subject matter herein.</p>

            </article>
        </main>
    )

}

export default TermsConditions