import React from 'react'


const ChevronRight = ({enabled = true}) => (
    <svg width="16" height="30" viewBox="0 0 16 30">
        <path
            fill={enabled ? "#1A428A" : "#CCCCCC"}
            d="M.7 29.76l-.47-.47a.815.815 0 0 1 0-1.14L13.15 15 .23 1.85a.815.815 0 0 1 0-1.14L.7.24c.31-.32.81-.32 1.12 0l13.95 14.19c.31.31.31.83 0 1.14L1.82 29.76c-.31.32-.81.32-1.12 0zm0 0"
        />
    </svg>
)

export default ChevronRight
