import React from 'react'
import '../../../assets/scss/Patients/Form.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Select from "react-select"
import getKeywords from "../../../util/getKeywords"
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import OutlineLargeDangerButton from "../Styled/OutlineLargeDangerButton"
import PrimaryButton from "../Styled/PrimaryButton"
import colourStyles from "../../../constants/colorStyles"
import _ from "lodash"


const Form = props => {

    const {register, handleSubmit, setValue, watch, formState: {errors}, control} = useForm({mode: 'onSubmit'})

    const password = watch("password", props.password)
    const program_path = watch("program_path", props.program_paths)

    const onSubmit = data => {
        data.id = props.id
        data.path = props.path

        const programs = data.program_path.map(i => i.label)

        data.keywords = getKeywords(data.patient_id, ...programs)

        props.handleUpdate(data)
    }

    return (
        <form
            className="edit-patient-form"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault()
            }}
        >

            <div className="program_path">
                <Label
                    id={`patient-programs-${props.id}`}
                    data-tooltip-id={`update-patient-tooltip-${props.id}`}
                    data-tooltip-content={`Patient's programs`}>Programs</Label>
                <Controller
                    render={({field}) =>
                        <Select
                            {...field}
                            maxMenuHeight={170}
                            options={props.options}
                            className="react-select-field"
                            placeholder="Please select a program..."
                            styles={colourStyles}
                            isMulti
                        />
                    }
                    defaultValue={_.isNull(program_path) ? null : props.allOptions.filter(program => program_path.includes(program.value))}
                    control={control}
                    rules={{required: true}}
                    name="program_path"
                />
                {errors.program_path && <InvalidFeedback>The `Programs` field is required</InvalidFeedback>}
            </div>

            <div className="patient_id">
                <Label
                    id={`patient-id-${props.id}`}
                    data-tooltip-id={`update-patient-tooltip-${props.id}`}
                    data-tooltip-content={`Patient's ID`}>Patient ID</Label>
                <InputField
                    isValid={!!errors.patient_id}
                    type="text"
                    placeholder="Please enter a Patient ID..."
                    defaultValue={props.patient_id}
                    {...register(
                        'patient_id',
                        {
                            required: true,
                            validate: {
                                lowerCase: value => value.toLowerCase() === value,
                                noSpaces: value => value.replace(" ", "") === value,
                                longerThanThreeLetters: value => value.length > 2
                            }
                        })
                    }
                    readOnly
                />
            </div>

            <div className="password">
                <Label
                    id={`patient-password-${props.id}`}
                    data-tooltip-id={`update-patient-tooltip-${props.id}`}
                    data-tooltip-content={`Patient's password`}>Password</Label>

                <div style={{position: "relative"}}>
                    <InputField
                        autoComplete="off"
                        isValid={!!errors.password}
                        type="password"
                        placeholder="Please enter the password..."
                        defaultValue={props.password}
                        {...register(
                            'password',
                            {
                                required: true,
                                validate: {
                                    lowerCase: value => value.toLowerCase() === value,
                                    noSpaces: value => value.replace(" ", "") === value,
                                    longerThanThreeLetters: value => value.length > 2
                                }
                            })
                        }
                    />
                    {password.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('password', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.password && errors.password.type === 'required' &&
                    <InvalidFeedback>The `Password` field is required</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'lowerCase' &&
                    <InvalidFeedback>The password needs to be in lowercase</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'noSpaces' &&
                    <InvalidFeedback>The password cannot have spaces</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'longerThanThreeLetters' &&
                    <InvalidFeedback>The password must be at least 3 characters</InvalidFeedback>
                }
            </div>

            <OutlineLargeDangerButton
                className="trash"
                type="button"
                onClick={e => {
                    e.preventDefault()
                    props.handleDelete(props.id)
                }}
                disabled={true}>
                Delete Patient
            </OutlineLargeDangerButton>

            <PrimaryButton
                id={`patient-update-record-btn-${props.id}`}
                disabled={props.fetching}
                className="update btn"
                type="submit"
                value="UPDATE"
                data-tooltip-id={`update-patient-tooltip-${props.id}`}
                data-tooltip-content={`Click to update record`}>
                UPDATE
            </PrimaryButton>

            <Tooltip
                id={`update-patient-tooltip-${props.id}`}
                float={true}
            />

        </form>
    )
}


export default Form