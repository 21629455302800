import Nurses from '../../ui/Nurses'
import {connect} from 'react-redux'
import {deleteItem, setItem} from '../../../actions/nurses'
import {addError} from '../../../actions/error'
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        accounts: state.patient_service_providers.docs,
        docs: state.nurses.docs,
        fetching: state.nurses.fetching,
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        deleteItem(doc) {
            dispatch(
                deleteItem(doc)
            )
        },
        setItem(doc) {
            dispatch(
                setItem(doc)
            )
        },
        addError(error) {
            dispatch(
                addError(error)
            )
        }
    })

export default connect(mapStateToProps, mapDispatchToProps)(Nurses)
