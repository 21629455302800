import styled from "styled-components"
import theme from "./theme"


const SlideDrawerWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 500px;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  will-change: transform;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  padding: 20px;
  transform: ${props => props.show ? 'translateX(0)' : 'translateX(calc(100% + 50px))'};
  transition: all 0.3s ease-out;
  z-index: 9999;
`

SlideDrawerWrapper.defaultProps = {
    theme: theme
}


export default SlideDrawerWrapper