import {createSelector} from 'reselect'

export const getPatientServiceProviders = state => state.patient_service_providers.docs
export const getPrograms = state => state.programs.docs
export const getNotifications = state => state.notifications.docs

export const getProgramsWithNotifications = createSelector(
    getPrograms,
    getNotifications,
    (programs, notifications) => programs.map(i => {
        i.notifications = notifications.filter(j => j.program_id === i.id)
        return i
    })
)

export const getData = createSelector(
    getPatientServiceProviders,
    getProgramsWithNotifications,
    (patientServiceProviders, programs) => patientServiceProviders.map(i => {
        i.programs = programs.filter(j => j.patient_service_provider_id === i.id)
        return i
    })
)
