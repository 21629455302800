import styled from 'styled-components'
import theme from "./theme"


const OutlineLargeDangerButton = styled.button`
  background-color: #fff;
  color: ${props => props.theme.brandDanger};
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  padding: .375rem .75rem;
  border-radius: 0.25rem;
  border: 1px solid ${props => props.theme.brandDanger};
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s ease-in;
  line-height: 1.5;
  margin: 0 1.6rem 0 0;
  height: 3rem;
  align-self: center;

  &:hover {
    background-color: ${props => props.theme.brandDanger};
    color: #fff;
  }

  &:disabled {
    color: ${props => props.theme.brandDangerDisabled};
    opacity: .65;
    background-color: transparent;
    cursor: not-allowed;
  }
`
OutlineLargeDangerButton.defaultProps = {
    theme: theme
}

export default OutlineLargeDangerButton