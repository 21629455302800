import styled from 'styled-components'
import theme from "./theme"


const Textarea = styled.textarea`
  display: block;
  width: 100%;
  height: 13rem;
  padding: 0.7rem .75rem;
  line-height: 1.5;
  color: #495057;
  font-size: 1.6rem;
  border: 0.1rem solid ${props => props.isValid ? "#dc3545" : "#ced4da"};
  background-color: #fff;
  background-clip: padding-box;
  border-radius: .25rem;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: ${props => props.isValid ? "#dc3545" : "#80bdff"};
    outline: 0;
    box-shadow: 0 0 0 0.2rem ${props => props.isValid ? "rgba(220,53,69,.25)" : "rgba(0, 123, 255, .25)"};
  }

  &[readonly],
  &:disabled {
    background-color: #e9ecef;
  }
`

Textarea.defaultProps = {
    theme: theme
}


export default Textarea