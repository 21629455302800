import {createSelector} from 'reselect'
import _ from 'lodash'
import moment from "moment-timezone"
import Timezone from "../../../constants/timezone"


const datesCalc = docs => _.chain(docs)
    .map(i => i.date_formatted)
    .uniq()
    .value()


const patientsCalc = docs => _.chain(docs)
    .groupBy(i => i.date_formatted)
    .map((value, date) => _.chain(value)
        .filter(i => i.role === "patient")
        .values()
        .map(j => j.rating)
        .mean()
        .round(1)
        .value()
    )
    .value()

const dataCalc = docs => [
    [
        "date",
        "patient_service_provider",
        "program",
        "rating",
        "did_you_feel_prepared_for_the_call",
        "did_the_nurse_call_you_within_the_allocated_time",
        "do_you_feel_you_received_the_support_you_required",
        "are_you_satisfied_with_the_service_provided_today"
    ],
    ...docs.map(i => (
        [
            i.date_table ?? "",
            i.rating ?? "",
            i.patient_service_provider.title.trim(),
            i.program.title.trim(),
            i.extra_questions.did_you_feel_prepared_for_the_call,
            i.extra_questions.did_the_nurse_call_you_within_the_allocated_time,
            i.extra_questions.do_you_feel_you_received_the_support_you_required,
            i.extra_questions.are_you_satisfied_with_the_service_provided_today
        ]
    ))]

export const millisToMomentSelector = millis => moment.tz(millis, Timezone)
const getStartDateUnix = state => state.feedback.start_date
const getEndDateUnix = state => state.feedback.end_date

export const getPatientServiceProviders = state => state.patient_service_providers.docs
export const getPrograms = state => state.programs.docs

export const getAllPrograms = createSelector(
    getPrograms,
    (programs) => {
        const options = programs
            .map(i => ({label: i.title, value: i.path})) ?? []

        return [{label: 'All programs', value: 'all'}, ...options]
    }
)

const getOptions = createSelector(
    getPatientServiceProviders,
    getPrograms,
    (patientServiceProviders, programs) => {
        return patientServiceProviders.map(i => {
            const programOptions = programs
                .filter(j => j.patient_service_provider_id === i.id)
                .map(j => ({label: j.title, value: j.path})) ?? []

            return {
                label: i.title,
                options: programOptions
            }
        })
    }
)

export const getAllOptions = createSelector(
    getOptions,
    (options) => {
        return [{label: 'All programs', value: 'all'}, ...options]
    }
)

export const getDocs = state => state.feedback.docs
export const getDates = createSelector(getDocs, datesCalc)
export const getPatients = createSelector(getDocs, patientsCalc)
export const getData = createSelector(getDocs, dataCalc)
export const getStartDate = createSelector(getStartDateUnix, millisToMomentSelector)
export const getEndDate = createSelector(getEndDateUnix, millisToMomentSelector)


