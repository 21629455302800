import React, {useEffect, useState} from 'react'
import AsyncSelect from 'react-select/async'
import Select from "react-select"
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import moment from "moment"
import SlideDrawerWrapper from '../Styled/SlideDrawerWrapper'
import DrawerContent from '../Styled/DrawerContent'
import DrawerHandel from '../Styled/DrawerHandel'
import Backdrop from '../Styled/Backdrop'
import H2Wrapper from '../Styled/H2Wrapper'
import FormField from '../Styled/FormField'
import AddAppointmentButton from '../Styled/AddAppointmentButton'
import FormFieldWrapper from '../Styled/FormFieldWrapper'
import {db} from '../../../actions'
import {collection, getDocs, limit, orderBy, query, Timestamp, where} from "firebase/firestore"
import COLLECTION from "../../../constants/collections"
import Label from "../Styled/Label"
import colourStyles from "../../../constants/colorStyles"
import {Tooltip} from "react-tooltip"
import 'react-tooltip/dist/react-tooltip.css'


const SlideDrawer = ({
                         closeDrawer = false,
                         setAppointment,
                         options = []
                     }) => {

    const [showValue, setShowValue] = useState(false)
    const [nurseValue, setNurseValue] = useState(null)
    const [programPath, setProgramPath] = useState('all')
    const [patientValue, setPatientValue] = useState(null)
    const [dateValue, setDateValue] = useState("")


    useEffect(() => {
        setTimeout(() => setShowValue(true), 200)
    }, [closeDrawer])


    const loadNurses = async nurse_id => {
        const collectionRef = collection(db, COLLECTION.NURSES)

        const q = query(
            collectionRef,
            where("keywords", "array-contains", nurse_id.toLowerCase().replace(/\s/g, '')),
            orderBy("registered"),
            limit(10)
        )

        const querySnapshot = await getDocs(q)

        return querySnapshot.empty ? [] : querySnapshot.docs.map(i => ({
            id: i.id,
            label: i.data().nurse_id,
            value: i.data().nurse_id,
            path: i.ref.path, ...i.data()
        }))
    }


    const loadPatients = async patient_id => {
        const collectionRef = collection(db, COLLECTION.PATIENTS)

        const q = query(
            collectionRef,
            where("keywords", "array-contains", patient_id.toLowerCase().replace(/\s/g, '')),
            orderBy("registered"),
            limit(10)
        )

        const querySnapshot = await getDocs(q)

        return querySnapshot.empty ?
            [] :
            querySnapshot.docs
                .map(i => ({
                    id: i.id,
                    label: i.data().patient_id,
                    value: i.data().patient_id,
                    path: i.ref.path, ...i.data()
                }))
                .map(i => {
                    i.program_paths = i.program_refs.map(i => i.path)
                    delete i.program_refs
                    i.patient_service_provider_path = i.patient_service_provider_ref.path
                    delete i.patient_service_provider_ref

                    return i
                })
                .filter(i => i.program_paths.includes(programPath))
    }


    const close = () => {
        setShowValue(false)
        setTimeout(() => closeDrawer(), 200)
    }


    const addAppointment = async () => {
        const millis = dateValue.valueOf()
        const timestamp = Timestamp.fromMillis(millis)
        if (programPath !== 'all' && !!nurseValue && !!patientValue && !!timestamp) {
            await setAppointment(programPath, nurseValue, patientValue, timestamp, true)
            close()
        }
    }


    return (
        <>
            <SlideDrawerWrapper show={showValue} style={{fontSize: "16px"}}>
                <DrawerContent>

                    <H2Wrapper>Add Appointment</H2Wrapper>

                    <FormField>
                        <Label
                            id="add-appointment-program"
                            data-tooltip-id="side-drawer-tooltip"
                            data-tooltip-content={`The Program for the item`}
                        >Program</Label>

                        <Select
                            id="nurse_id"
                            key="nurse_id"
                            name="nurse_id"
                            options={options}
                            className="react-select-field"
                            placeholder="Please select a program..."
                            onChange={selected => setProgramPath(selected.value)}
                            styles={colourStyles}
                        />
                    </FormField>

                    <FormField>
                        <Label
                            id="add-appointment-nurse-id"
                            data-tooltip-id="side-drawer-tooltip"
                            data-tooltip-content={`The Nurse ID`}
                        >Nurse ID</Label>
                        <AsyncSelect
                            id="nurse_id"
                            key="nurse_id"
                            name="nurse_id"
                            isDisabled={programPath === "all"}
                            placeholder="Please begin typing for suggestions below"
                            loadOptions={loadNurses}
                            defaultOptions
                            onChange={selected => setNurseValue(selected)}
                            styles={colourStyles}
                        />
                    </FormField>

                    <FormField>
                        <Label
                            id="add-appointment-patient-id"
                            data-tooltip-id="side-drawer-tooltip"
                            data-tooltip-content={`The Patient ID`}
                        >Patient ID</Label>
                        <AsyncSelect
                            id="patient_id"
                            key="patient_id"
                            name="patient_id"
                            isDisabled={programPath === "all"}
                            placeholder="Please begin typing for suggestions below"
                            loadOptions={loadPatients}
                            defaultOptions
                            onChange={selected => setPatientValue(selected)}
                            styles={colourStyles}
                        />
                    </FormField>

                    <FormFieldWrapper>
                        <Label
                            id="add-appointment-date"
                            data-tooltip-id="side-drawer-tooltip"
                            data-tooltip-content={`Appointment Date`}
                        >Appointment Date</Label>
                        <Datetime
                            dateFormat="D/M/YYYY"
                            inputProps={{
                                disabled: !(programPath !== 'all' && !!nurseValue && !!patientValue)
                            }}
                            timeFormat={false}
                            value={moment(dateValue)}
                            onChange={v => setDateValue(v)}
                            closeOnSelect={true}
                            isValidDate={(currentDate, selectedDate) => {
                                if (!selectedDate) {
                                    return currentDate.isAfter(moment().subtract(1, 'd'))
                                } else {
                                    return selectedDate.isAfter(moment().subtract(1, 'd'))
                                        && currentDate.isAfter(moment().subtract(1, 'd'))
                                }
                            }}
                        />
                    </FormFieldWrapper>

                    <AddAppointmentButton
                        disabled={!(programPath !== 'all' && !!nurseValue && !!patientValue && !!dateValue?.valueOf() && moment(dateValue?.valueOf()).isSameOrAfter(new Date(), "day"))}
                        onClick={() => addAppointment()}
                    >ADD APPOINTMENT</AddAppointmentButton>
                    <Tooltip
                        id="side-drawer-tooltip"
                        float={true}
                    />
                </DrawerContent>
                <DrawerHandel onClick={() => close()}/>
            </SlideDrawerWrapper>
            <Backdrop/>
        </>
    )
}


export default SlideDrawer