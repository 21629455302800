import styled from 'styled-components'
import theme from "./theme"

const MessagesWrapper = styled.ul`
	margin: 0;
	padding: 0;
`

MessagesWrapper.defaultProps = {
    theme: theme
}

export default MessagesWrapper