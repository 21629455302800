import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Timestamp} from "firebase/firestore"
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/Nurses/Add.scss'
import getKeywords from '../../../util/getKeywords'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import ResetButton from "../Styled/ResetButton"
import {useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import OutlineLargeDangerButton from "../Styled/OutlineLargeDangerButton"
import PrimaryButton from "../Styled/PrimaryButton"
import VersionNumber from "../Styled/VersionNumber"
import LegalInfoFooter from "../LegalInfoFooter"
import {logEvent} from "firebase/analytics"


const Add = ({addItem, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Add Nurse"
    })

    const navigate = useNavigate()

    const {register, handleSubmit, setValue, watch, formState: {errors}, reset} = useForm({
        mode: 'onSubmit',
        defaultValues: {
            nurse_id: "",
            password: "",
            first_name: "",
            last_name_initial: ""
        }
    })


    const onSubmit = data => {
        data.is_auth = false
        data.registered = Timestamp.now()
        data.online = {
            status: false,
            is_logged_in: false,
            last_updated: Timestamp.now()
        }
        data.keywords = getKeywords(nurse_id, first_name, last_name_initial)

        addItem(data)
        navigate('/nurses')
    }

    const [nurse_id, password, first_name, last_name_initial] = watch(["nurse_id", "password", "first_name", "last_name_initial"])

    return (
        <main className="app add-nurse">
            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Add Nurse"/>

                <section className="content">

                    <h3>New Nurse</h3>
                    <Link
                        id="add-nurse-close-btn"
                        to="/nurses" className="close"
                        data-tooltip-id="add-nurse-tooltip"
                        data-tooltip-content={`Click to close`}
                    ><img src={close} alt="close icon"/></Link>

                    <form
                        className="add-nurse-form"
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                        }}
                    >

                        <div className="nurse_id">
                            <Label
                                id="add-nurse-id"
                                data-tooltip-id="add-nurse-tooltip"
                                data-tooltip-content={`Nurse's ID`}>
                                Nurse ID
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.nurse_id}
                                    type="text"
                                    placeholder="Please enter a nurse ID..."
                                    {...register(
                                        'nurse_id',
                                        {
                                            required: true,
                                            validate: {
                                                lowerCase: value => value.toLowerCase() === value,
                                                noSpaces: value => value.replace(" ", "") === value,
                                                longerThanThreeLetters: value => value.length > 2
                                            }
                                        })
                                    }
                                />
                                {nurse_id.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('nurse_id', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.nurse_id && errors.nurse_id.type === 'required' &&
                                <InvalidFeedback>The `Nurse ID` field is required</InvalidFeedback>
                            }
                            {errors.nurse_id && errors.nurse_id.type === 'lowerCase' &&
                                <InvalidFeedback>The `Nurse ID` needs to be in lowercase</InvalidFeedback>
                            }
                            {errors.nurse_id && errors.nurse_id.type === 'noSpaces' &&
                                <InvalidFeedback>The `Nurse ID` cannot have spaces</InvalidFeedback>
                            }
                            {errors.nurse_id && errors.nurse_id.type === 'longerThanThreeLetters' &&
                                <InvalidFeedback>The `Nurse ID` must be at least 3 characters</InvalidFeedback>
                            }
                        </div>

                        <div className="password">
                            <Label
                                id="add-nurse-password"
                                data-tooltip-id="add-nurse-tooltip"
                                data-tooltip-content={`Nurse's password`}>
                                Password
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    autoComplete="off"
                                    type="text"
                                    isValid={!!errors.password}
                                    placeholder="Please enter a password..."
                                    {...register(
                                        'password',
                                        {
                                            required: true,
                                            validate: {
                                                lowerCase: value => value.toLowerCase() === value,
                                                noSpaces: value => value.replace(" ", "") === value,
                                                longerThanThreeLetters: value => value.length > 2
                                            }
                                        })
                                    }
                                />
                                {password.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('password', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.password && errors.password.type === 'required' &&
                                <InvalidFeedback>The `Password` field is required</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'lowerCase' &&
                                <InvalidFeedback>The password needs to be in lowercase</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'noSpaces' &&
                                <InvalidFeedback>The password cannot have spaces</InvalidFeedback>
                            }
                            {errors.password && errors.password.type === 'longerThanThreeLetters' &&
                                <InvalidFeedback>The password must be at least 3 characters</InvalidFeedback>
                            }
                        </div>

                        <div className="first_name">
                            <Label
                                id="add-nurse-first-name"
                                data-tooltip-id="add-nurse-tooltip"
                                data-tooltip-content={`Nurse's first name`}
                            >First Name</Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.first_name}
                                    type="text"
                                    placeholder="Please enter a first name..."
                                    {...register(
                                        'first_name',
                                        {required: true}
                                    )
                                    }
                                />
                                {first_name.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('first_name', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.first_name && <InvalidFeedback>The `First Name` field is required</InvalidFeedback>}
                        </div>

                        <div className="last_name_initial">
                            <Label
                                id="add-nurse-last-name-initial"
                                data-tooltip-id="add-nurse-tooltip"
                                data-tooltip-content={`Nurse's last name initial`}
                            >Last Name Initial</Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.last_name_initial}
                                    type="text"
                                    placeholder="Please enter a last name initial..."
                                    {...register(
                                        'last_name_initial',
                                        {
                                            required: true,
                                            validate: {
                                                noSpaces: value => value.replace(" ", "") === value,
                                                onlyOneLetter: value => value.length === 1
                                            }
                                        })
                                    }
                                />
                                {last_name_initial.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('last_name_initial', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.last_name_initial && errors.last_name_initial.type === 'required' &&
                                <InvalidFeedback>The `Last Name Initial` field is required</InvalidFeedback>
                            }
                            {errors.last_name_initial && errors.last_name_initial.type === 'noSpaces' &&
                                <InvalidFeedback>The `Last Name Initial` field cannot have spaces</InvalidFeedback>
                            }
                            {errors.last_name_initial && errors.last_name_initial.type === 'onlyOneLetter' &&
                                <InvalidFeedback>The `Last Name Initial` field must be 1 character in
                                    length</InvalidFeedback>
                            }
                        </div>

                        <OutlineLargeDangerButton
                            className="reset"
                            type="button"
                            onClick={() => reset()}>
                            Reset Form
                        </OutlineLargeDangerButton>

                        <PrimaryButton
                            id="add-nurse-add-record-btn"
                            data-tooltip-id="add-nurse-tooltip"
                            data-tooltip-content={`Click to add record`}
                            className="add btn"
                            type="submit">
                            ADD NURSE
                        </PrimaryButton>
                        <Tooltip
                            id="add-nurse-tooltip"
                            float={true}
                        />
                    </form>

                </section>

            </article>

            <VersionNumber/>
            <LegalInfoFooter/>
        </main>
    )
}


export default Add