import React, {useEffect} from 'react'
import logo from '../../assets/svg/logo-with-phoenix.svg'
import '../../assets/scss/CookiePolicy.scss'
import GoBack from "./GoBack"
import {logEvent} from "firebase/analytics"
import {useLocation} from "react-router-dom"


const CookiePolicy = ({analytics}) => {

    const {pathname} = useLocation()

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Cookie Policy"
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <main id="cookie-policy">
            <article className="content">

                <GoBack/>

                <img src={logo} className="logo" alt="TCP Homecare"/>

                <h1 className="text-center display-4">Cookie Policy</h1>

                <h2>What are cookies?</h2>

                <p>Also known as browser cookies or tracking cookies, cookies are small text files of letters and
                    numbers that we
                    put on your computer if you agree. These cookies allow us to distinguish you from other users of our
                    website,
                    which helps us to provide you with a good experience when you browse our website and also allows us
                    to improve
                    our site.</p>

                <p>Cookies are created when a user’s browser loads a particular website. The website sends information
                    to the
                    browser which then creates a text file. Every time the user goes back to the same website, the
                    browser retrieves
                    and sends this file to the website’s server. Computer cookies are created not just by the website
                    the user is
                    browsing but also by other websites that run advertisements, widgets, or other elements on the page
                    being
                    loaded. These cookies regulate how the advertisements appear or how the widgets and other elements
                    function on
                    the page.</p>

                <h2>Who drops cookies when you use our website?</h2>

                <p>Many of the cookies dropped when you use our services are “first party cookies” (meaning cookies
                    placed and
                    read by us directly).</p>

                <p>A number of our partners also drop cookies when you use our services. These specialists “third party
                    cookies”
                    are not set by us but are rather set by companies such as Google for website analytics purposes with
                    our
                    permission. Please read our partners’ privacy policies to ensure that you are comfortable with the
                    manner in
                    which they use cookies:</p>

                <p><a href="https://www.google.com/analytics/learn/privacy.html" target="_blank"
                      rel="noreferrer">www.google.com/analytics/learn/privacy.html</a></p>

                <h2>How do you opt-out?</h2>

                <p>If you do not consent to our use of cookies, you can set your browser settings to reject cookies.
                    These
                    settings are usually found in the “options” or “preferences” menu of your web browser.
                    Alternatively, you can
                    use the “Help” option in your web browser to get more details.</p>

                <p>A number of features of our services may not work or will be curtailed from working effectively
                    without
                    cookies. As a result, changing your browser settings to reject cookies may have a negative impact on
                    your
                    ability to use our website.</p>

                <p>If you use our website with your web browser set to accept cookies, you consent to our use of cookies
                    as
                    described in this cookies policy. If you do not accept our use of cookies, you should either (a)
                    immediately
                    stop using our services or (b) use the settings described above to reject cookies. You can find out
                    more about
                    deleting and blocking cookies here and using your web browser’s “Help” option.</p>

                <h2>Contacting us</h2>

                <p>If you have any questions about this Policy, our practices relating to the website or your dealings
                    with the website you can contact: <a href="tel:+35314291828">+353 1 429 1828</a></p>

            </article>
        </main>
    )

}

export default CookiePolicy