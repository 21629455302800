import React from 'react'
import {Link} from 'react-router-dom'
import '../../assets/scss/QuickSearchWithLink.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import ResetButton from "./Styled/ResetButton"


const QuickSearchWithLink = ({
                                 query,
                                 setQuery,
                                 entries,
                                 linkTo,
                                 linkTitle
                             }) => {

    return (
        <>
            <section className="quick-search">
                <form onSubmit={e => e.preventDefault()}>
                    <div className="quick-search-container">
                        <input
                            type="text"
                            placeholder="Quick search..."
                            value={query}
                            onChange={e => {
                                setQuery(e.target.value)
                            }}
                        />
                        {query.length > 0 &&
                            <ResetButton onClick={() => setQuery("")} type="reset"/>
                        }
                    </div>

                    {entries > 0 &&
                        <p>{entries} entries</p>
                    }
                </form>

                <Link
                    to={linkTo}
                    id="add-record-link-element"
                    data-cy="link-add"
                    className="btn"
                    data-tooltip-id="quick-search-with-link-tooltio"
                    data-tooltip-content="Click to add record">
                    {linkTitle}
                </Link>

            </section>
            <Tooltip
                id="quick-search-with-link-tooltio"
                float={true}
            />
        </>
    )
}

export default QuickSearchWithLink