import styled from "styled-components"
import theme from "./theme"


const EventWrapperWrapper = styled.span`
  display: block;
  position: relative;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`

EventWrapperWrapper.defaultProps = {
    theme: theme
}

export default EventWrapperWrapper