import React from 'react'
import '../../../assets/scss/ProgramInformation/Form.scss'
import Select from 'react-select'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import getKeywords from "../../../util/getKeywords"
import isUrlValid from '../../../util/isUrlValid'
import isPhoneValid from '../../../util/isPhoneValid'
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import OutlineLargeDangerButton from "../Styled/OutlineLargeDangerButton"
import PrimaryButton from "../Styled/PrimaryButton"
import _ from "lodash"
import colourStyles from "../../../constants/colorStyles"
import InputNumber from "rc-input-number"
import 'rc-input-number/assets/index.css'


const Form = props => {

    const {register, handleSubmit, setValue, watch, formState: {errors}, control} = useForm({mode: 'onSubmit'})

    const onSubmit = data => {
        data.path = props.path
        data.index = _.toNumber(data.index)
        data.group = 'patient'
        data.keywords = getKeywords(data.title, data.url)

        props.handleUpdate(data)
    }

    const title = watch("title", props.title)
    const url = watch("url", props.url)

    return (
        <form
            className="edit-program-information-form"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault()
            }}
        >

            <div className="program_path">
                <Label
                    id={`program-info-select-${props.id}`}
                    data-tooltip-id={`update-program-information-tooltip-${props.id}`}
                    data-tooltip-content={`Program for the item`}
                >Program</Label>
                <Controller
                    render={({field}) =>
                        <Select
                            {...field}
                            options={props.options}
                            className="react-select-field"
                            placeholder="Please select a program..."
                            styles={colourStyles}
                        />
                    }
                    defaultValue={props.allOptions.find(program => program.value === props.program_path)}
                    control={control}
                    rules={{required: true}}
                    name="program_path"
                />
                {errors.program_path && <InvalidFeedback>The `Program` field is required</InvalidFeedback>}
            </div>

            <div className="title">
                <Label
                    id={`program-info-title-${props.id}`}
                    data-tooltip-id={`update-program-information-tooltip-${props.id}`}
                    data-tooltip-content={`Title for the item`}
                >Title</Label>
                <div style={{position: "relative"}}>
                    <InputField
                        name="title"
                        isValid={!!errors.title}
                        type="text"
                        placeholder="Please enter a title..."
                        defaultValue={props.title}
                        {...register(
                            'title',
                            {
                                required: true
                            })
                        }
                    />
                    {title.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('title', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.title && <InvalidFeedback>The `Title` field is required</InvalidFeedback>}
            </div>

            <div className="url">
                <Label
                    id={`program-info-url-${props.id}`}
                    data-tooltip-id={`update-program-information-tooltip-${props.id}`}
                    data-tooltip-content={`URL for the item`}
                >URL</Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.url}
                        type="text"
                        placeholder="Please enter a url or telephone..."
                        defaultValue={props.url}
                        {...register(
                            'url',
                            {
                                required: true,
                                validate: {
                                    isValidUrlOrPhone: value => isUrlValid(value) || isPhoneValid(value)
                                }
                            })
                        }
                    />
                    {url.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('url', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.url && errors.url.type === "required" &&
                    <InvalidFeedback>The `URL` field is required</InvalidFeedback>
                }
                {errors.url && errors.url.type === 'isValidUrlOrPhone' &&
                    <InvalidFeedback>Please enter a valid url</InvalidFeedback>
                }

            </div>

            <div className="index">
                <Label
                    id={`program-info-order-${props.id}`}
                    data-tooltip-id={`update-program-information-tooltip-${props.id}`}
                    data-tooltip-content={`Rank order for the item`}
                >Order</Label>
                <Controller
                    render={({field}) =>
                        <InputNumber
                            {...field}
                            min={1}
                        />
                    }
                    rules={{required: true}}
                    defaultValue={parseInt(props.index)}
                    control={control}
                    name="index"
                />
            </div>

            <OutlineLargeDangerButton
                disabled={props.fetching}
                className="trash"
                type="button"
                onClick={e => {
                    e.preventDefault()
                    props.handleDelete(props.id)
                }}>
                Delete Info
            </OutlineLargeDangerButton>

            <PrimaryButton
                id={`program-update-record-btn-${props.id}`}
                disabled={props.fetching}
                className="update btn"
                data-tooltip-id={`update-program-information-tooltip-${props.id}`}
                data-tooltip-content={`Click to update record`}
                type="submit">
                UPDATE
            </PrimaryButton>

            <Tooltip
                id={`update-program-information-tooltip-${props.id}`}
                float={true}
            />

        </form>
    )
}


export default Form