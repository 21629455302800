import styled from 'styled-components'
import theme from "./theme"


const InvalidFeedback = styled.div`
  display: block;
  width: 100%;
  margin-top: 0.4rem;
  font-size: 1.4rem;
  color: ${props => props.theme.brandDanger};
`

InvalidFeedback.defaultProps = {
    theme: theme
}


export default InvalidFeedback