import {combineReducers} from 'redux'
import auth from './auth'
import appointments from './appointments'
import errors from './errors'
import help from './help'
import program_information from './program_information'
import patient_service_providers from './patient_service_providers'
import messages from './messages'
import admins from './admins'
import nurses from './nurses'
import patients from './patients'
import feedback from './analytics'
import programs from './programs'
import notifications from './notifications'
import data from './data'


export default combineReducers({
    errors,
    auth,
    data,
    help,
    appointments,
    program_information,
    feedback,
    nurses,
    programs,
    notifications,
    patients,
    patient_service_providers,
    messages,
    admins
})