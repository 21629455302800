import React, {useEffect, useState} from 'react'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import AddRecordIcon from './AddRecordIcon'
import RemoveRecordIcon from './RemoveRecordIcon'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import '../../../assets/scss/Data/Data.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import PrimaryButton from "../Styled/PrimaryButton"
import VersionNumber from "../Styled/VersionNumber"
import LegalInfoFooter from "../LegalInfoFooter"
import {logEvent} from "firebase/analytics"


const Data = ({fetching, data_processing_stage, setDataProcessingStage, uploadDataDocs, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Data"
    })

    const [filePath, setFilePath] = useState("No file selected")
    const [selectedFile, setSelectedFile] = useState("")
    const [addRecords, setAddRecords] = useState("1")

    useEffect(() => {
        if (data_processing_stage === "COMPLETED") {
            setDataProcessingStage("INACTIVE")
            setSelectedFile("")
            setFilePath("No file selected")
        }
    }, [data_processing_stage])

    const extractFilename = path => {
        if (path.substring(0, 12) === "C:\\fakepath\\")
            return path.substring(12)
        let x
        x = path.lastIndexOf('/')
        if (x >= 0)
            return path.substring(x + 1)
        x = path.lastIndexOf('\\')
        if (x >= 0)
            return path.substring(x + 1)
        return path
    }


    const handleSubmit = e => {
        e.preventDefault()

        if (selectedFile !== "") {
            uploadDataDocs(selectedFile, addRecords === "1")
        }
    }

    return (
        <main className="app data">
            {fetching &&
                <ProgressBarIndeterminate/>
            }
            <ShowErrors/>
            <Header/>
            <article className="inner-content">

                <EntryHeader title="Patient/Nurse Data Bulk Upload"/>

                <form
                    onSubmit={handleSubmit}>
                    <p>{filePath}</p>


                    <div className="btn-group">
                        <input
                            name="add_records"
                            type="radio"
                            value="1"
                            id="addRecords"
                            onChange={() => setAddRecords("1")}
                            checked={addRecords === "1"}
                        />
                        <label
                            id="data-add-records-label"
                            data-cy="add-records-label"
                            data-tooltip-id="data-tooltip"
                            data-tooltip-content="Select to add nurse or patient records"
                            htmlFor="addRecords"><AddRecordIcon/>Add</label>
                        <input
                            name="add_records"
                            type="radio"
                            value="2"
                            id="removeRecords"
                            onChange={() => setAddRecords("2")}
                            checked={addRecords === "2"}
                        />
                        <label
                            id="data-remove-records"
                            data-cy="remove-records-label"
                            data-tooltip-id="data-tooltip"
                            data-tooltip-content="Select to remove nurse or patient records"
                            htmlFor="removeRecords"><RemoveRecordIcon/>Remove</label>
                    </div>

                    <input
                        type="file"
                        id="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={e => {
                            const filePath = extractFilename(e.target.value)

                            if (filePath.endsWith(".xlsx")) {
                                setSelectedFile(e.target.files[0])
                                setFilePath(filePath)
                            }
                        }}/>

                    <label
                        id="data-select-file-label"
                        data-cy="select-file-label"
                        data-tooltip-id="data-tooltip"
                        data-tooltip-content="Click to select a excel file to upload"
                        htmlFor="file"
                        className="btn">
                        SELECT FILE FOR UPLOAD
                    </label>

                    <PrimaryButton
                        id="data-upload-button"
                        data-cy="upload-button"
                        data-tooltip-id="data-tooltip"
                        data-tooltip-content="Click to begin processing data"
                        type="submit"
                        className={(fetching ? 'sync btn' : 'btn')}
                        disabled={selectedFile.length === 0}
                    >UPLOAD
                    </PrimaryButton>
                    <Tooltip
                        id="data-tooltip"
                        float={true}
                    />

                </form>

            </article>

            <VersionNumber/>
            <LegalInfoFooter/>
        </main>
    )

}

export default Data