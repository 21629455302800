import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {collection, onSnapshot, orderBy, query} from "firebase/firestore"
import _ from 'lodash'
import {batch} from "react-redux"
import {addError} from "../error"


const addItem = item =>
    ({
        type: C.NURSES_NEW_DOC,
        payload: item
    })

const updateItem = item =>
    ({
        type: C.NURSES_UPDATE_DOC,
        payload: item
    })

const deleteItem = item =>
    ({
        type: C.NURSES_DELETE_DOC,
        payload: item
    })


export const nursesDocListener = () => async (dispatch, getState) => {

    if (_.isFunction(window.nursesDocListener)) {
        window.nursesDocListener()
        delete window.nursesDocListener
    }

    const nurseCollRef = collection(db, COLLECTION.NURSES)

    const q = query(nurseCollRef, orderBy("registered"))

    window.nursesDocListener = onSnapshot(q, querySnapshot => {
        querySnapshot.docChanges().forEach(change => {

            const item = {
                id: change.doc.id,
                path: change.doc.ref.path,
                ...change.doc.data()
            }

            item.registered = item.registered.toMillis()

            item.online.last_updated = item.online.last_updated.toMillis()

            batch(() => {
                switch (change.type) {
                    case 'added':
                        dispatch(
                            addItem(item)
                        )
                        break
                    case 'modified':
                        dispatch(
                            updateItem(item)
                        )
                        break
                    case 'removed':
                        dispatch(
                            deleteItem(item)
                        )
                        break
                    default:
                        break
                }
            })

        })
    }, error => {
        dispatch(
            addError(error.message)
        )
    })

}

