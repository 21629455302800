import {createRoot} from "react-dom/client"
import routes from './routes'
import {configureStore} from './store'
import {Provider} from 'react-redux'
import * as serviceWorker from './serviceWorker'

const store = configureStore()
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <Provider store={store}>
        {routes}
    </Provider>
)


serviceWorker.unregister()