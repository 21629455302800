import styled from 'styled-components'
import theme from "./theme"


const LoginButton = styled.button`
  position: relative;
  width: 100%;
  height: 3.8rem;
  background-color: ${props => props.theme.brandPrimary};
  color: white;
  padding: 0.6rem 1.2rem;
  border: none;
  font-size: 1.6rem;
  line-height: 1.5;
  border-radius: .25rem;
  margin-bottom: 1.7rem;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.3s ease-in;
  cursor: pointer;

  &:disabled {
    background-color: ${props => props.theme.grayLighter};
    cursor: not-allowed;
  }

  &:hover:enabled {
    background-color: ${props => props.theme.brandPrimaryDark};
  }
`

LoginButton.defaultProps = {
    theme: theme
}

export default LoginButton