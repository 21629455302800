const theme = {
    grayBase: "#000",
    grayDarker: "#222",
    grayDark: "#333",
    gray: "#555",
    grayLight: "#777",
    grayLighter: "#eee",
    brandPrimary: "#1A428A",
    brandPrimaryDark: "#122d5f",
    brandSecondary: "#96DAEA",
    brandSuccess: "#5cb85c",
    brandSuccessDark: "#439F43",
    brandInfo: "#5bc0de",
    brandWarning: "#f0ad4e",
    brandDanger: "#d9534f",
    brandDangerDisabled: "#dc3545",
    fontFamily: `"Open Sans", Helvetica, Arial, sans-serif`
}


export default theme