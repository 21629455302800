import styled from 'styled-components'
import theme from "./theme"


const PrimaryButton = styled.button`
  background-color: ${props => props.theme.brandPrimary};
  color: white;
  font-size: 2rem;
  font-family: 'Open Sans', sans-serif;
  padding: 1rem 1.5em;
  border-radius: 0.5rem;
  border: none;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease-in;

  &:hover {
    background-color: ${props => props.theme.brandPrimaryDark};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

PrimaryButton.defaultProps = {
    theme: theme
}


export default PrimaryButton