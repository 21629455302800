import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {collection, onSnapshot} from "firebase/firestore"
import _ from "lodash"
import {batch} from "react-redux"
import {addError} from "../error"


const addItem = item =>
    ({
        type: ACTION.ADMINS_NEW_DOC,
        payload: item
    })

const updateItem = item =>
    ({
        type: ACTION.ADMINS_UPDATE_DOC,
        payload: item
    })

const deleteItem = item =>
    ({
        type: ACTION.ADMINS_DELETE_DOC,
        payload: item
    })


export const adminsDocListener = () => async (dispatch, getState) => {

    if (_.isFunction(window.adminsDocListener)) {
        window.adminsDocListener()
        delete window.adminsDocListener
    }

    const adminCollRef = collection(db, COLLECTION.ADMINS)

    window.adminsDocListener = onSnapshot(adminCollRef, querySnapshot => {
        querySnapshot.docChanges().forEach(change => {

            const item = {
                id: change.doc.id,
                path: change.doc.ref.path,
                ...change.doc.data()
            }

            if (_.has(item, "registered")) {
                item.registered = item.registered.toMillis()
            }

            batch(() => {
                switch (change.type) {
                    case 'added':
                        dispatch(
                            addItem(item)
                        )
                        break
                    case 'modified':
                        dispatch(
                            updateItem(item)
                        )
                        break
                    case 'removed':
                        dispatch(
                            deleteItem(item)
                        )
                        break
                    default:
                        break
                }
            })

        })
    }, error => {
        dispatch(
            addError(error.message)
        )
    })

}