const COLLECTION = {
    ADMINS: 'admins',
    TWILIO_ROOMS: 'twilio_rooms',
    PATIENT_SERVICE_PROVIDERS: 'patient_service_providers',
    PROGRAMS: 'programs',
    NOTIFICATIONS: 'notifications',
    NURSES: 'nurses',
    PATIENTS: 'patients',
    APPOINTMENTS: 'appointments',
    COUNTERS: 'counters',
    FEEDBACK: 'feedback',
    CHAT_CHANNELS: 'chat_channels',
    CHAT_THREAD: 'chat_thread',
    HELP: 'help',
    PROGRAM_INFORMATION: 'program_information'
}


export default COLLECTION