import styled from 'styled-components'
import theme from "./theme"

const Label = styled.label`
  font-size: 1.4rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: #14171A;
  margin-bottom: 0.4rem;
  display: block;
`

Label.defaultProps = {
    theme: theme
}

export default Label