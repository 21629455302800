import C from '../../../constants/actionType'
import {combineReducers} from 'redux'

const fetching = (state = false, action) =>
    (action.type === C.DATA_FETCHING_DOCS) ?
        action.payload :
        state

const data_processing_stage = (state = "INACTIVE", action) =>
    (action.type === C.DATA_PROCESSING_STAGE) ?
        action.payload :
        state

export default combineReducers({
    fetching,
    data_processing_stage
})