import Appointments from '../../ui/Appointments/Appointments'
import {connect} from 'react-redux'
import {
    addDate,
    addProgramPath,
    addQuery,
    appointmentListener,
    cancelAppointment,
    rescheduleAppointment,
    setAppointment
} from '../../../actions/appointments/index'
import {getAllPrograms, getOptions, getOptionsWithAll} from "./selectors"
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        options: getOptions(state),
        optionsWithAll: getOptionsWithAll(state),
        allPrograms: getAllPrograms(state),
        date: state.appointments.date,
        queryString: state.appointments.queryString,
        docs: state.appointments.docs,
        fetching: state.appointments.fetching,
        program_path: state.appointments.program_path,
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        appointmentListener(date) {
            dispatch(
                appointmentListener(date)
            )
        },
        cancelAppointment(doc) {
            dispatch(
                cancelAppointment(doc)
            )
        },
        setAppointment(programPath, nurse, patient, timestamp, allDay) {
            dispatch(
                setAppointment(programPath, nurse, patient, timestamp, allDay)
            )
        },
        rescheduleAppointment(appointment, date, isAllDay) {
            dispatch(
                rescheduleAppointment(appointment, date, isAllDay)
            )
        },
        addQuery(query) {
            dispatch(
                addQuery(query)
            )
        },
        addDate(date) {
            dispatch(
                addDate(date)
            )
        },
        addProgramPath(program_path) {
            dispatch(
                addProgramPath(program_path)
            )
        }
    })

export default connect(mapStateToProps, mapDispatchToProps)(Appointments)
