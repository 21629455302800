import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import Role from '../../constants/Role'
import {db} from '../index'
import {collection, doc, getDocs, increment, query, setDoc, Timestamp, where} from "firebase/firestore"
import {addError} from "../error"


const isFetching = fetching =>
    ({
        type: ACTION.ADMINS_FETCHING_DOCS,
        payload: fetching
    })

export const addItem = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {

        const adminsCollRef = collection(db, COLLECTION.ADMINS)

        const q = query(adminsCollRef, where("admin_id", "==", item.admin_id))

        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
            throw new Error(`Admin with id: ${item.admin_id} already exists.  Please use a unique admin id.`)
        }

        const temp = {
            admin_id: item.admin_id,
            password: item.password,
            first_name: item.first_name,
            last_name: item.last_name,
            registered: Timestamp.now(),
            is_auth: item.is_auth,
            keywords: item.keywords,
            admin_group: item.admin_group
        }

        const uid = doc(adminsCollRef).id + Role.ADMIN

        await setDoc(doc(db, COLLECTION.ADMINS, uid), temp)

        const adminsCountersRef = doc(db, COLLECTION.COUNTERS, COLLECTION.ADMINS)

        await setDoc(adminsCountersRef, {total: increment(1)}, {merge: true})

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}