import styled from 'styled-components'
import theme from "./theme"

const Table = styled.table`
  width: 100%;
  margin: 1rem 0;
  background-color: transparent;
  border-collapse: collapse;
  box-sizing: border-box;
  border: 0.1rem solid #dee2e6;

  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;

    th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
      border-right: 0.1rem solid #dee2e6;

      .column-header {
        font-size: 1.8rem;
        font-weight: bold;
        padding: 5px;
        line-height: normal;
        border-top: 0.5rem solid #fff;
        border-bottom: 0.5rem solid #fff;
        user-select: none;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        > span {
          padding-left: 15px;
          flex: 1 1 auto;
          color: ${props => props.theme.brandPrimary};
        }

        > svg {
          flex: 0 0 15px;
          max-height: 15px;
          height: 15px;
          width: auto;
          margin-left: 8px;
        }
      }

      .column-filter {
        padding: 0.75rem;
        background-image: linear-gradient(#dee2e6, #fff);

        input {
          display: block;
          width: 100%;
          height: calc(2.25rem + 2px);
          padding: 1.7rem .75rem;
          line-height: 1.5;
          color: #495057;
          font-size: 1.6rem;
          border: 0.1rem solid ${props => props.isValid ? "#dc3545" : "#ced4da"};
          background-color: #fff;
          background-clip: padding-box;
          border-radius: .25rem;
          box-sizing: border-box;
          font-family: 'Open Sans', sans-serif;
          transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

          &:focus {
            color: #495057;
            background-color: #fff;
            border-color: ${props => props.isValid ? "#dc3545" : "#80bdff"};
            outline: 0;
            box-shadow: 0 0 0 0.2rem ${props => props.isValid ? "rgba(220,53,69,.25)" : "rgba(0, 123, 255, .25)"};
          }

          &:read-only,
          &:disabled {
            background-color: #e9ecef !important;
            cursor: not-allowed !important;
          }
        }
      }
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, .05);
    }
  }

  th {
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  td {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: center;
    font-size: 1.6rem;
  }
`

Table.defaultProps = {
    theme: theme
}

export default Table
