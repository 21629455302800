import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/ProgramInformation/Add.scss'
import getKeywords from "../../../util/getKeywords"
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Select from 'react-select'
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputNumber from "rc-input-number"
import 'rc-input-number/assets/index.css'
import isUrlValid from "../../../util/isUrlValid"
import isPhoneValid from "../../../util/isPhoneValid"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import OutlineLargeDangerButton from "../Styled/OutlineLargeDangerButton"
import PrimaryButton from "../Styled/PrimaryButton"
import _ from "lodash"
import colourStyles from "../../../constants/colorStyles"
import VersionNumber from "../Styled/VersionNumber"
import LegalInfoFooter from "../LegalInfoFooter"
import {logEvent} from "firebase/analytics"


const Add = ({options, addItem, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Add Program Information"
    })

    const navigate = useNavigate()

    const {register, handleSubmit, setValue, watch, formState: {errors}, reset, control} = useForm({
        mode: 'onSubmit',
        defaultValues: {
            program_path: null,
            title: "",
            url: "",
            index: 1
        }
    })

    const onSubmit = data => {
        data.keywords = getKeywords(data.title, data.url)
        data.index = _.toNumber(data.index)
        data.group = "patient"

        addItem(data)
        navigate('/program-information')
    }

    const [title, url] = watch(["title", "url"])


    return (
        <main className="app add-program-information">
            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Add Program Information"/>

                <section className="content">

                    <h3>New Program Information</h3>

                    <Link
                        id="add-program-info-close-btn"
                        to="/program-information"
                        className="close"
                        data-tooltip-id="add-program-information-tooltip"
                        data-tooltip-content={`Click to close`}
                    >
                        <img src={close} alt="close icon"/>
                    </Link>

                    <form
                        className="add-program-information-form"
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                        }}
                    >

                        <div className="program_path">
                            <Label
                                id="add-program-info-program"
                                data-tooltip-id="add-program-information-tooltip"
                                data-tooltip-content={`Program for the item`}
                            >Program</Label>
                            <Controller
                                render={({field}) =>
                                    <Select
                                        {...field}
                                        options={options}
                                        className="react-select-field"
                                        placeholder="Please select a program..."
                                        styles={colourStyles}
                                    />
                                }
                                control={control}
                                rules={{required: true}}
                                name="program_path"
                            />
                            {errors.program_path &&
                                <InvalidFeedback>The `Program` field is required</InvalidFeedback>
                            }
                        </div>

                        <div className="title">
                            <Label
                                id="add-program-info-title"
                                data-tooltip-id="add-program-information-tooltip"
                                data-tooltip-content={`Title for the item`}>Title</Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.title}
                                    type="text"
                                    placeholder="Please enter a title..."
                                    {...register(
                                        'title',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {title.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('title', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.title &&
                                <InvalidFeedback>The `Title` field is required</InvalidFeedback>
                            }
                        </div>

                        <div className="url">
                            <Label
                                id="add-program-info-url"
                                data-tooltip-id="add-program-information-tooltip"
                                data-tooltip-content={`URL for the item`}>URL</Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.url}
                                    type="text"
                                    placeholder="Please enter a url or telephone..."
                                    {...register(
                                        'url',
                                        {
                                            required: true,
                                            validate: {
                                                isValidUrlOrPhone: value => isUrlValid(value) || isPhoneValid(value)
                                            }
                                        })
                                    }
                                />
                                {url.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('url', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.url && errors.url.type === "required" &&
                                <InvalidFeedback>The `URL` field is required</InvalidFeedback>
                            }
                            {errors.url && errors.url.type === 'isValidUrlOrPhone' &&
                                <InvalidFeedback>Please enter a valid url</InvalidFeedback>
                            }

                        </div>

                        <div className="index">
                            <Label
                                id="add-program-info-order"
                                data-tooltip-id="add-program-information-tooltip"
                                data-tooltip-content={`The rank order for the item`}
                            >Order</Label>
                            <Controller
                                render={({field}) =>
                                    <InputNumber
                                        {...field}
                                        min={1}
                                    />
                                }
                                control={control}
                                rules={{required: true}}
                                name="index"
                            />
                        </div>

                        <OutlineLargeDangerButton
                            className="reset"
                            type="button"
                            onClick={() => reset()}>
                            Reset Form
                        </OutlineLargeDangerButton>

                        <PrimaryButton
                            id="add-program-info-add-record-btn"
                            data-tooltip-id="add-program-information-tooltip"
                            data-tooltip-content={`Click to add record`}
                            className="add btn"
                            type="submit">
                            ADD PROGRAM INFORMATION
                        </PrimaryButton>

                        <Tooltip
                            id="add-program-information-tooltip"
                            float={true}
                        />

                    </form>

                </section>
            </article>

            <VersionNumber/>
            <LegalInfoFooter/>
        </main>
    )
}


export default Add