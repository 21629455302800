import C from '../../../constants/actionType'
import {combineReducers} from 'redux'
import _ from 'lodash'


const fetching = (state = false, action) =>
    (action.type === C.PATIENTS_FETCHING_DOCS) ?
        action.payload :
        state

const queryString = (state = "", action) =>
    (action.type === C.PATIENTS_QUERY) ?
        action.payload :
        state

const patient_service_provider_path = (state = "all", action) =>
    (action.type === C.PATIENTS_SERVICE_PROVIDER_FILTER) ?
        action.payload :
        state

const nextAvailable = (state = false, action) =>
    (action.type === C.PATIENTS_PAGINATION_NEXT_AVAILABLE) ?
        action.payload :
        state

const prevAvailable = (state = false, action) =>
    (action.type === C.PATIENTS_PAGINATION_PREVIOUS_AVAILABLE) ?
        action.payload :
        state


const docs = (state = [], action) => {

    let newDoc, docID, docIndex

    switch (action.type) {
        case C.PATIENTS_NEW_DOC :
            newDoc = action.payload
            docID = newDoc.id
            docIndex = _.findIndex(state, doc => doc.id === docID)

            return docIndex < 0 ? _.orderBy([...state, newDoc], ['registered'], ['desc']) : state
        case C.PATIENTS_CLEAR_DOCS :
            return []
        case C.PATIENTS_NEW_DOCS :
            newDoc = action.payload
            return [...newDoc]
        case C.PATIENTS_UPDATE_DOC :
            newDoc = action.payload
            docID = newDoc.id
            docIndex = _.findIndex(state, doc => doc.id === docID)

            return [
                ...state.slice(0, docIndex),
                newDoc,
                ...state.slice(docIndex + 1)
            ]
        case C.PATIENTS_DELETE_DOC :
            newDoc = action.payload
            docID = newDoc.id
            return _.reject(state, doc => doc.id === docID)
        case C.PATIENTS_DELETE_DOCS :
            return []
        default :
            return state
    }
}

const total_number_of_patients = (state = 0, action) =>
    (action.type === C.COUNTER_PATIENT) ?
        action.payload :
        state

export default combineReducers({
    docs,
    fetching,
    queryString,
    patient_service_provider_path,
    nextAvailable,
    prevAvailable,
    total_number_of_patients
})