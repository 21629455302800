import React, {useReducer, useState} from 'react'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import '../../../assets/scss/Messages/index.scss'
import ProgressBarIndeterminate from "../ProgressBar/ProgressBarIndeterminate"
import NoData from "../Styled/NoData"
import _ from "lodash"
import QuickSearch from "./QuickSearch"
import MessagesWrapper from "../Styled/MessagesWrapper"
import MessageWrapper from "../Styled/MessageWrapper"
import DownloadMessagesButton from "./DownloadMessagesButton"
import Tabs from "../Styled/Tabs"
import VersionNumber from "../Styled/VersionNumber"
import LegalInfoFooter from "../LegalInfoFooter"
import {logEvent} from "firebase/analytics"


const Messages = ({setItem, deleteItem, docs, fetching, options, allOptions, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Messages"
    })

    const [query, setQuery] = useState("")
    const [checked, toggle] = useReducer(
        (checked, docID) => {
            const isChecked = _.get(checked, docID, false)

            return {
                ...checked,
                [docID]: !isChecked
            }
        },
        {}
    )

    const filtered = query.length > 0 ? docs.filter(doc => JSON.stringify(doc).toLowerCase().includes(query.toLowerCase())) : docs

    return (
        <main className="app messages">

            {fetching &&
                <ProgressBarIndeterminate/>
            }

            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Messages"/>

                <QuickSearch
                    query={query}
                    setQuery={setQuery}
                    entries={filtered.length}
                />

                <Tabs style={{marginTop: "16px"}}>
                    {filtered.map(obj =>
                        <li key={obj.path}>
                            <input
                                type="checkbox"
                                id={obj.path}
                                checked={_.get(checked, `${obj.path}`, false)}
                                onChange={() => toggle(obj.path)}
                            />
                            <label className="tab-label"
                                   htmlFor={obj.path}>{obj.date_formatted_channel} - {obj.nurse} & {obj.patient}</label>
                            <div className="tab-content">
                                {_.get(checked, `${obj.path}`, false) &&
                                    <>
                                        <MessagesWrapper>
                                            {obj.messages.map(message =>
                                                <MessageWrapper
                                                    key={message.id}
                                                    isPatient={message.isPatient}>
                                                    <p className="meta">{message.meta}</p>
                                                    <p className="content">{message.content}</p>
                                                </MessageWrapper>
                                            )}
                                        </MessagesWrapper>

                                        <DownloadMessagesButton
                                            data={obj.download_data}>
                                            DOWNLOAD
                                        </DownloadMessagesButton>
                                    </>
                                }
                            </div>
                        </li>
                    )}
                </Tabs>


                {filtered.length === 0 &&
                    <NoData>No data</NoData>
                }

            </article>

            <VersionNumber/>
            <LegalInfoFooter/>
        </main>
    )

}


export default Messages