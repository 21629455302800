import React from 'react'
import times from '../../../assets/svg/times.svg'
import '../../../assets/scss/ShowErrors.scss'


const ShowErrors = ({onClearError, errors = []}) => {

    return (
        <aside className="show-errors">
            {errors.length > 0 &&
                errors.map((error, i) =>
                    <div key={i}
                         className={error.isNewError ? 'error slideInRight' : 'error'}>
                        <p>{error.message}</p>
                        <button onClick={() => onClearError(i)}><img src={times} alt="close"/></button>
                    </div>
                )
            }
        </aside>
    )
}


export default ShowErrors