import React from 'react'
import {Navigate, useNavigate} from "react-router-dom"
import ShowErrors from '../../container/ShowErrors'
import logo from '../../../assets/svg/logo-with-phoenix.svg'
import '../../../assets/scss/Login/Login.scss'
import ProgressBarInDeterminate from "../ProgressBar/ProgressBarIndeterminate"
import {useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import FormField from "../Styled/FormField"
import InputField from "../Styled/InputField"
import LoginButton from "../Styled/LoginButton"
import ResetButton from "../Styled/ResetButton"
import CheckContainer from "../Styled/CheckContainer"
import VersionNumber from "../Styled/VersionNumber"
import LinkButton from "../Styled/LinkButton"
import {logEvent} from "firebase/analytics"


const Login = ({fetching, user, signInWithAdminIDAndPassword, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Login"
    })

    const navigate = useNavigate()

    const {register, handleSubmit, setValue, watch, formState: {errors}} = useForm({
        mode: 'onSubmit',
        defaultValues: {
            admin_id: "",
            password: "",
            privacy_policy: false,
            terms_conditions: false,
            cookie_policy: false
        }
    })

    const [admin_id, password, privacyPolicy, termsAndConditions, cookiePolicy] = watch(["admin_id", "password", "privacy_policy", "terms_conditions", "cookie_policy"])

    const onSubmit = ({admin_id, password}) => signInWithAdminIDAndPassword(admin_id, password)

    if (user != null) {
        return <Navigate to="/data"/>
    } else {
        return (
            <>
                {fetching &&
                    <ProgressBarInDeterminate/>
                }
                <ShowErrors/>
                <section id="login">
                    <div className="inner-content">
                        <header className="App-header">
                            <img
                                src={logo}
                                data-cy="logo"
                                className="logo"
                                alt="logo"
                            />
                        </header>

                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            onKeyDown={(e) => {
                                e.key === 'Enter' && handleSubmit(onSubmit)
                            }}
                        >
                            <FormField>
                                <InputField
                                    type="text"
                                    data-cy="username-input-field"
                                    isValid={!!errors.admin_id}
                                    placeholder="Please enter your admin ID..."
                                    autoComplete="username"
                                    tabIndex="10"
                                    {...register(
                                        'admin_id',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {errors.admin_id && <InvalidFeedback>The `Admin ID` field is required</InvalidFeedback>}
                                {admin_id.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('admin_id', '')}
                                        type="reset"
                                    />
                                }
                            </FormField>
                            <FormField>
                                <InputField
                                    type="password"
                                    data-cy="password-input-field"
                                    isValid={!!errors.password}
                                    placeholder="Please enter your password..."
                                    autoComplete="current-password"
                                    tabIndex="20"
                                    {...register(
                                        'password',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {errors.password && <InvalidFeedback>The `Password` field is required</InvalidFeedback>}
                                {password.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('password', '')}
                                        type="reset"
                                    />
                                }
                            </FormField>

                            <LoginButton
                                type="submit"
                                data-cy="login-button"
                                tabIndex="30"
                                disabled={!(privacyPolicy && termsAndConditions && cookiePolicy)}
                                className={(fetching ? 'sync' : '')}>
                                LOG IN
                            </LoginButton>

                            <CheckContainer>
                                <input
                                    type="checkbox"
                                    data-cy="privacy-statement-checkbox-field"
                                    id="privacy-statement-permissions"
                                    {...register(
                                        'privacy_policy',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                <label htmlFor="privacy-statement-permissions">
                                    <LinkButton onClick={() => navigate('/privacy-policy')}>
                                        Read & Consent to
                                        <br/>
                                        Privacy Statement
                                    </LinkButton>
                                </label>
                            </CheckContainer>

                            <CheckContainer>
                                <input
                                    type="checkbox"
                                    data-cy="terms-and-conditions-checkbox-field"
                                    id="terms-and-conditions-permissions"
                                    {...register(
                                        'terms_conditions',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                <label htmlFor="terms-and-conditions-permissions">
                                    <LinkButton onClick={() => navigate('/terms-and-conditions')}>
                                        Read & Consent to
                                        <br/>
                                        Terms and Conditions
                                    </LinkButton>
                                </label>
                            </CheckContainer>

                            <CheckContainer>
                                <input
                                    type="checkbox"
                                    data-cy="cookie-policy-checkbox-field"
                                    id="cookie-policy-permissions"
                                    {...register(
                                        'cookie_policy',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                <label htmlFor="cookie-policy-permissions">
                                    <LinkButton onClick={() => navigate('/cookie-policy')}>
                                        Read & Consent to
                                        <br/>
                                        Cookie Policy
                                    </LinkButton>
                                </label>
                            </CheckContainer>

                        </form>

                    </div>

                </section>

                <VersionNumber/>
            </>
        )
    }
}


export default Login
