import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {doc, onSnapshot} from "firebase/firestore"


const setNumberOfPatients = doc =>
    ({
        type: C.COUNTER_PATIENT,
        payload: doc
    })


export const patientCountListener = () => async (dispatch, getState) => {

    if (window.patientCountListener) {
        window.patientCountListener()
        window.patientCountListener = null
    }

    const patientCounterDocRef = doc(db, COLLECTION.COUNTERS, COLLECTION.PATIENTS)

    window.patientCountListener = onSnapshot(patientCounterDocRef, querySnapshot => {

        const totalCount = querySnapshot.get('total') ?? 0

        dispatch(
            setNumberOfPatients(totalCount)
        )

    })

}
