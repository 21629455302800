import PatientServiceProviders from '../../ui/PatientServiceProviders'
import {connect} from 'react-redux'
import {
    deleteNotification,
    deletePatientServiceProvider,
    deleteProgram,
    setItems
} from '../../../actions/batch/patient_service_providers'
import {addError} from '../../../actions/error'
import {getData} from "./selectors"
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        docs: getData(state),
        programs: state.programs.docs,
        fetching: state.patient_service_providers.fetching,
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        deletePatientServiceProvider(doc) {
            dispatch(
                deletePatientServiceProvider(doc)
            )
        },
        deleteProgram(serviceProviderDocID, programDocID) {
            dispatch(
                deleteProgram(serviceProviderDocID, programDocID)
            )
        },
        deleteNotification(path) {
            dispatch(
                deleteNotification(path)
            )
        },
        setItems(doc) {
            dispatch(
                setItems(doc)
            )
        },
        addError(error) {
            dispatch(
                addError(error)
            )
        }
    })


export default connect(mapStateToProps, mapDispatchToProps)(PatientServiceProviders)