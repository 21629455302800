import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import PrivateRoute from '../components/container/PrivateRoute'

import Data from "../components/container/Data/Data"

import Admins from '../components/container/Admins/Admins'
import AddAdmin from '../components/container/Admins/AddAdmin'

import Appointments from '../components/container/Appointments/Appointments'

import PatientServiceProviders from '../components/container/PatientServiceProviders/PatientServiceProviders'
import AddPatientServiceProvider from '../components/container/PatientServiceProviders/AddPatientServiceProvider'

import Nurses from '../components/container/Nurses/Nurses'
import AddNurse from '../components/container/Nurses/AddNurse'

import Patients from '../components/container/Patients/Patients'
import AddPatient from '../components/container/Patients/AddPatient'

import Analytics from '../components/container/Analytics/Analytics'

import ProgramInformation from '../components/container/ProgramInformation/ProgramInformation'
import AddProgramInformation from '../components/container/ProgramInformation/AddProgramInformation'

import Messages from '../components/container/Messages/Messages'

import Help from '../components/container/Help/Help'
import AddHelp from '../components/container/Help/AddHelp'

import Login from '../components/container/Login/Login'
import Lost from '../components/container/Lost/Lost'

import PrivacyPolicy from "../components/container/PrivacyPolicy/PrivacyPolicy"
import TermsConditions from "../components/container/TermsConditions/TermsConditions"
import CookiePolicy from "../components/container/CookiePolicy/CookiePolicy"


const routes = (
    <BrowserRouter>
        <Routes>

            <Route path="/login" element={
                <Login/>
            }/>

            <Route path="/data" element={
                <PrivateRoute>
                    <Data/>
                </PrivateRoute>
            }/>

            <Route path="/admins" element={
                <PrivateRoute>
                    <Admins/>
                </PrivateRoute>
            }/>

            <Route path="/admins/add" element={
                <PrivateRoute>
                    <AddAdmin/>
                </PrivateRoute>
            }/>

            <Route path="/patient-service-providers" element={
                <PrivateRoute>
                    <PatientServiceProviders/>
                </PrivateRoute>
            }/>

            <Route path="/patient-service-providers/add" element={
                <PrivateRoute>
                    <AddPatientServiceProvider/>
                </PrivateRoute>
            }/>

            <Route path="/appointments" element={
                <PrivateRoute>
                    <Appointments/>
                </PrivateRoute>
            }/>

            <Route path="/nurses" element={
                <PrivateRoute>
                    <Nurses/>
                </PrivateRoute>
            }/>

            <Route path="/nurses/add" element={
                <PrivateRoute>
                    <AddNurse/>
                </PrivateRoute>
            }/>

            <Route path="/patients" element={
                <PrivateRoute>
                    <Patients/>
                </PrivateRoute>
            }/>

            <Route path="/patients/add" element={
                <PrivateRoute>
                    <AddPatient/>
                </PrivateRoute>
            }/>

            <Route path="/analytics" element={
                <PrivateRoute>
                    <Analytics/>
                </PrivateRoute>
            }/>

            <Route path="/program-information" element={
                <PrivateRoute>
                    <ProgramInformation/>
                </PrivateRoute>
            }/>

            <Route path="/program-information/add" element={
                <PrivateRoute>
                    <AddProgramInformation/>
                </PrivateRoute>
            }/>

            <Route path="/messages" element={
                <PrivateRoute>
                    <Messages/>
                </PrivateRoute>
            }/>

            <Route path="/help" element={
                <PrivateRoute>
                    <Help/>
                </PrivateRoute>
            }/>

            <Route path="/help/add" element={
                <PrivateRoute>
                    <AddHelp/>
                </PrivateRoute>
            }/>

            <Route path="/privacy-policy" element={
                <PrivacyPolicy/>
            }/>

            <Route path="/terms-and-conditions" element={
                <TermsConditions/>
            }/>

            <Route path="/cookie-policy" element={
                <CookiePolicy/>
            }/>

            <Route path="*" element={
                <PrivateRoute>
                    <Lost/>
                </PrivateRoute>
            }/>

        </Routes>
    </BrowserRouter>
)

export default routes 