import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    onSnapshot,
    query,
    Timestamp,
    updateDoc,
    where
} from "firebase/firestore"
import {addError} from "../error"
import _ from 'lodash'
import moment from "moment-timezone"
import getKeywords from "../../util/getKeywords"
import {batch} from "react-redux"
import Timezone from "../../constants/timezone"


const isFetching = fetching =>
    ({
        type: C.APPOINTMENT_FETCHING_DOCS,
        payload: fetching
    })

export const addQuery = queryString =>
    ({
        type: C.APPOINTMENT_QUERY_TERM,
        payload: queryString
    })

export const addDate = date =>
    ({
        type: C.APPOINTMENT_QUERY_DATE_RANGE,
        payload: date
    })

export const addProgramPath = program_path =>
    ({
        type: C.APPOINTMENT_QUERY_PROGRAM,
        payload: program_path
    })

const removeItem = item =>
    ({
        type: C.APPOINTMENT_DELETE_DOC,
        payload: item
    })

const updateItem = item =>
    ({
        type: C.APPOINTMENT_UPDATE_DOC,
        payload: item
    })

const addItem = item =>
    ({
        type: C.APPOINTMENT_NEW_DOC,
        payload: item
    })

export const cancelAppointment = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {

        await deleteDoc(doc(db, COLLECTION.APPOINTMENTS, item.id))

    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }
}


export const appointmentListener = () => async (dispatch, getState) => {

    const {
        appointments: {
            docs = [],
            queryString = "",
            date = moment.tz(Timezone).toDate(),
            program_path = "all"
        }
    } = getState()

    dispatch(
        isFetching(true)
    )

    const queryConstraints = []

    queryConstraints.push(where('date', '>=', moment(date).startOf('month').subtract(16, 'd').startOf('day').toDate()))
    queryConstraints.push(where('date', '<=', moment(date).endOf('month').add(16, 'd').endOf('day').toDate()))

    if (program_path !== "all") {
        const program_ref = doc(db, program_path)
        queryConstraints.push(where('program.ref', '==', program_ref))
    }

    if (queryString.length > 0) {
        queryConstraints.push(where("keywords", "array-contains", queryString.toLowerCase()))
    }

    if (_.isFunction(window.appointmentListener)) {
        window.appointmentListener()
        delete window.appointmentListener
    }

    const appointmentsCollRef = collection(db, COLLECTION.APPOINTMENTS)

    const q = query(
        appointmentsCollRef,
        ...queryConstraints
    )

    window.appointmentListener = onSnapshot(q, querySnapshot => {

        dispatch(
            isFetching(false)
        )

        const queryDocs = querySnapshot.docs.map(i => ({id: i.id, ...i.data()}))

        docs.filter(i => !queryDocs.find(j => i.id === j.id))
            .forEach(i => dispatch(removeItem(i)))

        querySnapshot.docChanges().forEach(change => {

            const item = {
                id: change.doc.id,
                path: change.doc.ref.path,
                ...change.doc.data()
            }

            if (_.has(item, "date")) {
                item.date = item.date.toMillis()
            }

            item.patient_service_provider.path = item.patient_service_provider.ref.path

            delete item.patient_service_provider.ref

            item.program.path = item.program.ref.path

            delete item.program.ref

            item.patient.path = item.patient.ref.path

            delete item.patient.ref

            item.nurse.path = item.nurse.ref.path

            delete item.nurse.ref

            batch(() => {
                switch (change.type) {
                    case 'added':
                        dispatch(
                            addItem(item)
                        )
                        break
                    case 'modified':
                        dispatch(
                            updateItem(item)
                        )
                        break
                    case 'removed':
                        dispatch(
                            removeItem(item)
                        )
                        break
                    default:
                        break
                }
            })

        })
    }, error => {
        batch(() => {
            dispatch(
                isFetching(false)
            )
            dispatch(
                addError(error.message)
            )
        })
    })
}


export const setAppointment = (programPath, nurseDoc, patientDoc, date, allDay) => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        const program_ref = doc(db, programPath)

        const patient_service_provider_ref = doc(db, program_ref.parent.parent.path)

        const [pSnapshot, sSnapshot] = await Promise.all([getDoc(program_ref), getDoc(patient_service_provider_ref)])

        const patient_service_provider = {
            ref: patient_service_provider_ref,
            title: sSnapshot.get('title')
        }

        const program = {
            ref: program_ref,
            title: pSnapshot.get('title')
        }
        const patient = {
            ref: doc(db, patientDoc.path),
            patient_id: patientDoc.patient_id,
            keywords: patientDoc.keywords
        }

        const nurse = {
            ref: doc(db, nurseDoc.path),
            nurse_id: nurseDoc.nurse_id,
            first_name: nurseDoc.first_name,
            last_name_initial: nurseDoc.last_name_initial,
            keywords: nurseDoc.keywords
        }

        const temp = {
            date: date,
            patient_service_provider: patient_service_provider,
            program: program,
            patient: patient,
            nurse: nurse,
            all_day: allDay,
            keywords: getKeywords(
                patient.patient_id,
                nurse.nurse_id,
                nurse.first_name,
                nurse.last_name_initial,
                patient_service_provider.title,
                program.title
            )
        }

        const apptCollRef = collection(db, COLLECTION.APPOINTMENTS)

        await addDoc(apptCollRef, temp)
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}

export const rescheduleAppointment = (
    appointment,
    date,
    isAllDay
) => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        const appointmentRef = doc(db, COLLECTION.APPOINTMENTS, appointment.id)

        await updateDoc(appointmentRef, {
            date: Timestamp.fromDate(date),
            all_day: isAllDay
        })
    } catch (error) {
        dispatch(
            addError(error.message)
        )
    } finally {
        dispatch(
            isFetching(false)
        )
    }

}