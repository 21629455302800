import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {collection, doc, getDocs, increment, query, setDoc, Timestamp, where} from "firebase/firestore"
import {addError} from "../error"
import Role from "../../constants/Role"


const isFetching = fetching =>
    ({
        type: C.NURSES_FETCHING_DOCS,
        payload: fetching
    })

export const addItem = item => async (dispatch, getState) => {

    dispatch(
        isFetching(true)
    )

    try {
        const nurseCollRef = collection(db, COLLECTION.NURSES)

        const q = query(nurseCollRef, where("nurse_id", "==", item.nurse_id))

        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
            throw new Error(`Nurse with id: ${item.nurse_id} already exists. Please use a unique id.`)
        }

        const temp = {
            nurse_id: item.nurse_id,
            first_name: item.first_name,
            last_name_initial: item.last_name_initial,
            password: item.password,
            registered: Timestamp.now(),
            online: {
                status: false,
                is_logged_in: false,
                last_updated: Timestamp.now(),
            },
            is_auth: item.is_auth,
            keywords: item.keywords
        }

        const id = doc(nurseCollRef).id + Role.NURSE

        await setDoc(doc(db, COLLECTION.NURSES, id), temp)

        const nurseCounterRef = doc(db, COLLECTION.COUNTERS, COLLECTION.NURSES)

        await setDoc(nurseCounterRef, {total: increment(1)}, {merge: true})

    } catch (error) {

        dispatch(
            addError(error.message)
        )

    } finally {
        dispatch(
            isFetching(false)
        )
    }

}
