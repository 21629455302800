import React from 'react'
import styled from "styled-components"
import {useNavigate} from 'react-router-dom'

const Container = styled.a`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.2s ease-in;
  color: #1b4289;
  cursor: pointer;
  margin-top: 16px;

  &:hover {
    text-decoration: underline;
    color: black;
  }

  &::before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 244 448' width='244' height='448' xml:space='preserve'%3E%3Cpath stroke='none' fill='rgb(0, 11, 172)' d='M 232.47,443.54 L 239.55,436.46 C 244.23,431.78 244.23,424.18 239.55,419.49 L 44.05,224 239.55,28.51 C 244.23,23.82 244.23,16.22 239.55,11.53 L 232.47,4.46 C 227.79,-0.22 220.19,-0.22 215.5,4.46 L 4.45,215.51 C -0.23,220.2 -0.23,227.8 4.45,232.49 L 215.5,443.54 C 220.19,448.22 227.79,448.22 232.47,443.54 Z M 232.47,443.54' /%3E%3C/svg%3E");
    background-size: contain;
    width: 16px;
    height: 16px;
    display: inline-block;
    background-repeat: no-repeat;
  }
`


const GoBack = () => {
    const navigate = useNavigate()

    return (
        <Container onClick={() => navigate(-1)}>Go back</Container>
    )
}


export default GoBack