import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Header from '../../container/Header'
import ShowErrors from '../../container/ShowErrors'
import EntryHeader from '../EntryHeader'
import getKeywords from '../../../util/getKeywords'
import close from '../../../assets/svg/close.svg'
import '../../../assets/scss/Help/Add.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import InputNumber from "rc-input-number"
import roleType from '../../../constants/roleType'
import Select from 'react-select'
import isUrlValid from '../../../util/isUrlValid'
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Textarea from "../Styled/Textarea"
import Label from "../Styled/Label"
import OutlineLargeDangerButton from "../Styled/OutlineLargeDangerButton"
import PrimaryButton from "../Styled/PrimaryButton"
import _ from "lodash"
import colourStyles from "../../../constants/colorStyles"
import VersionNumber from "../Styled/VersionNumber"
import LegalInfoFooter from "../LegalInfoFooter"
import {logEvent} from "firebase/analytics"


const Add = ({options, addItem, analytics}) => {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "CMS Admin | Add Help"
    })

    const navigate = useNavigate()

    const {register, handleSubmit, setValue, watch, formState: {errors}, reset, control} = useForm({
        mode: 'onSubmit',
        defaultValues: {
            program_path: null,
            title: "",
            body: "",
            group: null,
            index: 1,
            with_link: false
        }
    })

    const onSubmit = data => {
        data.group = data.group.value
        data.index = _.toNumber(data.index)

        data.keywords = data.with_link ?
            getKeywords(data.title, data.body, data.link_title, data.link_url) :
            getKeywords(data.title, data.body)

        addItem(data)
        navigate('/help')
    }

    const [title, with_link, link_title, link_url] = watch(["title", "with_link", "link_title", "link_url"])

    return (
        <main className="app add-help">
            <ShowErrors/>
            <Header/>
            <article className="inner-content">
                <EntryHeader title="Add Help"/>

                <section className="content">

                    <h3>New Help</h3>
                    <Link
                        id="add-help-close"
                        to="/help"
                        className="close"
                        data-tooltip-id="add-help-tooltip"
                        data-tooltip-content={`Click to close`}
                    ><img src={close} alt="close icon"/></Link>

                    <form
                        className="add-help-form"
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                        }}
                    >

                        <div className="program_path">
                            <Label
                                id="add-help-program"
                                data-tooltip-id="add-help-tooltip"
                                data-tooltip-content={`Program for the item`}
                            >Program</Label>
                            <Controller
                                render={({field}) =>
                                    <Select
                                        {...field}
                                        options={options}
                                        className="react-select-field"
                                        placeholder="Please select a program..."
                                        styles={colourStyles}
                                    />
                                }
                                control={control}
                                rules={{required: true}}
                                name="program_path"
                            />
                            {errors.program_path &&
                                <InvalidFeedback>The `Program` field is required</InvalidFeedback>
                            }
                        </div>

                        <div className="group">
                            <Label
                                id="add-help-group"
                                data-tooltip-id="add-help-tooltip"
                                data-tooltip-content={`Group for the item`}>
                                Group
                            </Label>
                            <Controller
                                render={({field}) =>
                                    <Select
                                        {...field}
                                        options={roleType}
                                        className="react-select-field"
                                        placeholder="Please select a group..."
                                        styles={colourStyles}
                                    />
                                }
                                control={control}
                                rules={{required: true}}
                                name="group"
                            />
                        </div>

                        <div className="title">
                            <Label
                                id="add-help-title"
                                data-tooltip-id="add-help-tooltip"
                                data-tooltip-content={`Title for the item`}>
                                Title
                            </Label>
                            <div style={{position: "relative"}}>
                                <InputField
                                    isValid={!!errors.title}
                                    type="text"
                                    placeholder="Please enter a title..."
                                    {...register(
                                        'title',
                                        {
                                            required: true
                                        })
                                    }
                                />
                                {title.length > 0 &&
                                    <ResetButton
                                        onClick={() => setValue('title', '')}
                                        type="reset"
                                    />
                                }
                            </div>
                            {errors.title &&
                                <InvalidFeedback>The `Title` field is required</InvalidFeedback>
                            }
                        </div>

                        <div className="body">
                            <Label
                                id="add-help-description"
                                data-tooltip-id="add-help-tooltip"
                                data-tooltip-content={`Description for the item`}
                            >Description (500 word limit)</Label>

                            <Textarea
                                placeholder="Please enter the description..."
                                isValid={!!errors.body}
                                {...register(
                                    'body',
                                    {
                                        required: true,
                                        maxLength: 1000
                                    })
                                }
                            />
                            {errors.body && errors.body.type === "maxLength" &&
                                <InvalidFeedback>The description needs to be less than 500 words</InvalidFeedback>
                            }
                            {errors.body && errors.body.type === "required" &&
                                <InvalidFeedback>The `Description` field is required</InvalidFeedback>
                            }
                        </div>

                        <label
                            id="add-help-link"
                            className="checkbox"
                            data-tooltip-id="add-help-tooltip"
                            data-tooltip-content={`Does this item include a link`}>
                            <input
                                type="checkbox"
                                {...register('with_link')}
                            />
                            <span>Link</span>
                        </label>

                        {with_link && (
                            <>
                                <div className="link_title">
                                    <Label
                                        id="add-help-link-title"
                                        data-tooltip-id="add-help-tooltip"
                                        data-tooltip-content={`Link title`}>
                                        Link title
                                    </Label>
                                    <div style={{position: "relative"}}>
                                        <InputField
                                            isValid={!!errors.title}
                                            type="text"
                                            placeholder="Please enter the link title..."
                                            {...register(
                                                'link_title',
                                                {
                                                    required: true
                                                })
                                            }
                                        />
                                        {!!link_title && link_title.length > 0 &&
                                            <ResetButton
                                                onClick={() => setValue('link_title', '')}
                                                type="reset"
                                            />
                                        }
                                    </div>
                                    {errors.link_title &&
                                        <InvalidFeedback>The `Link Title` field is required</InvalidFeedback>}
                                </div>

                                <div className="link_url">
                                    <Label
                                        id="add-help-link-url"
                                        data-tooltip-id="add-help-tooltip"
                                        data-tooltip-content={`Link url`}>
                                        Link Url
                                    </Label>
                                    <div style={{position: "relative"}}>
                                        <InputField
                                            isValid={!!errors.link_url}
                                            name="link_url"
                                            type="text"
                                            placeholder="Please enter the link url..."
                                            {...register(
                                                'link_url',
                                                {
                                                    required: true,
                                                    validate: {
                                                        isValidUrl: value => isUrlValid(value)

                                                    }
                                                })
                                            }
                                        />
                                        {!!link_url && link_url.length > 0 &&
                                            <ResetButton
                                                onClick={() => setValue('link_url', '')}
                                                type="reset"
                                            />
                                        }
                                    </div>
                                    {errors.link_url && errors.link_url.type === "required" &&
                                        <InvalidFeedback>The `Link URL` field is required</InvalidFeedback>
                                    }
                                    {errors.link_url && errors.link_url.type === 'isValidUrl' &&
                                        <InvalidFeedback>Please enter a valid url</InvalidFeedback>
                                    }
                                </div>
                            </>
                        )}

                        <div className="index">
                            <Label
                                id="add-help-link-order"
                                data-tooltip-id="add-help-tooltip"
                                data-tooltip-content={`Rank order for the item`}>
                                Order
                            </Label>
                            <Controller
                                render={({field}) =>
                                    <InputNumber
                                        {...field}
                                        min={1}
                                    />
                                }
                                defaultValue={1}
                                control={control}
                                rules={{required: true}}
                                name="index"
                            />
                        </div>

                        <OutlineLargeDangerButton
                            className="reset"
                            type="button"
                            onClick={() => reset()}>
                            Reset Form
                        </OutlineLargeDangerButton>


                        <PrimaryButton
                            id="add-help-add-record"
                            data-tooltip-id="add-help-tooltip"
                            data-tooltip-content={`Click to add record`}
                            className="add btn"
                            type="submit">
                            ADD HELP
                        </PrimaryButton>

                        <Tooltip
                            id="add-help-tooltip"
                            float={true}
                        />

                    </form>

                </section>
            </article>

            <VersionNumber/>
            <LegalInfoFooter/>
        </main>
    )
}


export default Add