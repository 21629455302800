import styled from 'styled-components'
import theme from "./theme"


const LinkButton = styled.button`
  font-family: 'Open Sans', sans-serif;
  transition: all 0.3s ease-in;
  appearance: none;
  border: none;
  background: transparent;
  line-height: 1.5;
  padding: 0;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  color: #1A428A;
  text-align: left;
`

LinkButton.defaultProps = {
    theme: theme
}

export default LinkButton