const roleType = [{
    label: "All groups",
    value: "all"
}, {
    label: "Patients",
    value: "patient"
}, {
    label: "Nurses",
    value: "nurse"
}]

export default roleType