import ACTION from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {collection, onSnapshot} from "firebase/firestore"
import _ from "lodash"
import {batch} from "react-redux"
import {addError} from "../error"


const addItem = item =>
    ({
        type: ACTION.PATIENT_SERVICE_PROVIDERS_NEW_DOC,
        payload: item
    })

const updateItem = item =>
    ({
        type: ACTION.PATIENT_SERVICE_PROVIDERS_UPDATE_DOC,
        payload: item
    })

const deleteItem = item =>
    ({
        type: ACTION.PATIENT_SERVICE_PROVIDERS_DELETE_DOC,
        payload: item
    })


export const patientServiceProvidersDocListener = () => async (dispatch, getState) => {

    if (_.isFunction(window.patientServiceProvidersDocListener)) {
        window.patientServiceProvidersDocListener()
        delete window.patientServiceProvidersDocListener
    }

    const serviceProviderCollRef = collection(db, COLLECTION.PATIENT_SERVICE_PROVIDERS)

    window.patientServiceProvidersDocListener = onSnapshot(serviceProviderCollRef, querySnapshot => {
        querySnapshot.docChanges().forEach(change => {
            const item = {
                id: change.doc.id,
                path: change.doc.ref.path,
                ...change.doc.data()
            }

            item.registered = item.registered.toMillis()

            if (_.has(item, 'program_ref')) {
                item.program_path = item.program_ref.path
                delete item.program_ref
            } else {
                item.program_path = "all"
            }

            batch(() => {
                switch (change.type) {
                    case 'added':
                        dispatch(
                            addItem(item)
                        )
                        break
                    case 'modified':
                        dispatch(
                            updateItem(item)
                        )
                        break
                    case 'removed':
                        dispatch(
                            deleteItem(item)
                        )
                        break
                    default:
                        break
                }
            })

        })
    }, error => {
        dispatch(
            addError(error.message)
        )
    })

}