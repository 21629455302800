import _ from "lodash"


const getKeywords = (...data) => {
    const original = _.chain(data)
        .compact()
        .map(i => i.trim().toLowerCase())
        .value()

    const keywords = new Set(original)

    for (const item of original) {
        for (let i = 1; i <= item.length; i++) {
            keywords.add(item.substring(0, i))
        }
    }

    const words = _.chain(data)
        .compact()
        .words(/[^, ]+/g)
        .flattenDeep()
        .map(i => i.toLowerCase().replace(/\s/g, ''))
        .value()

    for (const word of words) {
        for (let i = 1; i <= word.length; i++) {
            keywords.add(word.substring(0, i))
        }
    }

    return Array.from(keywords)
}

export default getKeywords