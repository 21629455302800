import styled from "styled-components"
import theme from "./theme"


const DrawerContent = styled.div`
  overflow: auto;
  z-index: 1;
  position: relative;
  height: 100%;
`

DrawerContent.defaultProps = {
    theme: theme
}

export default DrawerContent