import React from 'react'
import '../../../assets/scss/Admins/Form.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import getKeywords from "../../../util/getKeywords"
import ResetButton from "../Styled/ResetButton"
import {Controller, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import Select from "react-select"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import AdminGroupType from "../../../constants/AdminGroupType"
import OutlineLargeDangerButton from "../Styled/OutlineLargeDangerButton"
import PrimaryButton from "../Styled/PrimaryButton"
import colourStyles from "../../../constants/colorStyles"
import _ from 'lodash'


const Form = props => {

    const {register, handleSubmit, setValue, watch, formState: {errors}, control} = useForm({mode: 'onSubmit'})

    const onSubmit = data => {
        const admin_group_keywords = data.admin_group.label

        data.id = props.id
        data.path = props.path
        data.admin_group = _.has(data.admin_group, "value") ? data.admin_group.value : data.admin_group

        data.keywords = getKeywords(data.admin_id, data.first_name, data.last_name, admin_group_keywords)

        props.handleUpdate(data)
    }

    const password = watch("password", props.password ?? "")
    const first_name = watch("first_name", props.first_name ?? "")
    const last_name = watch("last_name", props.last_name ?? "")

    return (
        <form
            className="edit-admin"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault()
            }}
        >

            <div className="admin_id">
                <Label
                    id={`admin-form-admin-id-${props.id}`}
                    data-tooltip-id={`admin-form-tooltip-${props.id}`}
                    data-tooltip-content={`Admin's ID`}>
                    Admin ID
                </Label>

                <InputField
                    type="text"
                    placeholder="Please enter an admin ID..."
                    defaultValue={props.admin_id}
                    {...register(
                        'admin_id',
                        {
                            required: true,
                            validate: {
                                lowerCase: value => value.toLowerCase() === value,
                                noSpaces: value => value.replace(" ", "") === value,
                                longerThanThreeLetters: value => value.length > 2
                            }
                        })
                    }
                    readOnly
                />

            </div>

            <div className="password">
                <Label
                    id={`admin-form-password-${props.id}`}
                    data-tooltip-id={`admin-form-tooltip-${props.id}`}
                    data-tooltip-content={`Admin's password`}>
                    Password
                </Label>

                <div style={{position: "relative"}}>
                    <InputField
                        type="password"
                        autoComplete="off"
                        isValid={!!errors.password}
                        placeholder="Please enter a password..."
                        defaultValue={props.password}
                        {...register(
                            'password',
                            {
                                required: true,
                                validate: {
                                    lowerCase: value => value.toLowerCase() === value,
                                    noSpaces: value => value.replace(" ", "") === value,
                                    longerThanThreeLetters: value => value.length > 2
                                }
                            })
                        }
                    />
                    {password.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('password', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.password && errors.password.type === 'required' &&
                    <InvalidFeedback>The `Password` field is required</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'lowerCase' &&
                    <InvalidFeedback>The password needs to be in lowercase</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'noSpaces' &&
                    <InvalidFeedback>The password cannot have spaces</InvalidFeedback>
                }
                {errors.password && errors.password.type === 'longerThanThreeLetters' &&
                    <InvalidFeedback>The password must be at least 3 characters</InvalidFeedback>
                }
            </div>

            <div className="first_name">
                <Label
                    id={`admin-form-first-name-${props.id}`}
                    data-tooltip-id={`admin-form-tooltip-${props.id}`}
                    data-tooltip-content={`Admin's first name`}>
                    First Name
                </Label>

                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.first_name}
                        type="text"
                        placeholder="Please enter a first name..."
                        defaultValue={props.first_name}
                        {...register(
                            'first_name',
                            {
                                required: true
                            })
                        }
                    />
                    {first_name.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('first_name', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.first_name && <InvalidFeedback>The `First Name` field is required</InvalidFeedback>}
            </div>

            <div className="last_name">
                <Label
                    id={`admin-form-last-name-${props.id}`}
                    data-tooltip-id={`admin-form-tooltip-${props.id}`}
                    data-tooltip-content={`Admin's last name`}>
                    Last name
                </Label>
                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.last_name}
                        type="text"
                        placeholder="Please enter a last name..."
                        defaultValue={props.last_name}
                        {...register(
                            'last_name',
                            {
                                required: true
                            })
                        }
                    />
                    {last_name.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('last_name', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.last_name && <InvalidFeedback>The `Last Name` field is required</InvalidFeedback>}
            </div>

            <div className="admin_group">
                <Label
                    id={`admin-form-group-${props.id}`}
                    data-tooltip-id={`admin-form-tooltip-${props.id}`}
                    data-tooltip-content={`Admin's group`}>
                    Admin Group {AdminGroupType.find(role => role.value === props.admin_group).label}
                </Label>

                <Controller
                    render={({field}) => (
                        <Select
                            {...field}
                            options={AdminGroupType}
                            className="react-select-field"
                            placeholder="Please select an admin group..."
                            styles={colourStyles}
                        />
                    )}
                    defaultValue={AdminGroupType.find(role => role.value === props.admin_group)}
                    control={control}
                    rules={{required: true}}
                    name="admin_group"
                />
                {errors.account_id && <InvalidFeedback>The `Admin Group` field is required</InvalidFeedback>}
            </div>

            <OutlineLargeDangerButton
                className="trash"
                type="button"
                onClick={e => {
                    e.preventDefault()
                    props.handleDelete(props.id)
                }}
                disabled={true}>
                Delete Admin
            </OutlineLargeDangerButton>

            <PrimaryButton
                id={`admin-form-update-btn-${props.id}`}
                disabled={props.fetching}
                className="update btn"
                type="submit"
                data-tooltip-id={`admin-form-tooltip-${props.id}`}
                data-tooltip-content={`Click to update record`}>
                UPDATE
            </PrimaryButton>

            <Tooltip
                id={`admin-form-tooltip-${props.id}`}
                float={true}
            />
        </form>
    )
}


export default Form

