import React, {useEffect, useReducer} from 'react'
import '../../../assets/scss/PatientServiceProviders/Form.scss'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import getKeywords from "../../../util/getKeywords"
import ResetButton from "../Styled/ResetButton"
import {useFieldArray, useForm} from "react-hook-form"
import InvalidFeedback from "../Styled/InvalidFeedback"
import InputField from "../Styled/InputField"
import Label from "../Styled/Label"
import {isEmpty} from "../../../util/isEmpty"
import FormControl from "../Styled/FormControl"
import Notifications from "./Notifications"
import OutlineDangerButton from "../Styled/OutlineDangerButton"
import OutlineSuccessButton from "../Styled/OutlineSuccessButton"
import OutlineLargeDangerButton from "../Styled/OutlineLargeDangerButton"
import getDocumentID from "../../../util/getDocumentID"
import COLLECTION from "../../../constants/collections"
import _ from "lodash"
import Tabs from "../Styled/Tabs"
import PrimaryButton from "../Styled/PrimaryButton"
import Timezone from "../../../constants/timezone"
import moment from "moment-timezone"
import {Timestamp} from "firebase/firestore"


const useEditForm = ({title, programs}) => {
    const form = useForm({
        mode: 'onSubmit',
        defaultValues: {
            title: title,
            programs: programs
        }
    })

    useEffect(() => {
        form.reset({title, programs})
    }, [title, programs])

    return form
}

const Form = props => {

    const {register, handleSubmit, setValue, watch, formState: {errors}, control} = useEditForm(props)

    const {fields, append, remove} = useFieldArray({
        control,
        name: "programs",
        keyName: "item_id"
    })

    const [checked, toggle] = useReducer(
        (checked, docID) => {
            const isChecked = _.get(checked, docID, false)

            return {
                ...checked,
                [docID]: !isChecked
            }
        },
        {}
    )

    const onSubmit = data => {
        data.id = props.id
        data.path = props.path
        data.keywords = getKeywords(data.title)
        data.registered = Timestamp.fromMillis(props.registered)

        if (data.programs) {

            data.programs = data.programs.map(i => {
                i.keywords = getKeywords(i.title, data.title)

                const millis = props.programs.find(j => j.id === i.id)?.registered ?? moment.tz(Timezone).valueOf()
                i.registered = Timestamp.fromMillis(millis)

                return i
            })

            for (const i in data.programs) {

                if (data.programs[i].notifications) {
                    for (const j in data.programs[i].notifications) {
                        const notification = data.programs[i].notifications[j]

                        notification.program_id = data.programs[i].id
                        notification.patient_service_provider_id = data.id
                        notification.registered = Timestamp.now()

                        data.programs[i].notifications[j] = notification
                    }
                }
            }
        }

        props.setItems(data)
    }

    const title = watch("title", "")
    const programs = watch("programs", [])

    return (
        <form
            className="edit-patient-service-provider"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault()
            }}
        >

            <div className="title" style={{marginBottom: "16px"}}>
                <Label
                    id={`service-provider-name-${props.id}`}
                    data-tooltip-id={`update-patient-service-provider-tooltip-${props.id}`}
                    data-tooltip-content={`The patient service provider's name`}
                >Patient Service Provider Name</Label>

                <div style={{position: "relative"}}>
                    <InputField
                        isValid={!!errors.title}
                        tabIndex="20"
                        type="text"
                        placeholder="Please enter a patient service provider name..."
                        {...register(
                            'title',
                            {
                                required: true
                            }
                        )
                        }
                    />
                    {title.length > 0 &&
                        <ResetButton
                            onClick={() => setValue('title', '')}
                            type="reset"
                        />
                    }
                </div>
                {errors.display_name &&
                    <InvalidFeedback>The `Patient Service Provider Name` field is required</InvalidFeedback>}
            </div>

            <Tabs key={props.programs}>
                {fields.map((item, index) => {

                    let panelTitle = `Program: #${index + 1}`

                    if (!isEmpty(programs[index]?.title)) {
                        panelTitle = `Program: ${programs[index]?.title}`
                    } else if (!isEmpty(item?.title)) {
                        panelTitle = `Program: ${item.title}`
                    }

                    return (
                        <li key={item.item_id}>

                            <input
                                type="checkbox"
                                id={item.id}
                                checked={_.get(checked, `${item.id}`, false)}
                                onChange={() => toggle(item.id)}
                            />

                            <label className="tab-label" htmlFor={item.id}>{panelTitle}</label>
                            <div className="tab-content">

                                <input
                                    type="hidden"
                                    defaultValue={`${item.patient_service_provider_id}`}
                                    {...register(`programs[${index}].patient_service_provider_id`)}
                                />

                                <input
                                    type="hidden"
                                    defaultValue={`${item.id}`}
                                    {...register(`programs[${index}].id`)}
                                />

                                <div className={`programs`}>
                                    <Label
                                        id={`service-provider-program-title-${props.id}-${index}`}
                                        data-tooltip-id={`update-patient-service-provider-tooltip-${props.id}`}
                                        data-tooltip-content={`The program's name`}
                                    >Program Title</Label>

                                    <FormControl>
                                        <InputField
                                            isValid={!!errors.programs &&
                                                !!errors.programs[index] &&
                                                !!errors.programs[index].title}
                                            defaultValue={`${item.title}`}
                                            type="text"
                                            placeholder="Please enter the name of the program..."
                                            {...register(
                                                `programs[${index}].title`,
                                                {
                                                    required: true
                                                })
                                            }
                                        />
                                    </FormControl>
                                    {errors.programs && errors.programs[index]
                                        && errors.programs[index].title
                                        && errors.programs[index].title
                                        && <InvalidFeedback>The `Program Title` field is required</InvalidFeedback>}
                                </div>

                                <Notifications
                                    register={register}
                                    control={control}
                                    field={item}
                                    setValue={setValue}
                                    watch={watch}
                                    errors={errors}
                                    checked={checked}
                                    toggle={toggle}
                                    deleteNotification={props.deleteNotification}
                                    namePrefix={`programs[${index}].notifications`}
                                />

                                <OutlineDangerButton
                                    type="button"
                                    onClick={() => {
                                        props.deleteProgram(item.patient_service_provider_id, item.id)
                                        remove(index)
                                    }}
                                    disabled={true}>
                                    Delete Program {programs[index]?.title}
                                </OutlineDangerButton>
                            </div>
                        </li>
                    )
                })}
            </Tabs>

            <OutlineSuccessButton
                type="button"
                className="add-program-button"
                onClick={() => {
                    append({
                        id: getDocumentID(COLLECTION.PROGRAMS),
                        title: "",
                        notifications: []
                    })
                }}

            >{title.length > 0 ? `Add Program to ${title}` : `Add Program`}</OutlineSuccessButton>

            <aside className="controls">
                <OutlineLargeDangerButton
                    className="trash"
                    type="button"
                    onClick={e => {
                        e.preventDefault()
                        props.deletePatientServiceProvider(props.id)
                    }}
                    disabled={true}>
                    Delete {title}
                </OutlineLargeDangerButton>

                <PrimaryButton
                    id={`service-provider-update-btn-${props.id}`}
                    disabled={props.fetching}
                    data-tooltip-id={`update-patient-service-provider-tooltip-${props.id}`}
                    data-tooltip-content={`Click to update record`}
                    className="update btn"
                    type="submit">
                    UPDATE
                </PrimaryButton>

                <Tooltip
                    id={`update-patient-service-provider-tooltip-${props.id}`}
                    float={true}
                />

            </aside>

        </form>
    )
}


export default Form