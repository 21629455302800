import React from 'react'
import '../../../assets/scss/QuickSearchWithLink.scss'
import ResetButton from "../Styled/ResetButton"


const QuickSearch = ({
                         query,
                         setQuery,
                         entries
                     }) => {


    return (
        <section className="quick-search">
            <form onSubmit={e => e.preventDefault()}>
                <div className="quick-search-container">
                    <input
                        type="text"
                        placeholder="Quick search..."
                        value={query}
                        onChange={e => {
                            setQuery(e.target.value)
                        }}
                    />
                    {query.length > 0 &&
                        <ResetButton onClick={() => setQuery("")} type="reset"/>
                    }
                </div>

                {entries > 0 &&
                    <p>{entries} entries</p>
                }
            </form>

        </section>
    )
}

export default QuickSearch