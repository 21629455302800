import styled from 'styled-components'
import theme from "./theme"


const OutlineDangerButton = styled.button`
    background-color: #fff;
    color: #dc3545;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    padding: .375rem .75rem;
    border-radius: 0.25rem;
    border: 1px solid #dc3545;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.2s ease-in;
    line-height: 1.5;
    display: flex;
    margin: 1.6rem 0 0 auto;
    justify-content: flex-end;
		    
    &:hover{
      background-color: #dc3545;
      color: #fff;
    }
    
    &:disabled {
      color: ${props => props.theme.brandDangerDisabled};
      opacity: .65;
      background-color: transparent;
      cursor: not-allowed;
    }
`

OutlineDangerButton.defaultProps = {
    theme: theme
}

export default OutlineDangerButton