import React, {useMemo} from 'react'
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table'
import {matchSorter} from 'match-sorter'
import Table from "../Styled/Table"
import Sort from "../Sort"


const FeedbackTable = ({data = []}) => {

    const filterTypes = useMemo(
        () => ({
            fuzzyText: (rows, id, filterValue) => {
                return matchSorter(rows, filterValue, {keys: [row => row.values[id]]})
            },
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const columns = useMemo(
        () => [
            {
                Header: "Date",
                id: "date",
                accessor: d => d.date_table
            }, {
                Header: "Patient Service Provider",
                id: "patient_service_provider",
                accessor: d => d.patient_service_provider.title,
                filter: 'fuzzyText'
            }, {
                Header: "Program",
                id: "program",
                accessor: d => d.program.title,
                filter: 'fuzzyText'
            }, {
                Header: "Rating",
                id: "ratings",
                accessor: d => d.rating
            }, {
                Header: "Did you feel prepared for the call?",
                id: "did_you_feel_prepared_for_the_call",
                accessor: d => d.extra_questions.did_you_feel_prepared_for_the_call
            }, {
                Header: "Did the Nurse call you within the allocated time?",
                id: "did_the_nurse_call_you_within_the_allocated_time",
                accessor: d => d.extra_questions.did_the_nurse_call_you_within_the_allocated_time
            }, {
                Header: "Do you feel you received the support you required?",
                id: "do_you_feel_you_received_the_support_you_required",
                accessor: d => d.extra_questions.do_you_feel_you_received_the_support_you_required
            }, {
                Header: "Are you satisfied with the service provided today?",
                id: "are_you_satisfied_with_the_service_provided_today",
                accessor: d => d.extra_questions.are_you_satisfied_with_the_service_provided_today
            }
        ],
        []
    )

    const defaultColumn = useMemo(
        () => ({
            Filter: ({column: {filterValue, preFilteredRows, setFilter}}) => {

                return (
                    <input
                        type="text"
                        value={filterValue || ''}
                        onChange={e => {
                            setFilter(e.target.value || undefined)
                        }}
                    />
                )
            },
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize}
    } = useTable({
            columns,
            data,
            defaultColumn,
            filterTypes,
            initialState: {pageIndex: 0}
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    return (
        <>
            <Table {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps({
                                style: {width: '0.125%'}
                            }))}>
                                <div className='column-header'><span>{column.render('Header')}</span> <Sort
                                    isSorted={column.isSorted} isSortedDesc={column.isSortedDesc}/></div>
                                <div className='column-filter'>{column.canFilter ? column.render('Filter') : null}</div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </Table>

            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>
                {' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>
                {' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>
                {' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>
                {' '}
                <span>
          Page{' '}
                    <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
                <span>
          | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        min="1"
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{width: '100px'}}
                    />
        </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}


export default FeedbackTable