import {createSelector} from 'reselect'

export const getPatientServiceProviders = state => state.patient_service_providers.docs
export const getPrograms = state => state.programs.docs


export const getOptions = createSelector(
    getPatientServiceProviders,
    getPrograms,
    (patientServiceProviders, programs) => {
        const options = patientServiceProviders.map(i => {
            const programOptions = programs
                .filter(j => j.patient_service_provider_id === i.id)
                .map(j => ({label: j.title, value: j.path})) ?? []

            return {
                label: i.title,
                options: programOptions
            }
        })

        return [{label: 'All programs', value: 'all'}, ...options]
    }
)

export const allOptions = createSelector(
    getPrograms,
    (programs) => {
        const options = programs
            .map(i => ({label: i.title, value: i.path})) ?? []

        return [{label: 'All programs', value: 'all'}, ...options]
    }
)
