import styled from 'styled-components'
import React from 'react'


const ButtonWrapper = styled.button`
  position: absolute;
  top: -1px;
  right: 2px;
  width: 13px;
  height: 15px;
  z-index: 99;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:disabled {
    opacity: .65;
    background-color: transparent;
    cursor: default;
  }
`

const SVGWrapper = styled.svg`
    pointer-events: none;
`

const RemoveEvent = () => (
    <ButtonWrapper
        className="delete-event">
        <SVGWrapper
            viewBox="0 0 13 15"
            width="13"
            height="15">
            <path
                stroke="none"
                fill="#fff"
                d="M 8.59,12.66 L 9.05,12.66 C 9.18,12.66 9.29,12.55 9.29,12.42 L 9.29,4.45 C 9.29,4.32 9.18,4.22 9.05,4.22 L 8.59,4.22 C 8.46,4.22 8.36,4.32 8.36,4.45 L 8.36,12.42 C 8.36,12.55 8.46,12.66 8.59,12.66 Z M 3.95,12.66 L 4.41,12.66 C 4.54,12.66 4.64,12.55 4.64,12.42 L 4.64,4.45 C 4.64,4.32 4.54,4.22 4.41,4.22 L 3.95,4.22 C 3.82,4.22 3.71,4.32 3.71,4.45 L 3.71,12.42 C 3.71,12.55 3.82,12.66 3.95,12.66 Z M 12.77,1.88 L 9.75,1.88 8.78,0.56 C 8.51,0.21 8.1,-0 7.66,-0 L 5.34,-0 C 4.9,-0 4.49,0.21 4.22,0.56 L 3.25,1.88 0.23,1.88 C 0.1,1.88 0,1.98 0,2.11 L 0,2.58 C 0,2.71 0.1,2.81 0.23,2.81 L 0.93,2.81 0.93,13.59 C 0.93,14.37 1.55,15 2.32,15 L 10.68,15 C 11.45,15 12.07,14.37 12.07,13.59 L 12.07,2.81 12.77,2.81 C 12.9,2.81 13,2.71 13,2.58 L 13,2.11 C 13,1.98 12.9,1.88 12.77,1.88 Z M 4.97,1.12 C 5.06,1.01 5.19,0.94 5.34,0.94 L 7.66,0.94 C 7.81,0.94 7.94,1.01 8.03,1.12 L 8.59,1.88 4.41,1.88 4.97,1.12 Z M 11.14,13.59 C 11.14,13.85 10.93,14.06 10.68,14.06 L 2.32,14.06 C 2.07,14.06 1.86,13.85 1.86,13.59 L 1.86,2.81 11.14,2.81 11.14,13.59 Z M 6.27,12.66 L 6.73,12.66 C 6.86,12.66 6.96,12.55 6.96,12.42 L 6.96,4.45 C 6.96,4.32 6.86,4.22 6.73,4.22 L 6.27,4.22 C 6.14,4.22 6.04,4.32 6.04,4.45 L 6.04,12.42 C 6.04,12.55 6.14,12.66 6.27,12.66 Z M 6.27,12.66"/>
        </SVGWrapper>
    </ButtonWrapper>
)

export default RemoveEvent
