import C from '../../constants/actionType'
import COLLECTION from '../../constants/collections'
import {db} from '../index'
import {collection, doc, onSnapshot, query, where} from "firebase/firestore"
import {addError} from "../error"
import moment from "moment-timezone"
import Timezone from "../../constants/timezone"
import _ from "lodash"
import {batch} from "react-redux"
import {workerThread} from "../../util/workerThread"


const isFetching = fetching =>
    ({
        type: C.FEEDBACK_FETCHING_DOCS,
        payload: fetching
    })

const addItem = item =>
    ({
        type: C.FEEDBACK_NEW_DOC,
        payload: item
    })

const deleteItem = item =>
    ({
        type: C.FEEDBACK_DELETE_DOC,
        payload: item
    })

const updateItem = item =>
    ({
        type: C.FEEDBACK_UPDATE_DOC,
        payload: item
    })


const deleteAllItems = () =>
    ({
        type: C.FEEDBACK_CLEAR_DOCS
    })

export const setStartDate = (item = moment.tz(Timezone).subtract(6, 'd').valueOf()) =>
    ({
        type: C.FEEDBACK_START_DATE,
        payload: item
    })

export const setEndDate = (item = moment.tz(Timezone).valueOf()) =>
    ({
        type: C.FEEDBACK_END_DATE,
        payload: item
    })

export const setPatientServiceProviderPath = (item = 'all') =>
    ({
        type: C.FEEDBACK_PATIENT_SERVICE_PROVIDER_PATH,
        payload: item
    })

export const feedbackDocListener = () => async (dispatch, getState) => {

    const {feedback: {docs = [], start_date, end_date, patient_service_provider_path}} = getState()

    const startDate = moment.tz(start_date, Timezone).startOf('day').toDate()
    const endDate = moment.tz(end_date, Timezone).endOf('day').toDate()

    dispatch(
        isFetching(true)
    )

    let q = query(collection(db, COLLECTION.FEEDBACK), where('date', '>=', startDate), where('date', '<=', endDate))


    if (patient_service_provider_path !== 'all') {
        const patient_service_provider_ref = doc(db, patient_service_provider_path)
        q = query(q, where('program.ref', '==', patient_service_provider_ref))
    }

    if (_.isFunction(window.feedbackDocListener)) {
        window.feedbackDocListener()
        delete window.feedbackDocListener
    }

    window.feedbackDocListener = onSnapshot(q, querySnapshot => {
        if (querySnapshot.empty) {
            batch(() => {
                dispatch(
                    deleteAllItems()
                )
                dispatch(
                    isFetching(false)
                )
            })
        } else {
            const queryDocs = querySnapshot.docs.map(i => ({id: i.id, ...i.data()}))

            docs.filter(i => !queryDocs.find(j => i.id === j.id))
                .forEach(i => dispatch(deleteItem(i)))

            const testAccounts = [
                "eNTpg2uf8Xw5jGtRO2Rk-nurse",
                "G4x0cMsUBqGo933Endjg-nurse",
                "012nhwQTeG59n0RE2Tnx-nurse",
                "ahFtDWs3OL5e1B514tbq-patient",
                "k8QCFUqp761jp9KfRqhV-patient",
                "6pgkrIKYGllIpv5lZplf-patient",
                "53qQTxfR2ZZq2Td0AAaS-patient"
            ]

            const noOfChanges = querySnapshot.docChanges().length - 1

            querySnapshot.docChanges().forEach((change, index) => {

                workerThread(() => {

                    const item = {
                        id: change.doc.id,
                        path: change.doc.ref.path,
                        ...change.doc.data()
                    }

                    if (_.has(item, "date")) {
                        item.date = item.date.toMillis()
                        item.date_formatted = moment.tz(item.date, Timezone).format("DD/MM/YY")
                        item.date_table = moment.tz(item.date, Timezone).format("DD/MM/YY h:mm a")
                    }

                    item.extra_questions.did_you_feel_prepared_for_the_call = !_.isBoolean(item.extra_questions.did_you_feel_prepared_for_the_call) ? "N/A" : item.extra_questions.did_you_feel_prepared_for_the_call ? "Yes" : "No"
                    item.extra_questions.did_the_nurse_call_you_within_the_allocated_time = !_.isBoolean(item.extra_questions.did_the_nurse_call_you_within_the_allocated_time) ? "N/A" : item.extra_questions.did_the_nurse_call_you_within_the_allocated_time ? "Yes" : "No"
                    item.extra_questions.do_you_feel_you_received_the_support_you_required = !_.isBoolean(item.extra_questions.do_you_feel_you_received_the_support_you_required) ? "N/A" : item.extra_questions.do_you_feel_you_received_the_support_you_required ? "Yes" : "No"
                    item.extra_questions.are_you_satisfied_with_the_service_provided_today = !_.isBoolean(item.extra_questions.are_you_satisfied_with_the_service_provided_today) ? "N/A" : item.extra_questions.are_you_satisfied_with_the_service_provided_today ? "Yes" : "No"

                    item.program.path = item.program.ref.path

                    delete item.program.ref

                    item.patient_service_provider.path = item.patient_service_provider.ref.path

                    delete item.patient_service_provider.ref

                    item.commenter_path = item.commenter_ref.path
                    item.uid = item.commenter_ref.id

                    item.role = item.commenter_ref.id.endsWith("nurse") ? "nurse" : "patient"

                    delete item.commenter_ref

                    if (_.has(item, "uid") && !testAccounts.includes(item.uid)) {
                        switch (change.type) {
                            case 'added':
                                dispatch(
                                    addItem(item)
                                )
                                break
                            case 'modified':
                                dispatch(
                                    updateItem(item)
                                )
                                break
                            case 'removed':
                                dispatch(
                                    deleteItem(item)
                                )
                                break
                            default:
                                break
                        }
                    }

                    dispatch(
                        isFetching(index !== noOfChanges)
                    )
                })
            })
        }
    }, error => {
        batch(() => {
            dispatch(
                isFetching(false)
            )
            dispatch(
                addError(error.message)
            )
        })
    })
}