import ProgramInformation from '../../ui/ProgramInformation'
import {connect} from 'react-redux'
import {deleteItem, setItem} from '../../../actions/program_information'
import {addError} from '../../../actions/error'
import {allOptions, getOptions} from "./selectors"
import {analytics} from "../../../actions"


const mapStateToProps = (state, props) =>
    ({
        options: getOptions(state),
        allOptions: allOptions(state),
        docs: state.program_information.docs,
        fetching: state.program_information.fetching,
        analytics: analytics
    })

const mapDispatchToProps = dispatch =>
    ({
        deleteItem(doc) {
            dispatch(
                deleteItem(doc)
            )
        },
        setItem(doc) {
            dispatch(
                setItem(doc)
            )
        },
        addError(error) {
            dispatch(
                addError(error)
            )
        }
    })


export default connect(mapStateToProps, mapDispatchToProps)(ProgramInformation)