import React from "react"
import {useFieldArray} from "react-hook-form"
import Label from "../Styled/Label"
import InputField from "../Styled/InputField"
import FormControl from "../Styled/FormControl"
import OutlineSuccessButton from "../Styled/OutlineSuccessButton"
import Textarea from "../Styled/Textarea"
import OutlineDangerButton from "../Styled/OutlineDangerButton"
import {isEmpty} from "../../../util/isEmpty"
import InvalidFeedback from "../Styled/InvalidFeedback"
import _ from "lodash"
import getDocumentID from "../../../util/getDocumentID"
import COLLECTION from "../../../constants/collections"
import Tabs from "../Styled/Tabs"


const Notifications = ({
                           deleteNotification,
                           register,
                           control,
                           setValue,
                           watch,
                           namePrefix,
                           errors,
                           checked,
                           toggle
                       }) => {
    const {fields, append, remove} = useFieldArray({
        control,
        name: namePrefix,
        keyName: "item_id"
    })

    const notifications = watch(namePrefix, [])

    return (
        <div className="notifications-panels" style={{marginTop: "16px"}}>
            <Tabs>
                {fields.map((item, index) => {
                    let panelTitle = `Notification: #${index + 1}`

                    if (!isEmpty(notifications[index]?.title)) {
                        panelTitle = `Notification: ${notifications[index]?.title}`
                    } else if (!isEmpty(item?.title)) {
                        panelTitle = `Notification: ${item.title}`
                    }

                    return (
                        <li key={item.item_id}>
                            <input
                                type="checkbox"
                                id={item.id}
                                checked={_.get(checked, `${item.id}`, false)}
                                onChange={() => toggle(item.id)}
                            />

                            <label className="tab-label" htmlFor={item.id}>{panelTitle}</label>
                            <div className="tab-content">

                                <input
                                    type="hidden"
                                    defaultValue={`${item.id}`}
                                    {...register(`${namePrefix}[${index}].id`)}
                                />

                                <input
                                    type="hidden"
                                    defaultValue={`${item.program_id}`}
                                    {...register(`${namePrefix}[${index}].program_id`)}
                                />

                                <div className="notification-title">
                                    <Label
                                        data-tooltip-content={`The title of the notification to display`}>Title</Label>
                                    <FormControl>
                                        <InputField
                                            isValid={!!_.get(errors, `${namePrefix}[${index}].title`, false)}
                                            placeholder="Please enter the title for the notification..."
                                            defaultValue={`${item.title}`}
                                            {...register(
                                                `${namePrefix}[${index}].title`,
                                                {
                                                    required: true
                                                })
                                            }
                                        />
                                    </FormControl>
                                    {_.get(errors, `${namePrefix}[${index}].title`, false)
                                        && <InvalidFeedback>The `Title` field is required</InvalidFeedback>}
                                </div>

                                <div className="notification-body" style={{marginTop: "1.6rem"}}>
                                    <Label data-tooltip-content={`The body of the notification to display`}>Body</Label>
                                    <FormControl>
                                        <Textarea
                                            isValid={!!_.get(errors, `${namePrefix}[${index}].body`, false)}
                                            placeholder="Please enter the body of the notification..."
                                            defaultValue={`${item.body}`}
                                            {...register(
                                                `${namePrefix}[${index}].body`,
                                                {
                                                    required: true
                                                })
                                            }
                                        />
                                    </FormControl>
                                    {_.get(errors, `${namePrefix}[${index}].body`, false)
                                        && <InvalidFeedback>The `Body` field is required</InvalidFeedback>}
                                </div>

                                <div className="notification-lead-time" style={{marginTop: "1.6rem"}}>
                                    <Label
                                        data-tooltip-content={`The number of hours before the appointment to send the notification`}>Lead
                                        Time (in hours)</Label>
                                    <FormControl>
                                        <InputField
                                            style={{maxWidth: "10rem"}}
                                            type="number"
                                            isValid={!!_.get(errors, `${namePrefix}[${index}].lead_time`, false)}
                                            placeholder="Please enter the title for the notification..."
                                            defaultValue={item.lead_time}
                                            min="1"
                                            step="1"
                                            {...register(
                                                `${namePrefix}[${index}].lead_time`,
                                                {
                                                    required: true
                                                })
                                            }
                                        />
                                    </FormControl>
                                    {_.get(errors, `${namePrefix}[${index}].lead_time`, false)
                                        && <InvalidFeedback>The `Lead Time` field is required</InvalidFeedback>}
                                </div>

                                <OutlineDangerButton
                                    type="button"
                                    onClick={() => {
                                        if (_.has(item, "path")) {
                                            deleteNotification(item.path)
                                        }
                                        remove(index)
                                    }}
                                >
                                    Delete Notification {notifications[index]?.title}
                                </OutlineDangerButton>
                            </div>
                        </li>
                    )
                })}
            </Tabs>

            <OutlineSuccessButton
                style={{marginTop: "16px"}}
                type="button"
                className="add-notification-button"
                onClick={() => {
                    append({
                        id: getDocumentID(COLLECTION.NOTIFICATIONS),
                        title: "",
                        body: "",
                        lead_time: 24
                    })
                }}
            >Add Notification</OutlineSuccessButton>

        </div>
    )
}


export default Notifications